import styled, { css } from "styled-components/macro";

export const LoadingContainer = styled.div`
  background: #fff;
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
`;
