/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PackagesApi } from "services/api/packages";

const INITIAL_STATE = {
  list: [],
  loading: false,
  errors: "",
  selectedPackage: {},
};

const packagesSlice = createSlice({
  name: "packages",
  initialState: INITIAL_STATE,
  reducers: {
    packagesRequest(state) {
      state.loading = true;
    },

    packagesSuccess(state, action) {
      state.list = action.payload;
      state.loading = false;
    },
    packagesFailure(state, action) {
      state.errors = action.payload;
      state.list = [];
      state.loading = false;
    },
    selectedPackage(state, action) {
      state.selectedPackage = action.payload;
    },
  },
});

// export actions
export const {
  packagesRequest,
  packagesSuccess,
  packagesFailure,
  selectedPackage,
} = packagesSlice.actions;

// export reducer
export const { reducer: packagesReducer } = packagesSlice;

const dispatchFetchPackages = (programId) => async (dispatch) => {
  try {
    dispatch(packagesRequest());

    const response = await PackagesApi.getAllPackages(programId);

    if (response.data.metaData.key === "success") {
      dispatch(packagesSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(packagesFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(packagesFailure(error.toString()));
  }
};

const dispatchSelectedPackage = (data) => (dispatch) => {
  dispatch(selectedPackage(data));
};

export { dispatchFetchPackages, dispatchSelectedPackage };
