import { css } from "styled-components";
import "assets/fonts/4_F5.ttf";
import RbFont from "assets/fonts/4_F5.ttf";
import { media } from "./utils";

export default css`
  @font-face {
    font-family: "RB";
    src: url(${RbFont});
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  .main-container {
    /* max-width: 1366px; */
    margin: 0 auto;
    width: 100%;
  }

  .slide-in {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    transform-origin: left;
  }

  html,
  body {
    overflow-y: auto !important;
    min-width: auto !important;
  }

  .slide-out {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    transform-origin: right;
  }

  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 536px;
    /* height: 107px; */
    /* margin: 42px 415px 3px; */
    padding: 16px 17px 16px 20px;
    /* border-radius: 15px; */
    /* box-shadow: 0 22px 66px 0 rgb(208 208 208 / 31%); */
    /* background-color: #fff; */

    ${media("small")} {
      width: 350px !important;
    }
  }

  .Toastify__toast--default {
    background: #fff;
    color: #aaa;
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    transform: translateX(0px);
    opacity: 1;
    width: 536px;
    height: 107px;
    padding: 16px 17px 16px 20px;
    border-radius: 15px;
    box-shadow: 0 22px 66px 0 rgb(208 208 208 / 31%);

    ${media("small")} {
      width: 350px !important;
    }
  }
  .Toastify__toast-body {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
  .Toastify__close-button {
    margin-right: auto;
  }

  body,
  html {
    height: auto !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  main {
    width: 70%;
    margin: auto;
    text-align: center;
  }

  main button {
    margin-top: 20px;
    background-color: #2d8cff;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
  }

  main button:hover {
    background-color: #2681f2;
  }

  #zmmtg-root {
    display: none;

    .controls {
      display: flex;
      justify-content: center;

      button {
        background-color: rgb(var(--primary)) !important;
        color: #fff;
      }
    }
  }

  .zoom-container {
    padding: 22px 140px 25px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .video-container{
      flex:3;
      iframe{
        height:500px;
      }
    }
    .chat-container{
      iframe{
        height:500px;
        footer{
          height:120px;
        }
      }
      
    }
    ${media("large")} {
      padding: 22px 12px 25px;
    }
    ${media("medium")} {
      flex-direction:column;
      .chat-container{
        order:2;
      }
    }
    ${media("small")}{
      .chat-container{
        iframe{
          height:300px;
          
        }}
    }
   
  }

  .zoom-button {
    display: flex;
    font-family: "RB";
    font-size: 1.6rem;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    width: 116px;
    height: 46px;
    padding: 9px 24px 12px;
    border-radius: 10px;
    background-color: rgb(var(--primary));
    color: #fff;
    transition: background-color 0.2s;
    &:hover {
      background-color: rgba(var(--primary), 0.8);
    }
  }

  /* Modal Style */

  .app-modal-close {
    left: 33px;
    top: 18px;
    border: solid 1px #cbc2d6;
    border-radius: 50%;
    fill: #cbc2d6;
    max-width: 25px;
    max-height: 25px;
    justify-content: center;
    align-items: center;
  }

  .app-modal {
    padding: 0 !important;
    border-radius: 15px;
  }
  .review-app-modal {
    overflow: hidden;
  }
  .app-modal-overlay {
    opacity: 0.83 !important;

    background-color: #2e213c !important;
  }
  .app-terms-overlay + div{
    overflow:hidden;
    z-index:10000;
  }

  /* animations */

  @keyframes pulse {
    0% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes animate-stripes {
    100% {
      background-position: -100px 0px;
    }
  }

  @keyframes animate-stripes {
    100% {
      background-position: -100px 0px;
    }
  }
`;
