/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Programs } from "services/api/programs";

const INITIAL_STATE = {
  list: [],
  loading: false,
  errors: "",
  selectedProgram: {},
  userPrograms: [],
};

const programsSlice = createSlice({
  name: "programs",
  initialState: INITIAL_STATE,
  reducers: {
    programsRequest(state) {
      state.loading = true;
    },

    programsSuccess(state, action) {
      state.list = action.payload.data;
      state.loading = false;
    },
    programsFailure(state, action) {
      state.errors = action.payload;
      state.list = [];
      state.loading = false;
    },
    programRequest(state) {
      state.loading = true;
    },

    programSuccess(state, action) {
      state.selectedProgram = action.payload;
      state.loading = false;
    },
    programFailure(state, action) {
      state.errors = action.payload;
      state.selectedProgram = {};
      state.loading = false;
    },
    UserProgramsRequest(state) {
      state.loading = true;
    },

    UserProgramsSuccess(state, action) {
      state.userPrograms = action.payload;
      state.loading = false;
    },
    UserProgramsFailure(state, action) {
      state.errors = action.payload;
      state.userPrograms = {};
      state.loading = false;
    },
    // setSelectedProgram(state, action) {
    //   state.selectedProgram = action.payload;
    // },
  },
});

// export actions
export const {
  programsRequest,
  programsSuccess,
  programsFailure,
  programRequest,
  programSuccess,
  programFailure,
  UserProgramsRequest,
  UserProgramsSuccess,
  UserProgramsFailure,
  // setSelectedProgram,
} = programsSlice.actions;

// export reducer
export const { reducer: programsReducer } = programsSlice;

const dispatchFetchPrograms =
  (categoryId, limit = 3) =>
  async (dispatch) => {
    try {
      dispatch(programsRequest());

      const response = await Programs.getPrograms(categoryId, limit);

      if (response.data.metaData.key === "success") {
        dispatch(programsSuccess({ data: response.data.data, categoryId }));
      } else if (response.data.metaData.key === "not_found") {
        dispatch(programsFailure());
      }
    } catch (error) {
      console.log(error);
      dispatch(programsFailure(error.toString()));
    }
  };

const dispatchFetchProgram = (programId, callback) => async (dispatch) => {
  try {
    dispatch(programRequest());

    const response = await Programs.getProgram(programId);
    callback(response.data.data.is_user_subscribed);

    dispatch(programSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    dispatch(programFailure(error.toString()));
  }
};

const dispatchFetchUserPrograms = () => async (dispatch) => {
  try {
    dispatch(UserProgramsRequest());

    const response = await Programs.getUserPrograms();
    if (response.data.metaData.key === "success") {
      dispatch(UserProgramsSuccess(response.data.data));
    }
  } catch (error) {
    console.log(error);
    dispatch(UserProgramsFailure(error.toString()));
  }
};
// const dispatchSelectedProgram = (program) => async (dispatch) => {
//   dispatch(setSelectedProgram(program));
// };

export {
  dispatchFetchPrograms,
  dispatchFetchProgram,
  dispatchFetchUserPrograms,
};
