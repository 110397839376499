import { get, post } from "../../config/api";

export const Exams = {
  getProgramCollections: (programId) =>
    get(`/programs/${programId}/collections`),
  getCollectionExams: (collectionId) =>
    get(`/collections/${collectionId}/exams`),
  getExamQuestions: (examId) => get(`/exams/${examId}/questions`),
  getExamReviewQuestions: (collectionId, examId) =>
    get(`collections/${collectionId}/exams/${examId}/review`),
  postExam: (collectionId, examId, data) =>
    post(`collections/${collectionId}/exams/${examId}/do-exam`, data),
  resetCollections: (collectionId) =>
    post(`/collections/${collectionId}/reset`),
  getCollectionStats: (collectionId) =>
    get(`collections/${collectionId}/stats`),
};
