export const examsDiff = (updated, old) => {
  return (
    updated.filter(({ id }) => !old.some((exam) => exam.id === id)).length > 0
  );
};

export const convertMinutes = (seconds) => {
  if (seconds < 60) return `00:00:${seconds}`;

  if (seconds < 3600) {
    let minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    if (seconds < 10) seconds = `0${seconds}`;
    return `00:${minutes}:${seconds}`;
  }

  let hours = Math.floor(seconds / 3600);

  let minutes = seconds % 3600;
  seconds = Math.floor(minutes % 60);
  minutes = Math.floor(minutes / 60);

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${hours}:${minutes}:${seconds}`;
};
