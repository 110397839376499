import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ExamQuestionLayout = styled.div`
  direction: rtl;
`;

// question header
export const ExamQuestionHeaderWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 14px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media("large")} {
    padding: 14px 12px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ExamTimeContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const ExamTime = styled.span`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
  margin-left: 15px;
  color: rgb(var(--secondary));
`;
export const CloseButton = styled.div`
  height: 35px;
  width: 35px;
  color: #cbc2d6;
  border: 1px solid #cbc2d6;
  border-radius: 50%;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ExamTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;

  color: rgba(var(--primary-light));
`;

//footer styles
export const ExamQuestionFooterWrapper = styled.div`
  padding: 17px 36px;
  background-color: #fff;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  box-shadow: 0 11px 66px 0 rgba(203, 194, 213, 0.22);
  bottom: 0;

  ${media("large")} {
    flex-direction: column;
    padding: 17px 12px;
  }
`;

export const BookMarksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(var(--primary-light));
  font-size: 1.6rem;
  font-weight: bold;
  span,
  p {
    margin-right: 8px;
  }
`;
export const QuestionNavigationContainer = styled.div`
  ${media("large")} {
    margin-top: 20px;
  }
  ${media("small")} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;
export const NextQuestionButton = styled.button`
  height: 46px;

  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  font-size: 1.4rem;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;
export const PrevQuestionButton = styled.button`
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  background-color: transparent;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 45px;

  color: rgba(var(--black-dark), 0.8);
  ${media("small")} {
    margin-left: 0;
  }
`;
export const ProgressBar = styled.progress`
  flex: 1;
  max-width: 667px;
  &[value] {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    border-radius: 7px;
    -webkit-animation: animate-stripes 5s linear infinite;
    animation: animate-stripes 5s linear infinite;
  }

  &[value]::-webkit-progress-bar {
    background-color: #f1f2f2;
    border-radius: 7px;
  }

  &[value]::-webkit-progress-value {
    background-color: rgb(var(--secondary));
    border-radius: 7px;
  }
  ${media("large")} {
    flex: unset;
    margin-top: 20px;
    width: 100%;
  }
`;

// exam question body
export const ExamQuestionWrapper = styled.div`
  background-image: linear-gradient(154deg, #f6faff 11%, #f9fcff 35%, #fff 77%);
  width: 100%;
  padding: 32px 0 208px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media("large")} {
    padding: 32px 12px 260px;
  }
`;

export const QuestionHeaderContainer = styled.div`
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
`;

export const QuestionTextInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const QuestionSettings = styled.div`
  display: flex;
`;
export const QuestionTitleContainer = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: #a7a9ac;
`;
export const QuestionTitle = styled.span``;
export const QuestionNumber = styled.span`
  margin-right: 12px;
`;

export const BookMarkContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #a7a9ac;
    margin-right: 7px;
  }
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;
export const ReportContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 38px;
  p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #a7a9ac;
    margin-right: 7px;
  }
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const QuestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 37px;
    border-radius: 15px;
    max-width: 100%;
    align-self: center;
  }
  .react-audio-player {
    margin-top: 37px;

    min-width: 500px;
    height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 37px 18px 37px 28px;
    border-radius: 15px;
    box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
    background-color: #fff;
    ${media("large")} {
      min-width: 400px;
    }
    audio {
      /*border-radius: 90px;*/
      color: red;
      background: red;
    }

    &::-webkit-media-controls-enclosure {
      border-radius: 5px;
      background-color: transparent;
    }
    /* &::-webkit-media-controls-mute-button {
      display: none !important;
    }

    &::-webkit-media-controls-volume-slider {
      display: none !important;
    }

    &::-webkit-media-controls-volume-control-container.closed {
      display: none !important;
    }
    &::-webkit-media-controls-volume-control-container {
      display: none !important;
    } */
  }

  .video-player-wrapper {
    margin-top: 37px;
    /* align-self: center; */
    position: relative;
    width: 100%;
    /* position: relative; */
    padding-top: 56.25%;
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const QuestionHeader=styled.div`
  margin-top: 12px;
  color: rgb(var(--secondary));
  font-size: 2.4rem;
  font-weight: bold;
  width:100%;
`
export const QuestionText = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  text-align: right;
  margin-top: 12px;
  line-height: 1.4;
  color: rgb(var(--secondary));
`;

export const ChoicesContainer = styled.div`
  margin-top: 96px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ChoiceItemContainer = styled.div`
  padding: 15px 24px 16px 23px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 21px;
  }
`;
export const ChoiceText = styled.p`
  font-size: 1.6rem;
  letter-spacing: normal;
  text-align: right;
  color: rgb(var(--black-dark));
  margin-right: 15px;
`;
export const ChoiceImage = styled.img`
  margin-right: 15px;
  max-width: 95%;
`;

export const CorrectIcon = styled.div`
  min-width: 22px;
  height: 22px;
  background-color: #21ba45;
  background-color: ${(props) => (props.correct ? "#21ba45" : " #db2828")};

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin-right: auto;
`;

export const AudioChoice = styled.div`
margin-right: 10px;

  ${media("medium")} {
    .audio-player {
      width: 250px;
    }
  }
`;

export const BookMarksItems = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 200px;

  background-color: white;
  bottom: 80px;
  right: 30px;
  max-height: ${(props) => (props.active ? "500px" : "0px")};
  padding: ${(props) => (props.active ? "20px 0" : "0")};
  overflow-y: ${(props) => (props.active ? "scroll" : "hidden")};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 -8px 27px 0 rgb(203, 194, 213, 23%);
  z-index: 1;
  transition: all 3s;
`;
export const BookMarkItem = styled.button`
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f6faff;
  }
  transition: all 0.3s;
`;
export const BookmarkButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  cursor: pointer;
`;

export const SolveButton = styled.button`
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--primary-light));
  font-size: 1.4rem;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 45px;

  &:hover {
    background-color: rgba(var(--primary-light), 0.94);
  }
  ${media("medium")} {
    margin-right: unset;
    margin-bottom: 10px;
  }
  ${media("small")} {
    margin-left: 0;
  }
`;