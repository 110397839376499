import styled, { css } from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 32px 64px 56px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  direction: rtl;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  /* ${media("medium")} {
    flex-direction: column;
    justify-content: center;
  } */
`;

export const ModalTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.69;

  text-align: right;
  color: rgba(35, 31, 32, 0.87);
}`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const FooterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  margin-top: 27px;
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  align-self: center;
  background-color: rgb(var(--secondary-light));
  background-color: ${({ outline }) =>
    outline ? "#fff" : "rgb(var(--secondary-light))"};
  color: ${({ outline }) => (outline ? "#231f20" : "#fff")};
  border: ${({ outline }) => (outline ? "1px solid #231f20" : "none")};

  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ outline }) =>
      outline ? "#fff" : "rgba(var(--secondary-light),.9)"};
  }
`;
