import React, { useState, useEffect } from "react";
import { OBJQUizAPI } from "services/api/aifeature";
import { Input } from "components/ui/RadioCheckBox/RadioCheckBox.styles";
import {
  ChoicesContainer,
  ChoiceItemContainer,
  ChoiceText,
  QuestionImage,
  QuestionImageContainer,
  PDFQuizBodyContner,
  ExamQuestionFooterWrapper,
} from "./PDFQuiz.styles";
import {
  QuestionCounter,
  NextQuestionButton,
} from "components/DocumentGPT/StyledComponents";
import { ProgressBar } from "components/DocumentGPT/QuizComponent.styles";
import {
  QuestionTextInfo,
  QuestionTitleContainer,
  QuestionTitle,
  QuestionNumber,
  QuestionSettings,
  BookMarkContainer,
} from "components/DocumentGPT/QuizComponent.styles";
import Results from "./Results";
import Loader from "components/DocumentGPT/Loader";
import {
  ExamTimeContainer,
  ExamTime,
} from "components/ExamQuestion/ExamQuestion.styles";
import Alarm from "assets/img/alarm.svg";
import ConfirmationModal from "./ConfirmationModal"

function QuizComponent({ PDFId, setShowQuiz, fetchPerformanceData }) {
  const [quizId, setQuizId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [timer, setTimer] = useState(0); // Timer starts from 0
  const [quizEnd, setQuizEnd] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [quizResults, setQuizResults] = useState(null);
  const [isImageLoading, setImageLoading] = useState(true);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const getQuizData = async () => {
      try {
        if (PDFId) {
          const response = await OBJQUizAPI.getQuiz(PDFId);
          if (response?.quiz_id != null){
          setQuestions(response?.questions);
          setQuizId(response?.quiz_id);}
          else{
            setOpen(true)
          }
        }
      } catch (error) {
        console.error("Error fetching the quiz questions:", error);
      }
    };
    getQuizData();
  }, [PDFId]);
  console.log("Width is ", window.innerWidth, window.outerWidth)
  useEffect(() => {
    setSelectedOption(null);
  }, [currentQuestion]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    const option = Number(e.target.value);
    setUserAnswers((prev) => ({
      ...prev,
      [questions[currentQuestion]?.id]: option,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (selectedOption != null) {
        const response = await OBJQUizAPI.submitAnswer(
          quizId,
          questions[currentQuestion].id,
          selectedOption
        );
        return response;
      }
    } catch (error) {
      console.error("Error submitting the answer:", error);
    }
  };
  
  const handleNextQuestion = async () => {
    setImageLoading(true);
    if (currentQuestion < questions.length - 1) { 
      setCurrentQuestion(currentQuestion + 1);
    } 
    else {
      setLoading(true);
      await handleSubmit();
      clearInterval(window.quizTimerID); // Stop the timer
      const results = await OBJQUizAPI.submitQuiz(quizId);
      setQuizResults(results);
      setQuizEnd(true);
      setLoading(false);
      fetchPerformanceData();
    }
  };

  useEffect(() => {
    const quizTimer = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    // Save the timer ID to clear it later
    window.quizTimerID = quizTimer;

    return () => clearInterval(quizTimer);
  }, []);

  useEffect(() => {
    setSelectedOption(userAnswers[questions[currentQuestion]?.id] || null);
  }, [currentQuestion, userAnswers]);

  const options = [1, 2, 3, 4];
  const options_show = ["أ","ب","ج","د"]

  const formatTimeMinSec = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;

    // Add the padStart method here
    minutes = minutes.toString().padStart(2, '0');
    seconds = seconds.toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
};


  return (
    <PDFQuizBodyContner>
      {open && <ConfirmationModal
      setOpen={setOpen}
      open={open}
      setShowQuiz={setShowQuiz}
      />}
      <QuestionCounter>
        {isLoading ? (
          <Loader />
        ) : quizEnd ? (
          <>
            <Results
              quizResults={quizResults}
              quiz_id={quizId}
              setShowQuiz={setShowQuiz}
              timer={formatTimeMinSec(timer)}
            />
          </>
        ) : questions.length > 0 ? (
          <>
            <QuestionTextInfo style={{ margin: "10px" }}>
              <QuestionTitleContainer>
                <QuestionTitle>سؤال: </QuestionTitle>
                <QuestionNumber>
                  ({questions.length}/{currentQuestion + 1})
                </QuestionNumber>
              </QuestionTitleContainer>
              <ExamTimeContainer>
                <img src={Alarm} alt="" />
                <ExamTime style={{ margin: "10px" }}>
                  {formatTimeMinSec(timer)}
                </ExamTime>
              </ExamTimeContainer>
              <QuestionSettings>
                <BookMarkContainer>
                  <button onClick={() => setShowQuiz(false)}>X</button>
                </BookMarkContainer>
              </QuestionSettings>
            </QuestionTextInfo>
            <QuestionImageContainer>
              <QuestionImage
                src={questions[currentQuestion]?.question_statement}
                width={1050}
                alt="Question"
                onLoad={() => setImageLoading(false)}
                style={{ display: isImageLoading ? "none" : "block" }}
              />
            </QuestionImageContainer>
            <ChoicesContainer>
              {options.reverse().map((option) => (
                <ChoiceItemContainer key={option}>
                  <label style={{ fontSize: "30px", fontWeight: "bold" }}>
                    <Input
                      name="quizOption"
                      type="radio"
                      value={option}
                      checked={option === selectedOption}
                      onChange={handleOptionChange}
                    />
                  </label>
                  <ChoiceText style={{ fontSize: "15px", fontWeight: "bold" }}>{options_show[option-1]}</ChoiceText>
                </ChoiceItemContainer>
              ))}
            </ChoicesContainer>
            <ExamQuestionFooterWrapper>
              <ProgressBar value={currentQuestion} max={questions.length - 1} />
              <NextQuestionButton
                onClick={() => {
                  handleSubmit();
                  handleNextQuestion();
                }}
              >
                {currentQuestion === questions.length - 1
                  ? "إرسال الاختبار"
                  : "السؤال التالي"}
              </NextQuestionButton>
            </ExamQuestionFooterWrapper>
          </>
        ) : (
          <Loader />
        )}
      </QuestionCounter>
    </PDFQuizBodyContner>
  );
}
export default QuizComponent;
