import CircleProgressBar from "components/ui/CircleProgressBar";
import { useMemo } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useHistory, useParams } from "react-router";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { dispatchResetExamData } from "store/slices/exams";
import {
  FooterButton,
  ModalBodyContainer,
  ModalContent,
  ModalFooter,
  ModalTitle,
  ReportInfo,
  ReportItem,
  ReportsContainer,
  ReportTitle,
  FailingMessage,
  SharesContainer,
  ActionsContainer,
} from "./ExamResultModal.styles";

const ExamResultModal = ({
  setOpen,
  open,
  mode,
  results,
  handleExamReview,
  selectedExam,
  examTime,
}) => {
  const modalRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { programId, categoryId } = useParams();
  const closeModal = () => {
    dispatch(dispatchResetExamData());
    setOpen(false);
    history.push(`/categories/${categoryId}/programs/${programId}/exams`);
  };

  const TextToShare = useMemo(
    () => `لو عندكم اختبار (${
      selectedExam?.category
    }) قريب اختبر نفسك واتدرب في منصة تميز
    %انا اختبرت وجبت ${Math.floor(
      (results?.filter((r) => r.isCorrect)?.length / results?.length) * 100
    )}
سجل واختبر نفسك من هنا
`,
    [results, selectedExam]
  );

  const LinkToShare = useMemo(
    () => `https://tmyyoz.com.sa?referral_code=${user.referral_code}`,
    [user]
  );
  const handleShareModal = () => {};
  return (
    <>
      <Modal
        ref={modalRef}
        open={open}
        onClose={() => setOpen(false)}
        initialFocusRef={modalRef}
        showCloseIcon={mode === "exercises" ? true : false}
        closeOnOverlayClick={mode === "exercises" ? true : false}
        classNames={{
          root: "custom-root",
          overlay: "app-modal-overlay",
          modal: "app-modal",
          closeButton: "app-modal-close",
        }}
        center
      >
        {results && results.length > 0 && (
          <ModalBodyContainer>
            <ModalTitle>
              {mode == "exercises" ? "نتيجة التدريب" : "نتيجة الإختبار"}
            </ModalTitle>
            <ModalContent>
              <CircleProgressBar
                percentage={Math.floor(
                  (results.filter((r) => r.isCorrect).length / results.length) *
                    100
                )}
                width="100px"
                color="#ca57a0"
              />
              {mode === "exercises" ? null : <h1>{selectedExam.title}</h1>}
              {mode !== "exercises" &&
                results.filter((r) => r.isCorrect).length <
                  selectedExam.passing_grade && (
                  <FailingMessage>
                    {" "}
                    لم تجتز الامتحان <br />
                    يرجى المحاولة مرة أخرى
                  </FailingMessage>
                )}
              <ReportsContainer>
                <ReportItem>
                  <ReportInfo>
                    {results.filter((r) => r.isCorrect).length}
                  </ReportInfo>
                  <ReportTitle>إجابات صحيحة</ReportTitle>
                </ReportItem>
                <ReportItem wrong>
                  <ReportInfo wrong>
                    {results.filter((r) => !r.isCorrect).length}
                  </ReportInfo>
                  <ReportTitle wrong>إجابات خاطئة</ReportTitle>
                </ReportItem>
                {mode === "exercises" ? null : (
                  <ReportItem purple>
                    {/* <ReportInfo purple>١٥:٤٥ د</ReportInfo> */}
                    <ReportInfo purple>
                      {`${new Date(examTime).getMinutes()}:${new Date(
                        examTime
                      ).getSeconds()}`}{" "}
                      د
                    </ReportInfo>

                    <ReportTitle purple>وقت الاختبار</ReportTitle>
                  </ReportItem>
                )}
              </ReportsContainer>
            </ModalContent>
            {mode === "exercises" ? null : (
              <ModalFooter>
                {/* <FooterButton onClick={() =>handleShareClick()}>مشاركة الدرجة</FooterButton> */}
                <ActionsContainer>
                  <FooterButton outline onClick={() => handleExamReview()}>
                    مراجعة الاختبار
                  </FooterButton>
                  <FooterButton onClick={() => closeModal()}>
                    إغلاق
                  </FooterButton>
                </ActionsContainer>
                <FailingMessage>شارك رابط الاختبار</FailingMessage>
                <SharesContainer>
                  <TwitterShareButton
                    url={LinkToShare}
                    title={TextToShare}
                    hashtag="#تميز"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={LinkToShare}
                    title={TextToShare}
                    separator="  "
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <FacebookShareButton
                    url={LinkToShare}
                    quote={TextToShare}
                    hashtag="#تميز"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <TelegramShareButton url={LinkToShare} title={TextToShare}>
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </SharesContainer>
              </ModalFooter>
            )}
          </ModalBodyContainer>
        )}
      </Modal>
    </>
  );
};

export default ExamResultModal;
