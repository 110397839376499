/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  subscriptionId: null,
  coupon: "",
  finalPrice: "",
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: INITIAL_STATE,
  reducers: {
    setSubscriptionId(state, action) {
      state.subscriptionId = action.payload;
    },

    removeSubscriptionId(state) {
      state.subscriptionId = null;
    },
    setCoupon(state, action) {
      state.coupon = action.payload;
    },
    setFinalPrice(state, action) {
      state.finalPrice = action.payload;
    },
    resetCoupon(state) {
      state.coupon = "";
    },
    resetPrice(state) {
      state.finalPrice = "";
    },
  },
});

// export actions
export const {
  setSubscriptionId,
  removeSubscriptionId,
  setCoupon,
  resetCoupon,
  setFinalPrice,
  resetPrice,
} = paymentSlice.actions;

// export reducer
export const { reducer: paymentReducer } = paymentSlice;

const dispatchSetCoupon = (data) => (dispatch) => {
  dispatch(setCoupon(data));
};
const dispatchSetFinalPrice = (data) => (dispatch) => {
  dispatch(setFinalPrice(data));
};
const dispatchResetCoupon = () => (dispatch) => {
  dispatch(resetCoupon());
};
const dispatchResetPrice = () => (dispatch) => {
  dispatch(resetPrice());
};
export {
  dispatchSetCoupon,
  dispatchResetCoupon,
  dispatchSetFinalPrice,
  dispatchResetPrice,
};
