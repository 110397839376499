import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "../../styles/utils";

export const HeroContainer = styled.div`
  width: 100%;
  background-image: linear-gradient(
    154deg,
    #f6faff 11%,
    #f9fcff 46%,
    #ffffff 77%
  );

  display: flex;
  justify-content: space-between;
  align-items:flex-start;
  padding-right: 140px;
  padding-block:60px;
  
  ${media("large")} {
    padding: 39.6px 12px 39.6px ;
    align-items:center;
    
  }
  ${media("medium")} {
    
    flex-direction: column;
    justify-content: center;
  }
  ${media("small")} {
    padding: 59.6px 12px 42.7px;
  }
`;

export const ImageWrapper=styled.div`
  width:50%;
  
  img.hero-background {
    flex:1;
    width: 90%;
    max-width: 654px;
    object-fit: contain;
  }
  ${media("medium")} {
    display:none;
    width:100%;
  }
`
export const HeroTextContainer = styled.div`
position:relative;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:50%;

  img{
    :nth-child(1){
      position:absolute;
    }
    
    display:none;
    ${media('small')}{
      display:block;
    }
  }
  h1 {
    align-self: flex-end;
    color: rgb(var(--primary));
    font-size: 35px;
    text-align: right;
    font-weight: bold;
    line-height: 1.5;
    ${media("medium")} {
      width: 100%;
      text-align: center;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 1.6;
    text-align: right;
    margin-top: 1rem;
    font-weight: 500;

    ${media("medium")} {
      width: 100%;
      text-align: center;
    }
  }
  ${media("medium")} {
    width:100%;
  }
`;

export const SliderContainer = styled.div`
  max-width: 100%;
  margin-top: 45px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  /* .slick-slider {
    max-width: 600px;
    .slick-dots {
      li button:before {
        font-size: 10px !important;
        opacity: 0.5;
        color: #332064;
      }
      li.slick-active button:before {
        font-size: 12px !important;
        color: #332064;
        opacity: 1;
      }
    }
  } */
`;
export const SliderItem = styled.div`
  background-color: white;
  padding: 33px 16px 27px 17px;
  max-width: 162px;
  // height: "201px";
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  flex-basis: 33%;
  transition:all .3s ease;
  ${media("medium")} {
    padding: 33px 5px 27px;
    flex-basis: 100%;
  }
  &:hover{
    transform:scale(1.1);
  }
`;

// export const SliderTitle = styled.h2({
//   color: "rgb(var(--black-dark))",
//   fontSize: "1.5rem",
//   fontWeight: "bold",
//   marginTop: "22px",
// });

export const SliderTitle = styled(Link)`
  color: rgb(var(--black-dark));
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 22px;
  text-decoration: none;
  text-align: center;
`;

export const SliderText = styled.p({
  color: /* "rgba(var(--black-dark),0.8)" */ "#B74190",
  lineHeight: "1.7",
  marginTop: "12px",
  fontSize: "1.4rem",
  textAlign: "center",
  direction: "rtl",
});


export const TestContainer=styled.div`
  position:relative;
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:1rem;
  direction: rtl;
  margin-inline-start: auto;
  margin-top:2rem;
  ${media("medium")} {
    margin-inline-start: 0;
    
  }
  ${media("small")} {
    
    grid-template-columns:1fr ;
  }
  img{

    position:absolute;
    display:none;
    ${media("small")}{
      display:block;
      z-index: 1111;
      margin-inline: 25rem;
      margin-block: -4rem;
    }
  }
`
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  text-align:center;
  height: 58px;
  width: 220px;
  
  padding: 9px 14px 12px;
  border-radius: 10px;
  align-self: center;
  
  background-color: rgb(var(--secondary-light));
  background-color: ${({ outline }) =>
      outline ? "#fff" : "rgb(var(--secondary-light))"};
  color: ${({ outline }) => (outline ? "#231f20" : "#fff")};
  border: ${({ outline }) => (outline ? "1px solid #231f20" : "none")};

  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ outline }) =>
      outline ? "#fff" : "rgba(var(--secondary-light),.9)"};
  }
  ${media('small')}{
    width:260px;
    
  }
`


export const ArrowToBottom=styled.div`
  display:none;
  place-items:center;
  width:34px;
  height:34px;
  margin-top:2rem;
  border-radius:10px;
  border:1px solid #B74190;
  position:relative;
  color:#B74190;
  cursor:pointer;
  ${media('small')}{
    display:grid;
  }
  
`