import styled, { css } from "styled-components/macro";

export const ModalBodyContainer = styled.div`
  max-width: 720px;
  width: 100%;
  padding: 32px 64px 56px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  direction: rtl;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* height: 400px; */
`;

export const ModalTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.69;

  text-align: right;
  align-self: self-start;
  color: rgba(35, 31, 32, 0.87);
}`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
`;

export const FooterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  width: 138px;
  border: none;
  margin-top: 27px;
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  align-self: center;
  background-color: rgb(var(--secondary-light));
  background-color: ${({ outline }) =>
    outline ? "#fff" : "rgb(var(--secondary-light))"};
  color: ${({ outline }) => (outline ? "#231f20" : "#fff")};
  border: ${({ outline }) => (outline ? "1px solid #231f20" : "none")};

  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ outline }) =>
      outline ? "#fff" : "rgba(var(--secondary-light),.9)"};
  }
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
`;
export const ItemContainer = styled.div`
  width: 486px;
  height: 48px;
  margin: 52px 0 11px;
  padding: 10px 20px 10px 304px;
  border-radius: 15px;
  background-color: rgba(var(--primary-light), 0.03);
`;

export const OptionsContainer = styled.div``;
export const TextArea = styled.textarea`
  margin-top:15px;
  width: 100%;
  padding: 15px 24px 15px 23px;
  border-radius: 15px;
  border: solid 1px;
  background-color: #f6faff;
  font-size: 1.1em;
  line-height:2;
  border-color: ${({ error }) => (error ? "red" : "#b8dafc")};
  outline:none;
  resize:none;
`;
