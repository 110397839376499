import { ToastContainer } from "react-toastify";
import Footer from "../footer/Footer";
import Header from "../Header/Header";
import LoadingBar from "react-redux-loading-bar";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dispatchSetReferralCode } from "store/slices/auth";

const Layout = ({ children }) => {
  const search = useLocation().search;

  const dispatch = useDispatch();
  useEffect(() => {
    if (new URLSearchParams(search).get("referral_code")) {
      dispatch(
        dispatchSetReferralCode(
          new URLSearchParams(search).get("referral_code")
        )
      );
    }
  }, []);

  return (
    <div className='main-container'>
      <ToastContainer
        position='top-center'
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoadingBar style={{ backgroundColor: "#332064", height: "3px" }} />
      <Header />

      {children}
      <Footer />
    </div>
  );
};

export default Layout;
