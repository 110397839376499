import React, { memo, useEffect, useState } from "react";
import { OBJQUizAPI } from "services/api/aifeature";
import CircleProgressBar from "components/ui/CircleProgressBar";
import {
  ReportInfo,
  ReportTitle,
  ReportsContainer,
} from "components/Modals/ExamResultModal/ExamResultModal.styles";
import { ReportItem, NextQuestionButton,
    PrevQuestionButton,
     } from "components/DocumentGPT/StyledComponents";
import { Button } from "components/Hero/Hero.styles";

import { QuestionText } from "components/ExamQuestion/ExamQuestion.styles";
import {
  ChoiceItemContainer,
  ChoiceText,
  ChoicesContainer,
  QuestionImage,
  QuestionImageContainer,
  ExamQuestionFooterWrapper
} from "./PDFQuiz.styles";

import {
    ExamTimeContainer,
    ExamTime,
  } from "components/ExamQuestion/ExamQuestion.styles";
  import Alarm from "assets/img/alarm.svg";

import {
  QuestionTextInfo,
  QuestionTitleContainer,
  QuestionTitle,
  QuestionNumber,
  QuestionSettings,
  BookMarkContainer,
  ExamQuestionWrapper
} from "components/DocumentGPT/QuizComponent.styles";

import { Input } from "components/ui/RadioCheckBox/RadioCheckBox.styles";
import { CorrectIcon, ProgressBar } from "components/DocumentGPT/QuizComponent.styles";
const Results = ({ quizResults, quiz_id, setShowQuiz, timer }) => {
  const [reviewData, setReviewData] = useState([]);
  const [showReview, setShowReview] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleShowReview = () => {
    setShowReview(!showReview);
  };
  const handleHideReview = () => {
    setShowReview(false);
  };

  const handleNext = () => {
    if (currentQuestion < 9) {
      setCurrentQuestion(currentQuestion + 1);
    }
    else{
        setShowReview(false)
        setCurrentQuestion(0)
    }
  };
  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  useEffect(() => {
    const handleGetQuizReview = async () => {
      try {
        const response = await OBJQUizAPI.GetQuizReview(quiz_id);
        setReviewData(response?.questions);
      } catch (error) {
        console.log("Error in Quiz Review data fetching:", error);
      }
    };
    handleGetQuizReview();
  }, []);
  console.log(quiz_id);
  const options = [4, 3, 2, 1];
  const options_show = ["أ","ب","ج","د"]
  return (
    <div style={{width:"100%"}}>
      <QuestionTextInfo>
        <QuestionTitleContainer>
          {showReview && (
            <>
              <QuestionTitle>سؤال:</QuestionTitle>
              <QuestionNumber>
                ({10}/{currentQuestion + 1})
              </QuestionNumber>
            </>
          )}
        </QuestionTitleContainer>
        <QuestionSettings>
          <BookMarkContainer>
            <button onClick={() => setShowQuiz(false)}>X</button>
          </BookMarkContainer>
        </QuestionSettings>
      </QuestionTextInfo>
      {!showReview ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <QuestionText style={{ marginBottom: "20px" }}>
          نتيجة التدريب
          </QuestionText>
          {/* <CloseIconContainer>
               <AiOutlineCloseCircle/>
             </CloseIconContainer> */}
          <CircleProgressBar
            percentage={Math.floor((quizResults?.correct_questions / 10) * 100)}
            width="100px"
            color="#ca57a0"
          />
          <div>
            <ReportsContainer>
              <ReportItem>
                <ReportInfo>{quizResults?.correct_questions}</ReportInfo>
                <ReportTitle>إجابات صحيحة</ReportTitle>
              </ReportItem>
              <ReportItem wrong>
                <ReportInfo wrong>
                  {quizResults?.incorrect_questions}
                </ReportInfo>
                <ReportTitle wrong>إجابات خاطئة</ReportTitle>
              </ReportItem>
              <ReportItem purple>
                <ReportInfo purple>
                  {quizResults?.not_attempted_questions}
                </ReportInfo>
                <ReportTitle purple>تم تخطيه</ReportTitle>
              </ReportItem>
            </ReportsContainer>
          </div>
          <div style={{margin:"20px 10px 10px 10px"}}>
          <ExamTimeContainer>
                <img src={Alarm} alt="" />
                <ExamTime style={{ margin: "10px" }}>
                  {timer}
                </ExamTime>
              </ExamTimeContainer>
          </div>
          <div style={{ margin: "20px 0px" }}>
            <Button onClick={handleShowReview}>مراجعة الاختبار</Button>
          </div>
        </div>
      ) : (
        <div>
          <div>
          <ExamQuestionWrapper>
            <QuestionImageContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <QuestionImage
                  src={
                    reviewData[currentQuestion]?.question?.question_statement
                  }
                  width={1050}
                  alt="Question"
                />
              </div>
            </QuestionImageContainer>
            <div>
              <ChoicesContainer>
                {options.map((option) => (
                  <ChoiceItemContainer key={option}>
                    <label style={{ fontSize: "20px", fontWeight: "bold" }}>
                      <Input
                        disabled
                        name="quizOption"
                        type="radio"
                        value={option}
                        checked={option === reviewData[currentQuestion]?.option}
                      />
                    </label>
                    <ChoiceText>{options_show[option-1]}</ChoiceText>

                    {
                      reviewData[currentQuestion]?.option ===
                      reviewData[currentQuestion]?.question.answer ? (
                        option === reviewData[currentQuestion]?.option ? (
                          <CorrectIcon correct>&#10003;</CorrectIcon> // Show a check mark for correct selected option
                        ) : null // Do not show anything for other options if the user selected the correct option
                      ) : option === reviewData[currentQuestion]?.option ? (
                        <CorrectIcon>X</CorrectIcon> // Show an X mark for wrong selected option
                      ) : option ===
                        reviewData[currentQuestion]?.question.answer ? (
                        <CorrectIcon correct>&#10003;</CorrectIcon> // Show a check mark for correct option
                      ) : null // Do not show anything for other options if it's the correct option
                    }
                  </ChoiceItemContainer>
                ))}
              </ChoicesContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px",
                }}
              >
                <QuestionText>
                الخيار الصحيح: {options_show[reviewData[currentQuestion]?.question?.answer -1]}
                </QuestionText>
              </div>
            </div>
            <QuestionImageContainer>
            <QuestionImage
                  src={reviewData[currentQuestion]?.question?.answer_statement}
                  width={1050}
                  alt="Question"
                />
            </QuestionImageContainer>
            </ExamQuestionWrapper>
          </div>
          <ExamQuestionFooterWrapper>
          <ProgressBar
                  value={currentQuestion}
                  max={10 - 1}
                />
                <PrevQuestionButton onClick={handlePrev}>
                  السابق
                </PrevQuestionButton>
                <NextQuestionButton
                  onClick={handleNext}
                >
                  {"التالي"}
                </NextQuestionButton>
          </ExamQuestionFooterWrapper>
        </div>
      )}
    </div>
  );
};

const _Results = memo(Results);

export default _Results;
