import React, { useState, useEffect } from "react";
import Loader from "../DocumentGPT/Loader";
import { newGet } from "config/api/feature2";
import { OBJQUizAPI } from "services/api/aifeature";
import Header from "components/Header/Header";
import PDFQuizBody from "./PDFQuizBody";
import PDFListing from "./PDFListing";
import { MainContainer, NavWrapper } from "../DocumentGPT/StyledComponents";
import {
  ContentWrapper,
  ProgramTitleContainer,
  ProgramTitle,
  QuestionsContainer,
  BodyContainer,
  PerformanceContainer,
  TextContainer,
  ReportContainer,
  ReportRow,
  SuggestionConatainer,
  SuggestionText,
  ButtonContainer,
  Button,
  RootContentWrapper,
  SepratorContainer
} from "./PDFQuiz.styles";
import { PDFTitle } from "./PDFQuiz.styles";
import CircleProgressBar from "components/ui/CircleProgressBar";
import {
  ReportInfo,
  ReportItem,
  ReportTitle,
} from "components/DocumentGPT/StyledComponents";
import Footer from "components/footer/Footer";

const PDFQuiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [loading, setLoading] = useState();
  const [selectedPDf, setSelectedPDF] = useState(); //for setting the data from children
  const [PDFId, setPDFId] = useState(); //set the first PDFid inintially
  const [performance, setPerformance] = useState([]);
  const [quizId, setQuizId] = useState(null);
  const [quizResults, setQuizResults] = useState();
  const [showQuiz, setShowQuiz] = useState(false);
  const [categories, setCategories] = useState(null);

  const fetchPerformanceData = async () => {
    try {
      setLoading(true);
      if (PDFId != null) {
        const response = await newGet(`/quiz/results/${PDFId}`);
        setPerformance(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in fetching performance data:", error);
    }
  };

  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const response = await OBJQUizAPI.getPDFs();
        const cats = response.categories;
        const key = Object.keys(cats)[0];
        setCategories(cats);
        setSelectedPDF(cats[key][0]["pdf_name"]);
        setPDFId(cats[key][0]["id"]);
      } catch (error) {
        console.log(error);
        console.error("Error fetching categories: ", error.response);
      }
    };
    fetchPDFs().then(() => fetchPerformanceData());
  }, []);

  useEffect(() => {
    fetchPerformanceData();
  }, [PDFId]);

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const getQuizId = (quizId) => {
    setQuizId(quizId);
  };

  const handleQuizComplete = async () => {
    setIsQuizCompleted(true);
    setQuizStarted(false);
    try {
      const response = await OBJQUizAPI.submitQuiz(quizId);
      // console.log(response)
      setQuizResults(response);
    } catch (error) {
      console.log("Error in submitting quiz:", error);
    }
  };

  const handleSelectedPDF = (selectedPDF, PDFId) => {
    setSelectedPDF(selectedPDF);
    setPDFId(PDFId);
  };

  const handleQuizStarted = () => {
    setQuizStarted(true);
    setShowQuiz(true);
  };

  let correct_answers = performance?.correct_answers;
  let total_questions = performance?.total_questions;
  let message = performance?.message;
  let message_link = performance?.link;

  return (
    <>
      {loading && <Loader />}
      <RootContentWrapper quizStarted={quizStarted}>
        <Header />
        <ProgramTitleContainer>
          <ProgramTitle>هكر التجميعات</ProgramTitle>
        </ProgramTitleContainer>

        <PDFTitle>{selectedPDf}</PDFTitle>

        <ContentWrapper>
          <MainContainer>
            <NavWrapper>
              <PDFListing
                quizStarted={quizStarted}
                handleSelectedPDF={handleSelectedPDF}
                setLoading={setLoading}
                categories={categories}
                setSelectedPDF={setSelectedPDF}
                setPDFId={setPDFId}
                setShowQuiz={setShowQuiz}
              />
            </NavWrapper>

            <BodyContainer>
              <PerformanceContainer>
                <ReportContainer>
                  <ReportRow>
                    <div style={{ margin: "0px 20px" }}>
                      <ReportItem>
                        <ReportInfo>{performance?.correct_answers}</ReportInfo>
                        <ReportTitle>إجابات صحيحة</ReportTitle>
                      </ReportItem>
                    </div>
                    <div>
                      <ReportItem wrong>
                        <ReportInfo wrong>
                          {performance?.total_questions -
                            performance?.correct_answers}
                        </ReportInfo>
                        <ReportTitle wrong>إجابات خاطئة</ReportTitle>
                      </ReportItem>
                    </div>
                  </ReportRow>
                  <SuggestionConatainer>
                    <SuggestionText
                      href={message_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                  </SuggestionConatainer>
                </ReportContainer>
                <div style={{ margin: "0px 20px" }}>
                  <CircleProgressBar
                    percentage={Math.floor(
                      (correct_answers /
                        (total_questions == 0 ? 1 : total_questions)) *
                        100
                    )}
                    color="#ca57a0"
                    width="140px"
                  />
                </div>
                <SepratorContainer />
                <TextContainer>
                  <p>
                    عشان تعرف مستواك في القدرات ، اولاً استعن بالله ثانياً ركز
                    وأنت تحل الأسئلة كأنك في اختبار حقيقي
                  </p>
                  <ButtonContainer>
                    <Button disabled={showQuiz} onClick={handleQuizStarted}>
                      ابدأ اختبرني في التجميعات
                    </Button>
                  </ButtonContainer>
                </TextContainer>
              </PerformanceContainer>

              {showQuiz && (
                <QuestionsContainer>
                  <PDFQuizBody
                    PDFId={PDFId}
                    handleNextQuestion={handleNextQuestion}
                    currentQuestion={currentQuestion}
                    handleQuizComplete={handleQuizComplete}
                    quizStarted={quizStarted}
                    getQuizId={getQuizId}
                    quizResults={quizResults}
                    isQuizCompleted={isQuizCompleted}
                    setLoading={setLoading}
                    setShowQuiz={setShowQuiz}
                    fetchPerformanceData={fetchPerformanceData}
                  />
                </QuestionsContainer>
              )}
            </BodyContainer>
          </MainContainer>
          <Footer />
        </ContentWrapper>
      </RootContentWrapper>
    </>
  );
};

export default PDFQuiz;
