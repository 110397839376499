import {
  HeaderContainer,
  MenuContainer,
  LinksContainer,
  LoginLink,
  RegisterLink,
  LogoutButton,
  MenuItem,
  NavContainer,
  NavItem,
  MobileMenuIcon,
  FeatureLink,
  DropdownContainer,
  DropdownItem,
  ProfileContainer,
  ProfileDropDown,
  UserName,
  UserImage,
  LogoLink,
  FeatureLinka,
} from "./header.styles";
import React, { useState, useRef } from "react";
import { StyledBurger } from "../Menu/Menu.styles";
import RightNav from "../Menu/RightNav";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "store";
import DownArrow from "../../assets/img/down.svg";
import LogoImage from "../../assets/img/logo.svg";
import { useEffect } from "react";
import { dispatchFetchGroups } from "store/slices/groups";
import avatars from "data/avatars";
import { triningPrograms } from "data/menu";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const groups = useSelector((state) => state.groups.list);
  const dispatch = useDispatch();
  const profileDropdownRef = useRef();
  const menuDropdownRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/zoom") {
      dispatch(dispatchFetchGroups());
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleProfileDropDownClick);
    document.addEventListener("mousedown", handleMenuDropDownClick);

    return () => {
      document.removeEventListener("mousedown", handleProfileDropDownClick);
      document.removeEventListener("mousedown", handleMenuDropDownClick);
    };
  }, []);

  const handleProfileDropDownClick = (e) => {
    if (profileDropdownRef.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowProfileDropDown(false);
  };

  const handleMenuDropDownClick = (e) => {
    if (menuDropdownRef.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowDropDown(false);
  };

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const toggleProfileDropDown = () => {
    setShowProfileDropDown(!showProfileDropDown);
  };
  const handleLogout = () => {
    persistor.purge();
    window.location.reload();
  };

  return (
    <HeaderContainer>
      <LinksContainer>
        {isAuthenticated ? (
          <ProfileContainer open={showProfileDropDown} ref={profileDropdownRef}>
            <button onClick={toggleProfileDropDown}>
              <>
                <img src={DownArrow} alt="down" className="arrow-icon" />
              </>
            </button>
            <UserName onClick={toggleProfileDropDown}>{user.name}</UserName>
            <UserImage
              onClick={toggleProfileDropDown}
              src={avatars[`${user.avatar || "profile-0"}`]}
              alt="user image"
            />
            <ProfileDropDown open={showProfileDropDown}>
              {/* <hr /> */}
              <DropdownItem
                open={showProfileDropDown}
                to="/profile"
                active={false}
              >
                الملف الشخصي
              </DropdownItem>
              <DropdownItem
                open={showProfileDropDown}
                to="/my-programs"
                active={false}
              >
                دوراتي
              </DropdownItem>
              <DropdownItem
                open={showProfileDropDown}
                to="/subscriptions"
                active={false}
              >
                معلومات الاشتراك
              </DropdownItem>

              <hr />
              <LogoutButton
                open={showProfileDropDown}
                onClick={() => handleLogout()}
              >
                تسجيل الخروج
              </LogoutButton>
            </ProfileDropDown>
            <FeatureLinka
              href="https://mo.tmyyoz.com"
              target="_blank"
              style={{ "margin-left": "20px" }}
            >
              متميز AI  
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.3492 7.31293H0.973633V11.6984H7.3492V7.31293Z" fill="white"/>
            <path d="M18.0255 7.31293H11.6499V11.6984H18.0255V7.31293Z" fill="white"/>
            <path d="M11.6483 0.980469H7.34918L7.3492 7.31293L11.6483 7.3129V0.980469Z" fill="white"/>
            <path d="M11.6483 11.6999H7.34918V18.0323H11.6483V11.6999Z" fill="white"/>
            </svg>
            </FeatureLinka>
          </ProfileContainer>
        ) : (
          <>
            <RegisterLink
              to={{
                pathname: "/register",
                state: { fromHome: true },
              }}
            >
              حساب جديد
            </RegisterLink>
            <LoginLink to="/login">تسجيل دخول</LoginLink>
            <FeatureLinka
              href="https://mo.tmyyoz.com"
              target="_blank"
              style={{ "margin-left": "20px" }}
            >
              متميز AI
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.3492 7.31293H0.973633V11.6984H7.3492V7.31293Z" fill="white"/>
                    <path d="M18.0255 7.31293H11.6499V11.6984H18.0255V7.31293Z" fill="white"/>
                    <path d="M11.6483 0.980469H7.34918L7.3492 7.31293L11.6483 7.3129V0.980469Z" fill="white"/>
                    <path d="M11.6483 11.6999H7.34918V18.0323H11.6483V11.6999Z" fill="white"/>
            </svg>
            </FeatureLinka>
          </>
        )}
      </LinksContainer>
      <NavContainer>
        <MenuContainer>
          <NavItem>
            <MenuItem to="/" active={location.pathname === "/"}>
              الرئيسية
            </MenuItem>
          </NavItem>
          <NavItem open={showDropDown} ref={menuDropdownRef}>
            <button onClick={toggleDropDown}>
              <>
                <img src={DownArrow} alt="down" />
                <MenuItem active={location.pathname.includes("programs")}>
                  البرامج التدريبية
                </MenuItem>
              </>
            </button>

            <DropdownContainer open={showDropDown}>
              {triningPrograms.map((item, index) => (
                <DropdownItem
                  open={showDropDown}
                  onClick={() => setShowDropDown(false)}
                  to={item.path}
                  active={location.pathname === item.path}
                  key={index}
                >
                  {item.text}
                </DropdownItem>
              ))}
            </DropdownContainer>
          </NavItem>
          {/*    {React.Children.toArray(
            groups.length > 0 &&
            groups.map((group) => (
              <NavItem>
                <MenuItem to={`/groups/${group.id}/programs`} active={false}>
                  {group.name}
                </MenuItem>
              </NavItem>
            ))
          )} */}
          <NavItem>
            <MenuItem
              to="/about-us"
              active={location.pathname.includes("about-us")}
            >
              عن تميز
            </MenuItem>
          </NavItem>
          <NavItem>
            <MenuItem
              active={false}
              to={{
                pathname: "https://blog.tmyyoz.com.sa",
              }}
              target="_blank"
            >
              مدونة تميز
            </MenuItem>
          </NavItem>
        </MenuContainer>

        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <RightNav
          open={open}
          handleLogout={handleLogout}
          groups={groups}
          setOpen={setOpen}
        />
        <LogoLink to="/">
          <img src={LogoImage} height={37} width={63} alt="logo" />
        </LogoLink>
      </NavContainer>
    </HeaderContainer>
  );
};

export default Header;
