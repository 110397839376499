import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const CircleProgressBar = ({
  width = "40px",
  color = null,
  percentage = 50,
  fontSize = "30px",
  strokeWidth = 8,
}) => {
  // const percentage = 66;

  return (
    <CircularProgressbar
      value={percentage}
      text={`${percentage}%`}
      strokeWidth={strokeWidth}
      styles={{
        root: {
          width,
        },
        path: {
          stroke: color ? color : `rgba(77, 43, 118)`,
          strokeLinecap: "butt",
          transition: "stroke-dashoffset 0.5s ease 0s",
        },
        trail: {
          stroke: "#e6e7e8",
          strokeLinecap: "butt",
          transform: "rotate(0.25turn)",
          transformOrigin: "center center",
        },
        text: {
          fill: "rgba(35, 31, 32, 0.87)",
          fontSize: fontSize,
          fontWeight: "bold",
        },
        background: {
          fill: "#3e98c7",
        },
      }}
    />
  );
};

export default CircleProgressBar;
