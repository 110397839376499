import styled, { css } from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  max-width: 720px;
  width: 100%;
  padding: 32px 64px 56px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  direction: rtl;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${media("medium")} {
    padding: 32px 14px 56px;
    max-width: 350px;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.69;

  text-align: right;
  color: rgba(35, 31, 32, 0.87);

  ${media("medium")} {
    width: 100%;
    text-align: center;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: #684c88;
    margin-top: 23px;
  }
`;

export const ReportsContainer = styled.div`
  display: flex;
  gap: 29px;
  margin-top: 40px;
`;

export const ReportItem = styled.div`
  height: 63px;
  padding: 8.5px 30px;
  border-radius: 15px;
  box-shadow: 0 22px 66px 0 rgba(203, 194, 213, 0.31);
  background-color: ${({ wrong, purple }) =>
    wrong
      ? "rgba(var(--orange), 0.1)"
      : purple
      ? "#cbc2d6"
      : "rgba(var(--green), 0.1)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media("medium")} {
    padding: 8.5px 15px;
  }
`;
export const ReportTitle = styled.div`
  font-size: 1rem;
  text-align: center;
  color: ${({ wrong, purple }) =>
    wrong ? "rgb(var(--orange))" : purple ? "#684c88" : "rgb(var(--green))"};
  margin-top: 5px;
`;

export const ReportInfo = styled.div`
  min-height: 23px;
  padding: 0 8px 2px 9px;
  border-radius: 8px;
  box-shadow: 0 22px 66px 0 rgba(203, 194, 213, 0.31);

  background-color: ${({ wrong, purple }) =>
    wrong ? "rgb(var(--orange))" : purple ? "#684c88" : "rgb(var(--green))"};
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media("medium")} {
    font-size: 1.1rem;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction:column ;
  justify-content: center;
  gap: 16px;
  ${media("medium")} {
    justify-content: space-between;
  }

  button.share-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 1.4rem !important;
    font-weight: bold;
    cursor: pointer;
    border: none;
    margin-top: 27px;
    height: 46px;
    padding: 9px 24px 12px;
    border-radius: 10px;
    align-self: center;
    min-width: 90px;
    background-color: rgb(var(--secondary-light)) !important;

    color: #fff !important;

    transition: background-color 0.3s;
    &:hover {
      background-color: rgba(var(--secondary-light), 0.9) !important;
    }
    ${media("medium")} {
      padding: 9px 10px 12px;
    }
  }
`;

export const FooterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  margin-top: 27px;
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  align-self: center;
  background-color: rgb(var(--secondary-light));
  background-color: ${({ outline }) =>
    outline ? "#fff" : "rgb(var(--secondary-light))"};
  color: ${({ outline }) => (outline ? "#231f20" : "#fff")};
  border: ${({ outline }) => (outline ? "1px solid #231f20" : "none")};

  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ outline }) =>
      outline ? "#fff" : "rgba(var(--secondary-light),.9)"};
  }

  ${media("medium")} {
    padding: 9px 20px 12px;
  }
`;

export const FailingMessage = styled.p`
  margin-top: 16px;
  font-size: 1.4rem;
  color: rgba(var(--secondary-light));
  line-height: 1.4;
  text-align: center;
`;

export const SharesContainer = styled.div`
  display: flex;
  gap:10px;
  justify-content: center;
`
export const ActionsContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
`