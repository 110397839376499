/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Toast from "components/Toast/Toast";
import { Exercises } from "services/api/exercises";

const INITIAL_STATE = {
  exercises: [],
  questions: [],
  loading: false,
  errors: "",
  selectedPackage: {},
  studentAnswers: [],
  exercisesResult: [],
  hiddenChoices: [],
};

const exercisesSlice = createSlice({
  name: "exercises",
  initialState: INITIAL_STATE,
  reducers: {
    exercisesSuccess(state, action) {
      state.exercises = action.payload;
      state.loading = false;
    },
    exercisesFailure(state, action) {
      state = { ...INITIAL_STATE, errors: action.paylaod };
    },
    questionsRequest(state) {
      state.loading = true;
    },

    questionsSuccess(state, action) {
      state.questions = action.payload;
      state.loading = false;
    },
    questionsFailure(state, action) {
      state = { ...INITIAL_STATE, errors: action.paylaod };
    },

    setExerciseStudentAnswers(state, { payload }) {
      const { questionId, exerciseId, programId, answer } = payload;
      if (state.studentAnswers.length === 0) {
        state.studentAnswers = [
          {
            programId: programId,
            exerciseId: exerciseId,
            questionId: questionId,
            answer: { ...answer, checked: true },
          },
        ];
        return;
      }

      let answerIndex = state.studentAnswers.findIndex(
        (s) =>
          s.programId === programId &&
          s.exerciseId === exerciseId &&
          s.questionId === questionId
      );

      if (answerIndex !== -1) {
        state.studentAnswers = [
          ...state.studentAnswers.slice(0, answerIndex),
          { ...payload, answer: { ...payload.answer, checked: true } },
          ...state.studentAnswers.slice(answerIndex + 1),
        ];
        return;
      }
      state.studentAnswers = [
        ...state.studentAnswers,
        { ...payload, answer: { ...payload.answer, checked: true } },
      ];
    },
    setExerciseResult(state, { payload }) {
      const { questionId, exerciseId, programId, answer } = payload;

      // if this the first exercise
      if (state.exercisesResult.length === 0) {
        state.exercisesResult = [
          {
            programId: programId,
            exerciseId: exerciseId,
            questions: [
              {
                questionId: questionId,
                isCorrect: !!answer.is_correct,
              },
            ],
          },
        ];
        return;
      }
      // check if the this exercise is new or not
      let exerciseIndex = state.exercisesResult.findIndex(
        (s) => s.programId === programId && s.exerciseId === exerciseId
      );

      // if the exercise is exist before, we check for question if exist or not
      if (exerciseIndex !== -1) {
        // get the exercise from the state using ID
        let exercise = state.exercisesResult.filter(
          (s) => s.programId === programId && s.exerciseId === exerciseId
        )[0];

        // check if the is answered question is exist or not
        let questionIndex = exercise.questions.findIndex(
          (q) => q.questionId === questionId
        );

        // if the question is exist before, we just update the isCorrect field
        if (questionIndex !== -1) {
          state.exercisesResult = [
            ...state.exercisesResult.slice(0, exerciseIndex),
            {
              programId: programId,
              exerciseId: exerciseId,
              questions: exercise.questions.map((question) => {
                if (questionId !== question.questionId) return question;

                return { questionId, isCorrect: !!answer.is_correct };
              }),
            },
            ...state.exercisesResult.slice(exerciseIndex + 1),
          ];
        } else {
          // else add the question to the specific exercise
          state.exercisesResult = [
            ...state.exercisesResult.slice(0, exerciseIndex),
            {
              programId: programId,
              exerciseId: exerciseId,
              questions: [
                ...exercise.questions,
                {
                  questionId: questionId,
                  isCorrect: !!answer.is_correct,
                },
              ],
            },
            ...state.exercisesResult.slice(exerciseIndex + 1),
          ];
        }

        return;
      }

      // if this is a new exercise, then just added to the exercisesResult array
      state.exercisesResult = [
        ...state.exercisesResult,
        {
          programId: programId,
          exerciseId: exerciseId,
          questions: [
            {
              questionId: questionId,
              isCorrect: !!answer.is_correct,
            },
          ],
        },
      ];
    },
    setExerciseHiddenChoices(state, { payload }) {
      const { questionId, exerciseId, programId, answerId } = payload;
      if (state.hiddenChoices.length === 0) {
        state.hiddenChoices = [
          {
            programId: programId,
            exerciseId: exerciseId,
            questionId: questionId,
            answerId,
            hidden: true,
          },
        ];
        return;
      }

      let answerIndex = state.hiddenChoices.findIndex(
        (s) =>
          s.programId === programId &&
          s.exerciseId === exerciseId &&
          s.questionId === questionId &&
          s.answerId === answerId
      );

      if (answerIndex !== -1) {
        let ExistingAnswer = state.hiddenChoices.filter(
          (s) =>
            s.programId === programId &&
            s.exerciseId === exerciseId &&
            s.questionId === questionId &&
            s.answerId === answerId
        )[0];
        console.log(
          "🚀 ~ file: exercises.slice.js ~ line 194 ~ setExerciseHiddenChoices ~ ExistingAnswer",
          ExistingAnswer
        );
        state.hiddenChoices = [
          ...state.hiddenChoices.slice(0, answerIndex),
          {
            ...payload,
            hidden: !ExistingAnswer.hidden,
          },

          ...state.hiddenChoices.slice(answerIndex + 1),
        ];
        return;
      }
      state.hiddenChoices = [
        ...state.hiddenChoices,
        { ...payload, hidden: true },
      ];
    },
  },
});

// export actions
export const {
  exercisesRequest,
  exercisesSuccess,
  exercisesFailure,
  questionsRequest,
  questionsSuccess,
  questionsFailure,
  setExerciseStudentAnswers,
  setExerciseResult,
  setExerciseHiddenChoices,
} = exercisesSlice.actions;

// export reducer
export const { reducer: exercisesReducer } = exercisesSlice;

const dispatchFetchLessonExercises = (lessonId) => async (dispatch) => {
  try {
    dispatch(exercisesRequest());

    const response = await Exercises.getLessonExercises(lessonId);

    if (response.data.metaData.key === "success") {
      dispatch(exercisesSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(exercisesFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(exercisesFailure(error.toString()));
  }
};

const dispatchFetchExerciseQuestions = (exerciseId) => async (dispatch) => {
  try {
    dispatch(questionsRequest());

    const response = await Exercises.getExerciseQuestions(exerciseId);

    if (response.data.metaData.key === "success") {
      dispatch(questionsSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(questionsFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(questionsFailure(error.toString()));
  }
};

const dispatchSetExerciseStudentAnswers = (data) => (dispatch) => {
  dispatch(setExerciseStudentAnswers(data));
};

const dispatchSetExerciseResult = (data) => (dispatch) => {
  dispatch(setExerciseResult(data));
};
const dispatchSetExerciseHiddenChoices = (data) => (dispatch) => {
  dispatch(setExerciseHiddenChoices(data));
};

export {
  dispatchFetchLessonExercises,
  dispatchFetchExerciseQuestions,
  dispatchSetExerciseStudentAnswers,
  dispatchSetExerciseResult,
  dispatchSetExerciseHiddenChoices,
};
