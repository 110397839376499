/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Categories } from "services/api/categories";

const INITIAL_STATE = {
  list: [],
  lessons: [],
  loading: false,
  errors: "",
};

const subCategoriesSlice = createSlice({
  name: "subCategories",
  initialState: INITIAL_STATE,
  reducers: {
    subCategoriesRequest(state) {
      state.loading = true;
    },

    subCategoriesSuccess(state, action) {
      let lessons = [];
      state.list = action.payload;

      if (action.payload.length > 0) {
        action.payload.forEach((s) => {
          if (s.lessons.length > 0) {
            s.lessons.forEach((l) => {
              lessons.push(l);
            });
          }

          if (s.chapters.length > 0) {
            s.chapters.forEach((chapter) => {
              if (chapter.lessons.length > 0) {
                chapter.lessons.map((l) => {
                  lessons.push(l);
                });
              }
            });
          }
        });
      }
      state.lessons = lessons;
      state.loading = false;
    },
    subCategoriesFailure(state, action) {
      state.errors = action.payload;
      state.list = [];
      state.loading = false;
    },
  },
});

// export actions
export const {
  subCategoriesRequest,
  subCategoriesSuccess,
  subCategoriesFailure,
} = subCategoriesSlice.actions;

// export reducer
export const { reducer: subCategoriesReducer } = subCategoriesSlice;

const dispatchFetchSubCategories = (programId) => async (dispatch) => {
  try {
    dispatch(subCategoriesRequest());

    const response = await Categories.getSubCategories(programId);
    if (response.data.metaData.key === "success") {
      dispatch(subCategoriesSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(subCategoriesFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(subCategoriesFailure(error.toString()));
  }
};

export { dispatchFetchSubCategories };
