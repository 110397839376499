import SolutionModal from "components/Modals/SolutionModal";
import RadioCheckBox from "components/ui/RadioCheckBox/RadioCheckBox";
import { numbersMapping } from "data/numbersMapping";
import { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import {
  QuestionsContainer,
  QuestionTitleContainer,
  QuestionTitle,
  QuestionNumber,
  QuestionText,
  ChoicesContainer,
  ChoiceItemContainer,
  ChoiceText,
  QuestionsFooterContainer,
  QuestionNavigationContainer,
  NextQuestionButton,
  PrevQuestionButton,
  ProgressBar,
  SolveButton,
  QuestionMediaContainer,
  AudioChoice,
  HideChoiceButton,
  ShowAnswer,
  CorrectIcon,
  ReportContainer,
  QuestionInfo,
} from "./TrainingQuestions.styles";
import { Input } from "components/ui/RadioCheckBox/RadioCheckBox.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchSetExerciseHiddenChoices,
  dispatchSetExerciseResult,
  dispatchSetExerciseStudentAnswers,
} from "store/slices/exercises";
import PasswordIcon from "assets/img/view.svg";
import FlagIcon from "assets/img/flag.svg";

import { useParams } from "react-router";
import ExamResultModal from "components/Modals/ExamResultModal/ExamResultModal";
import QuestionReportModal from "components/Modals/QuestionReportModal/QuestionReportModal";
import React from "react";
import { ChoiceImage } from "components/ExamQuestion/ExamQuestion.styles";
import { StatisticsApi } from "services/api/statistics";

const TrainingQuestions = ({ questions, exerciseId, exercise }) => {
  const [solutionModalOpen, setSolutionModalOpen] = useState(false);
  const [examResultOpen, setExamResultOpen] = useState(false);
  const [questionReportOpen, setQuestionReportOpen] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showAnswers, setShowAnswers] = useState(false);
  const [showQuestionAnswer, setShowQuestionAnswer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const studentAnswers = useSelector((state) => state.exercises.studentAnswers);
  const hiddenChoices = useSelector((state) => state.exercises.hiddenChoices);
  const exercisesResult = useSelector(
    (state) => state.exercises.exercisesResult
  );

  const dispatch = useDispatch();
  const { programId } = useParams();

  useEffect(() => {
    let studentAnswer = studentAnswers.filter(
      (student) =>
        student.programId === programId &&
        student.exerciseId === exerciseId &&
        student.questionId === questions[currentQuestion].id
    )[0]?.answer?.id;
    if (studentAnswer) {
      setSelectedAnswer(studentAnswer);
    } else {
      setSelectedAnswer(null);
    }
  }, [questions[currentQuestion].id]);

  const handleSolveButtonClick = () => {
    setSolutionModalOpen(true);
  };

  const handleShowAnswerClick = () => {
    if (!selectedAnswer) return;
    setShowAnswers(true);
    setShowQuestionAnswer(true);
  };
  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer.id);
    dispatch(
      dispatchSetExerciseStudentAnswers({
        questionId: questions[currentQuestion].id,
        programId,
        exerciseId,
        answer,
      })
    );

    dispatch(
      dispatchSetExerciseResult({
        questionId: questions[currentQuestion].id,
        programId,
        exerciseId,
        answer,
      })
    );
  };

  const handleHideChoice = (answer) => {
    dispatch(
      dispatchSetExerciseHiddenChoices({
        questionId: questions[currentQuestion].id,
        programId,
        exerciseId,
        answerId: answer.id,
      })
    );
  };
  const showResults = async () => {
    setExamResultOpen(true);
    if (exercise.is_done) return;
    try {
      const response = await StatisticsApi.addNewAchievement({
        entity_type: "App\\Models\\Exercise",
        entity_id: exercise.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuestionReport = () => {
    setQuestionReportOpen(true);
  };
  const nextQuestion = () => {
    if (questions.length === currentQuestion + 1) return;
    setShowAnswers(false);
    setShowQuestionAnswer(false);

    window.scrollTo(0, 0);

    setCurrentQuestion((prev) => prev + 1);
  };

  const prevQuestion = () => {
    if (currentQuestion === 0) return;
    setShowAnswers(false);
    setShowQuestionAnswer(false);

    window.scrollTo(0, 0);

    setCurrentQuestion((prev) => prev - 1);
  };
  return (
    questions.length > 0 && (
      <QuestionsContainer>
        <QuestionTitleContainer>
          <QuestionInfo>
            <QuestionTitle>
              السؤال {numbersMapping[currentQuestion + 1]}
            </QuestionTitle>
            <QuestionNumber>
              ({questions.length}/ {currentQuestion + 1})
            </QuestionNumber>
          </QuestionInfo>
          <ReportContainer>
            <button onClick={() => handleQuestionReport()}>
              <img src={FlagIcon} alt='' />
            </button>{" "}
            <p>تبليغ</p>
          </ReportContainer>
        </QuestionTitleContainer>
        <QuestionText>{questions[currentQuestion].text}</QuestionText>
        {questions[currentQuestion].media_type && (
          <QuestionMediaContainer>
            {questions[currentQuestion]?.media_type === "image" ? (
              <img
                src={
                  process.env.REACT_APP_RESOURCES_URL +
                  questions[currentQuestion].media_content
                }
                alt=''
              />
            ) : questions[currentQuestion]?.media_type === "audio" ? (
              <ReactAudioPlayer
                className='audio-player'
                src={
                  process.env.REACT_APP_RESOURCES_URL +
                  questions[currentQuestion].media_content
                }
                controls
              />
            ) : questions[currentQuestion]?.media_type === "video" ? (
              <div className='video-player-wrapper'>
                <ReactPlayer
                  className='react-player'
                  url={questions[currentQuestion].media_content}
                  width='100%'
                  height='100%'
                />
              </div>
            ) : null}
          </QuestionMediaContainer>
        )}

        <ChoicesContainer>
          {React.Children.toArray(
            questions[currentQuestion].answers.map((answer) => (
              <ChoiceItemContainer
                hide={
                  hiddenChoices.filter(
                    (student) =>
                      student.programId == programId &&
                      student.exerciseId == exerciseId &&
                      student.questionId == questions[currentQuestion].id &&
                      student.answerId == answer.id
                  )[0]?.hidden
                }
              >
                <label htmlFor='test1'>
                  <Input
                    type='radio'
                    id='test1'
                    name='radio-group'
                    disabled={
                      hiddenChoices.filter(
                        (student) =>
                          student.programId == programId &&
                          student.exerciseId == exerciseId &&
                          student.questionId == questions[currentQuestion].id &&
                          student.answerId == answer.id
                      )[0]?.hidden
                    }
                    checked={studentAnswers.some(
                      (student) =>
                        student.programId == programId &&
                        student.exerciseId == exerciseId &&
                        student.questionId == questions[currentQuestion].id &&
                        student.answer.id == answer.id &&
                        student.answer.checked
                    )}
                    onChange={() => handleAnswerSelect(answer)}
                  />
                </label>{" "}
                {answer.question.answer_choices_type === "image" ? (
                  <ChoiceImage
                    src={process.env.REACT_APP_RESOURCES_URL + answer.content}
                    alt='answer image'
                  />
                ) : answer.question.answer_choices_type === "audio" ? (
                  <AudioChoice>
                    <ReactAudioPlayer
                      className='audio-player'
                      src={process.env.REACT_APP_RESOURCES_URL + answer.content}
                      controls
                    />
                  </AudioChoice>
                ) : (
                  <ChoiceText>{answer.content}</ChoiceText>
                )}
                <HideChoiceButton onClick={() => handleHideChoice(answer)}>
                  <img src={PasswordIcon} alt='choice hide' />
                </HideChoiceButton>
                {showAnswers &&
                studentAnswers.filter(
                  (student) =>
                    student.programId === programId &&
                    student.exerciseId === exerciseId &&
                    student.questionId === questions[currentQuestion].id &&
                    student.answer.id === answer.id
                )[0]?.answer?.is_correct === 0 ? (
                  <CorrectIcon>X</CorrectIcon>
                ) : null}
                {showAnswers && answer.is_correct ? (
                  <CorrectIcon correct>&#10003;</CorrectIcon>
                ) : null}
              </ChoiceItemContainer>
            ))
          )}
        </ChoicesContainer>
        <QuestionsFooterContainer>
          <ShowAnswer onClick={() => handleShowAnswerClick()}>
            إرسال الإجابة
          </ShowAnswer>
          {showQuestionAnswer && (
            <SolveButton onClick={() => handleSolveButtonClick()}>
              طريقة الحل
            </SolveButton>
          )}
          <ProgressBar value={currentQuestion} max={questions.length - 1} />
          <QuestionNavigationContainer>
            <PrevQuestionButton onClick={prevQuestion}>
              السابق
            </PrevQuestionButton>
            <NextQuestionButton
              onClick={() =>
                questions.length === currentQuestion + 1
                  ? showResults()
                  : nextQuestion()
              }
            >
              {questions.length === currentQuestion + 1
                ? "إنهاء التدريب"
                : "التالي"}
            </NextQuestionButton>
          </QuestionNavigationContainer>
        </QuestionsFooterContainer>
        <SolutionModal
          open={solutionModalOpen}
          setOpen={setSolutionModalOpen}
          type=''
          question={questions[currentQuestion]}
          studentAnswer={
            studentAnswers.filter(
              (student) =>
                student.programId == programId &&
                student.exerciseId == exerciseId &&
                student.questionId == questions[currentQuestion].id &&
                student.answer.checked
            )[0]?.answer || null
          }
        />
        <ExamResultModal
          open={examResultOpen}
          setOpen={setExamResultOpen}
          mode='exercises'
          question={questions[currentQuestion]}
          results={
            exercisesResult.filter(
              (exercise) => exercise.exerciseId === exerciseId
            )[0]?.questions
          }
        />
        <QuestionReportModal
          open={questionReportOpen}
          setOpen={setQuestionReportOpen}
          mode='exercises'
          questionId={questions[currentQuestion].id}
          resourceId={exerciseId}
        />
      </QuestionsContainer>
    )
  );
};

export default TrainingQuestions;
