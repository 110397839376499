import styled from "styled-components/macro";
import {Link} from 'react-router-dom'
import { media } from "styles/utils";
export const ToastWrapper = styled.div`
  width: 536px;

  padding: 16px 17px 16px 20px;
  border-radius: 15px;
  box-shadow: 0 22px 66px 0 rgba(208, 208, 208, 0.31);
  background-color: #fff;
  display: flex;
  ${media("small")} {
    width: 350px !important;
  }
`;

export const TextContainer = styled.div``;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 75px;
  margin: 0 0 0 24px;
  padding: 29px 31px 28px;
  border-radius: 15px;
  background-color: ${(props) => (props.success ? "#25ae88" : " #db2828")};

  img {
    width: 30px;
    height: 30px;
  }
`;
export const ToastTitle = styled.h1`
  font-family: RB;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
  color: rgba(var(--black-dark), 0.87);
`;
export const ToastMessage = styled.p`
  margin-top: 4px;
  font-family: RB;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: right;
  color: rgba(var(--black-dark), 0.8);
`;


export const ProfileLink = styled(Link)`
  
  
  border-radius: 10px;
  color: #CA57A0;
  font-size: 1.8rem;
  font-weight: bold;
  border: none;
  text-decoration:none;
  
`
export const Block = styled.div`
  margin-block:10px;
`