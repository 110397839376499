import styled from "styled-components/macro";
import { media } from "styles/utils";

export const LessonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  direction: rtl;
  ${media("large")} {
    margin-top: 19px;
  }
`;

export const CourseTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CourseTime = styled.span`
  font-size: 1.4rem;
  line-height: 3;
  color: rgb(var(--black-dark));
`;
export const CourseTimeTitle = styled.h1`
  font-size: 1.4rem;
  line-height: 3;
  color: rgb(var(--black-dark));
  font-weight: bold;
  text-align: right;
  ${media("large")} {
    visibility: hidden;
  }
`;

export const SectionContainer = styled.div`
  margin-top: 16px;

  .accordion {
    border: unset;
  }
  .accordion__item:not(:first-child) {
    margin-top: 16px;
  }
  .accordion__item + .accordion__item {
    border-top: 0;
  }
  .accordion__heading {
    width: 100%;
    height: 53px;
    padding: 14px 10px 13px;
    border-radius: 15px;
    box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
    background-color: #fff;
    display: flex;
    .accordion__button {
      padding: 0;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      &:focus-visible {
        outline: none;
      }
      &:before {
        border-bottom: 2px solid rgb(var(--grey-light));
        border-right: 2px solid rgb(var(--grey-light));
        left: 0;
        position: absolute;
      }
    }
  }
  .accordion__panel {
    padding: 0 !important;
  }
  .accordion__button:before {
    transform: rotate(135deg);
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(45deg);
  }
`;
export const SectionHeader = styled.div`
  width: 100%;
  padding: 14px 24px 13px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SectionTitle = styled.h1`
  font-size: 1.4rem;
  margin-right: 8px;
  line-height: 3;
  color: rgb(var(--black-dark));
  text-align: right;
  margin-left: auto;
`;
export const SectionTime = styled.span`
  font-size: 1.4rem;
  line-height: 3;
  color: rgb(var(--grey-light));
  margin-right: 10px;
`;

export const SubCategoryContainer = styled.div`
  background: #fff;
  padding: 16px;
  box-shadow: 0 22px 66px 0 rgba(203, 194, 213, 0.31);
  border-radius: 15px;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;
export const SubCategoryTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const SubCategoryTitle = styled.h1`
  font-size: 1.4rem;
  line-height: 3;
  color: rgb(var(--black-dark));
`;

export const SingleLessonContainer = styled.div`
  margin: 10px 0;
  padding: 10px 10px 10px 24px;
  border-radius: 15px;
  /* background-color: rgba(var(--secondary), 0.03); */
  background-color: ${({ active }) =>
    active ? "rgba(var(--secondary), 0.09);" : "rgba(var(--secondary), 0.03);"};
  display: flex;
  align-items: center;
  justify-content: space-between
  width: 100%;
  cursor: pointer;
`;
export const SingleLessonTitle = styled.h1`
  font-size: 1.4rem;
  line-height: 1.7;
  color: rgb(var(--black-dark));
  margin-left: auto;
  margin-right: 15px;
  max-width: 50%;
  text-align: right;
`;
export const SingleLessonIcon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: rgb(var(--secondary), 0.1);
  background-color: ${(props) =>
    props.isDone
      ? "rgb(var(--green), 0.1)"
      : !props.isDone && !props.isExercise
      ? "rgb(var(--secondary), 0.1)"
      : ""};

  display: flex;
  align-items: center;
  justify-content: center;
  img {
    filter: ${(props) =>
      props.isDone && props.isExercise ? "hue-rotate(160deg);" : "none"};
  }
`;
export const SingleLessonStatus = styled.div`
  font-size: 1.4rem;
  color: rgb(var(--secondary));
  margin-right: 10px;
`;
export const SingleLessonTime = styled.span`
  font-size: 1.4rem;
  line-height: 3;
  color: rgb(var(--grey-light));
  direction: rtl;
`;
export const LessonCheckbox = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;
export const ModalSubTitle = styled.h1`
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.69;

  text-align: right;
  color: rgba(35, 31, 32, 0.87);
}`;

export const ModalTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.69;

  text-align: center;
  margin:auto;
  color:#4d2b76;
}`;
export const ModalText = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.69;
  text-align: center;
  margin:auto;
  
  color:#000;
  
}`;
export const ModalBodyContainer = styled.div`
  max-width: 350px;
  max-height: 85vh;
  width: 100%;
  padding: 32px 27px 56px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  direction: rtl;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
export const ImageModal = styled.img`
  width: 75px;
  height: 75px;
  margin: 2rem auto;
`;
export const ModalButton = styled.button`
  background: #ca57a0;
  color: #fff;
  outline: none;
  border: none;
  padding: 10px 15px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
`;
