import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ProgressBar = styled.progress`
  flex: 1;
  max-width: 667px;
  &[value] {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    border-radius: 7px;
    -webkit-animation: animate-stripes 5s linear infinite;
    animation: animate-stripes 5s linear infinite;
  }

  &[value]::-webkit-progress-bar {
    background-color: #f1f2f2;
    border-radius: 7px;
  }

  &[value]::-webkit-progress-value {
    background-color: rgb(var(--secondary));
    border-radius: 7px;
  }
  ${media("large")} {
    flex: unset;
    margin-top: 20px;
    width: 100%;
  }
`;

// exam question body
export const ExamQuestionWrapper = styled.div`
  background-image: linear-gradient(154deg, #f6faff 11%, #f9fcff 35%, #fff 77%);
  width: 100%;
  padding: 32px 0 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media("large")} {
    padding: 32px 12px 260px;
  }
`;

export const QuestionHeaderContainer = styled.div`
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
`;

export const QuestionTextInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const QuestionSettings = styled.div`
  display: flex;
`;
export const QuestionTitleContainer = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: #a7a9ac;
`;
export const QuestionTitle = styled.span``;
export const QuestionNumber = styled.span`
  margin-right: 12px;
`;

export const BookMarkContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border: 2px solid rgb(var(--secondary));; // Adjust border color and thickness as needed
  border-radius: 50%; // Makes the border a circle
  padding: 10px; // Adjust padding as needed to create space between the content and the border
  p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #a7a9ac;
    margin-right: 7px;
  }
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;
export const ReportContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 38px;
  p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #a7a9ac;
    margin-right: 7px;
  }
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const QuestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  flex-direction: column;
  align-items: right;
  img {
    margin-top: 37px;
    border-radius: 15px;
    max-width: 100%;
    align-self: right;
  }
  .react-audio-player {
    margin-top: 37px;

    min-width: 500px;
    height: 136px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 37px 18px 0px 28px;
    border-radius: 15px;
    box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
    background-color: #fff;
    ${media("large")} {
      min-width: 400px;
    }
    audio {
      /*border-radius: 90px;*/
      color: red;
      background: red;
    }

    &::-webkit-media-controls-enclosure {
      border-radius: 5px;
      background-color: transparent;
    }
    /* &::-webkit-media-controls-mute-button {
      display: none !important;
    }

    &::-webkit-media-controls-volume-slider {
      display: none !important;
    }

    &::-webkit-media-controls-volume-control-container.closed {
      display: none !important;
    }
    &::-webkit-media-controls-volume-control-container {
      display: none !important;
    } */
  }

  .video-player-wrapper {
    margin-top: 37px;
    /* align-self: center; */
    position: relative;
    width: 100%;
    /* position: relative; */
    padding-top: 56.25%;
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const QuestionHeader=styled.div`
  margin-top: 12px;
  color: rgb(var(--secondary));
  font-size: 2.4rem;
  font-weight: bold;
  width:100%;
`
export const QuestionText = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  text-align: right;
  margin-top: 12px;
  line-height: 2; // assuming this is equal to one line of text
  height: 20rem; // 2 (line-height) * 3 (lines) = 6rem
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: rgb(var(--secondary));
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ExplanationTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  flex-direction: column;
  align-items: right;
`;

export const ExplanationText = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-align: right;
  line-height: 2; // assuming this is equal to one line of text
  height: 25rem; 
  overflow: auto;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  color: rgb(var(--secondary));
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // changed from center to flex-start
  margin-top:10px;
`;
export const ChoicesContainer = styled.div`
  margin-top: 0px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ChoiceItemContainer = styled.div`
  padding: 15px 24px 16px 23px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 21px;
  }
`;
export const ChoiceText = styled.p`
  font-size: 1.6rem;
  letter-spacing: normal;
  text-align: right;
  color: rgb(var(--black-dark));
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; // add this line
  line-height: 1.5; // assuming this is equal to one line of text
  height: 5rem; // 1.5 (line-height) * 2 (lines) = 3rem
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CorrectIcon = styled.div`
  min-width: 22px;
  height: 22px;
  background-color: #21ba45;
  background-color: ${(props) => (props.correct ? "#21ba45" : " #db2828")};

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin-right: auto;
`;