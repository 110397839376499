/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Chapters } from "services/api/chapters";

const INITIAL_STATE = {
  chapters: [],
  loading: false,
  errors: "",
};

const chaptersSlice = createSlice({
  name: "chapters",
  initialState: INITIAL_STATE,
  reducers: {
    chaptersRequest(state) {
      state.loading = true;
    },

    chaptersSuccess(state, action) {
      state.chapters = action.payload;
      state.loading = false;
    },
    chaptersFailure(state, action) {
      state.errors = action.payload;
      state.chapters = [];
      state.loading = false;
    },
  },
});

// export actions
export const { chaptersRequest, chaptersSuccess, chaptersFailure } =
  chaptersSlice.actions;

// export reducer
export const { reducer: chaptersReducer } = chaptersSlice;

const dispatchFetchChapters = (subCategoryId) => async (dispatch) => {
  try {
    dispatch(chaptersRequest());

    const response = await Chapters.getChapters(subCategoryId);

    if (response.data.metaData.key === "success") {
      dispatch(chaptersSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(chaptersFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(chaptersFailure(error.toString()));
  }
};

export { dispatchFetchChapters };
