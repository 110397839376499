/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Categories } from "services/api/categories";

const INITIAL_STATE = {
  list: [],
  loading: false,
  errors: "",
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState: INITIAL_STATE,
  reducers: {
    categoriesRequest(state) {
      state.loading = true;
    },

    categoriesSuccess(state, action) {
      state.list = action.payload;
      state.loading = false;
    },
    categoriesFailure(state, action) {
      state.errors = action.payload;
      state.list = [];
      state.loading = false;
    },
  },
});

// export actions
export const { categoriesRequest, categoriesSuccess, categoriesFailure } =
  categoriesSlice.actions;

// export reducer
export const { reducer: categoriesReducer } = categoriesSlice;

const dispatchFetchCategories = (groupId) => async (dispatch) => {
  try {
    dispatch(categoriesRequest());

    const response = await Categories.getCategories(groupId);

    if (response.data.metaData.key === "success") {
      dispatch(categoriesSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(categoriesFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(categoriesFailure(error.toString()));
  }
};

export { dispatchFetchCategories };
