import { TEXTS } from "data/textStrings";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import {
  HeaderContainer,
  HeaderTabsContainer,
  HeaderTitleContainer,
  HeaderTitle,
  TabLink,
} from "./ProgramHeader.styles";
import ProgramRating from "components/ProgramRating/ProgramRating";

const ProgramHeader = ({ page, showProgramReview }) => {
  const selectedProgram = useSelector(
    (state) => state.programs.selectedProgram
  );
  const { categoryId, programId } = useParams();
  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        <HeaderTitle>{selectedProgram.name}</HeaderTitle>
        {showProgramReview && <ProgramRating />}
      </HeaderTitleContainer>
      <HeaderTabsContainer>
        <TabLink
          to={`/categories/${categoryId}/programs/${programId}/exams`}
          active={page === "exam"}
        >
          {TEXTS.ACTUAL_TESTS}
        </TabLink>
        <TabLink
          active={page === "lessons"}
          to={`/categories/${categoryId}/programs/${programId}/lessons`}
        >
          {TEXTS.PROGRAM_LESSONS}
        </TabLink>
        <TabLink
          to={`/categories/${categoryId}/programs/${programId}/statistics`}
          active={page === "statistics"}
        >
          {TEXTS.STATISTICS}
        </TabLink>
      </HeaderTabsContainer>
    </HeaderContainer>
  );
};

export default ProgramHeader;
