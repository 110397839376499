import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

const centerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const HeaderContainer = styled.header`
  height: 93px;
  padding: 22px 140px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${media("large")} {
    padding: 22px 12px 25px;
    justify-content: space-between;
  }
  ${media("medium")} {
    justify-content: center;
  }
`;

export const NavContainer = styled.div({
  ...centerStyles,
});

export const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  gap: 35px;
  font-size: 1.6rem;
  margin-right: 56px;

  ${media("medium")} {
    display: none;
  }
`;

export const NavItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: baseline;
  position: relative;
  gap: 8px;
  ${media("medium")} {
    display:${props => props.active && 'grid'};
    margin-top: 22px;
    /* &:nth-child(2) {
      margin-top: 50%;
    } */
  }
  img {
    cursor: pointer;
    ${media("medium")} {
      justify-content: space-between;
    }
    transition: all 0.4s ease-out;

    margin-right: 8px;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "none")};
  }
  button {
    cursor: pointer;

    background: transparent;
    border: none;
  }
`;

export const MenuItem = styled(Link)`
  color: ${(props) =>
    props.active ? "rgb(var(--black-dark))" : "rgba(var(--black-dark),0.8)"};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  line-height: 1.5;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
  &::after {
    content: "";
  }
  &:hover {
    color: rgb(var(--black-dark));
  }
`;

export const LinksContainer = styled.div`
  display: ${(props) => (props.open ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  ${media("medium")} {
    display: ${(props) => (props.open ? "flex" : "none")};
    flex-direction: column;
    margin-top: 82px;
  }
  button {
    cursor: pointer;

    background: transparent;
    border: none;
  }
`;

export const FeatureLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 166px;
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  margin:20px;
  background-color: rgba(var(--secondary));
  color: #fff;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;

export const FeatureLinka = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 136px;
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  margin:20px;
  gap: 8px;
  background-color: rgba(var(--secondary));
  color: #fff;
  transition: background-color 0.3s;
  font-size: 16px; /* Example: Set font size to 16 pixels */
  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;


export const RegisterLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  width: 116px;
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  color: #fff;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;
export const LoginLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: rgb(var(--black-dark));
  line-height: 1.79;
  margin-left: 44px;
  transition: color 0.3s;

  &:hover {
    color: rgba(var(--black-dark), 0.8);
  }
  ${media("medium")} {
    margin-left: 0;
    margin-top: 12px;
  }
`;

export const LogoutButton = styled.button`
  margin: 0 20px;
  z-index: 32;
  text-decoration: none;
  font-size: 1.4rem;
  color: rgba(var(--black-dark), 0.8);
  font-weight: normal;
  line-height: 1.5;
  cursor: pointer;
  direction: rtl;
  padding-right: 0;
  display: ${(props) => (props.open ? "block" : "none")};

  &:hover {
    color: rgb(var(--black-dark));
  }
`;

export const MobileLogoutButton = styled.button`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: rgb(var(--black-dark));
  line-height: 1.79;
  transition: color 0.3s;
  margin-top:22px;
`;

export const LogoContainer = styled.div`
  width: 63px;
  height: 32px;

  img.logo {
    max-width: 100%;
  }
`;

export const MobileMenuIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div {
    width: 20px;
    height: 4px;
    margin-top: 3px;
    background: red;
  }

  &:active {
    & > div {
      background: yellow !important;
    }
    & div:first-child {
      transform: rotate(45deg);
    }
  }
`;

export const DropdownContainer = styled.div`
  height: ${(props) => (props.open ? "auto" : "0")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  box-shadow: 10px 22px 30px 3px rgba(203, 194, 213, 0.4);
  width: ${(props) => (props.mobile ? "130px" : "200px")};

  top: 35px;
  right: 0;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  transition: all 0.4s ease-out;

  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;

  ${media('medium')}{


    position:${(props) => props.active && ' relative'};
    padding:0;
    box-shadow:none;
    top:0px;
    padding-inline-start:20px;
    width:fit-content;
  }
`;

export const DropdownItem = styled(Link)`
  margin-top: 10px;
  z-index: 32;
  text-decoration: none;
  font-size: 1.4rem;
  color: ${(props) =>
    props.active ? "rgb(var(--black-dark))" : "rgba(var(--black-dark),0.8)"};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  line-height: 1.5;
  cursor: pointer;
  padding: 0 20px;
  direction: rtl;
  display: ${(props) => (props.open ? "block" : "none")};

  &:hover {
    color: rgb(var(--black-dark));
  }

  span {
    color: rgb(var(--secondary));
    font-size: 1.3rem;
    direction: rtl;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  img.arrow-icon {
    cursor: pointer;

    transition: all 0.4s ease-out;

    margin-right: 8px;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "none")};
    ${media("medium")} {
      justify-content: space-between;
    }
  }
`;
export const ProfileDropDown = styled.div`
  position: absolute;
  height: ${(props) => (props.open ? "auto" : "0")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  box-shadow: 10px 22px 30px 3px rgba(203, 194, 213, 0.4);
  width: ${(props) => (props.mobile ? "130px" : "200px")};
  padding: ${(props) => (props.open ? "20px 0" : "0")};
  /* display: ${(props) => (props.open ? "flex" : "none")}; */

  top: 50px;
  right: 0;
  background: #fff;
  border-radius: 10px;
  transition: all 0.4s ease-out;

  /* padding: 20px 0; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
  gap: 10px;

  hr {
    width: 100%;
    height: 1px;
    background-color: #f4f4f4;
    border: none;
  }
`;
export const UserName = styled.h1`
  font-size: 1.4rem;
  text-align: center;
  color: rgb(var(--black-dark));
  margin-right: 8px;
  cursor: pointer;
`;
export const UserImage = styled.img`
  width: 43px;
  height: 43px;
  cursor: pointer;
`;

export const LogoLink = styled(Link)``;
