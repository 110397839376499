import { get, post } from "config/api";

export const StatisticsApi = {
  getUserRank: (programId) => get(`programs/${programId}/users-degrees`),
  getWeeklyAchievements: () => get(`users/me/weekly-achievements`),
  getStudentStats: (programId) => get(`users/me/programs/${programId}/stats`),
  addNewAchievement: (data) => post(`/users/me/achievements`, data),
  updateUserSession: (user_id, program_id) =>
    get("/users/update-user-program-session", {
      params: {
        user_id,
        program_id,
      },
    }),
};
