import Layout from "components/layout/Layout";
import LessonReviewModal from "components/Modals/LessonReviewModal/LessonReviewModal";
import ProgramContentAccordion from "components/ProgramContentAccordion/ProgramContentAccordion";
import LessonContent from "components/ProgramLessons/LessonContent";
import {
  LessonsBodyContainer,
  LessonsWidget,
  LessonsWrapper,
  LessonViewer,
} from "components/ProgramLessons/ProgramLessons.styles";
import ProgramHeader from "components/Training/ProgramHeader/ProgramHeader";
import TrainingQuestions from "components/TrainingQuestions/TrainingQuestions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoading } from "react-redux-loading-bar";
import { useHistory, useParams } from "react-router";
import { StatisticsApi } from "services/api/statistics";
import { dispatchFetchExerciseQuestions } from "store/slices/exercises";
import { dispatchFetchProgram } from "store/slices/programs";
import { dispatchFetchSubCategories } from "store/slices/subCategories";
const ProgramLessonsContainer = () => {
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [showProgramReview, setShowProgramReview] = useState(false);
  const selectedProgram = useSelector(
    (state) => state.programs.selectedProgram
  );
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [currentLessonId, setCurrentLessonId] = useState(null);
  const lessons = useSelector((state) => state.subCategories.lessons);

  const questions = useSelector((state) => state.exercises.questions);
  let dispatch = useDispatch();
  let history = useHistory();
  const { categoryId, programId } = useParams();

  useEffect(() => {
    dispatch(
      dispatchFetchProgram(programId, (isSubscribed) => {
        if (!isSubscribed && !user.is_trail) {
          history.push(`/categories/${categoryId}/programs/${programId}`);
        }
      })
    );
    const time = setInterval(() => {
      if (!document.hidden) StatisticsApi.updateUserSession(user.id, programId);
    }, 120000);

    return () => clearInterval(time);
  }, []);

  useEffect(() => {
    if (selectedExercise) {
      dispatch(dispatchFetchExerciseQuestions(selectedExercise.id));
    }
  }, [selectedExercise]);

  const handleNextLesson = async () => {
    if (!selectedLesson.is_done) {
      try {
        const response = await StatisticsApi.addNewAchievement({
          entity_type: "App\\Models\\Lesson",
          entity_id: selectedLesson.id,
        });
        if (response.data.metaData.key === "success") {
          setOpen(true);
          setCurrentLessonId(selectedLesson.id);
          dispatch(dispatchFetchSubCategories(programId));
        }
      } catch (error) {
        console.log(error);
      }
    }

    let currentLessonIndex = lessons.findIndex(
      (lesson) => lesson.id === selectedLesson.id
    );
    if (currentLessonIndex === -1) return;
    if (currentLessonIndex >= lessons.length - 1) return;
    setSelectedLesson({ ...lessons[currentLessonIndex + 1] });
    dispatch(showLoading());
    setContentType("lesson");
    if (lessons[currentLessonIndex + 1].is_latest) {
      setShowProgramReview(true);
    }
  };
  return (
    <Layout>
      <LessonsWrapper>
        <ProgramHeader page="lessons" showProgramReview={showProgramReview} />
        <LessonsBodyContainer>
          <LessonsWidget>
            <ProgramContentAccordion
              setContentType={setContentType}
              setSelectedExercise={setSelectedExercise}
              setSelectedLesson={setSelectedLesson}
              setShowProgramReview={setShowProgramReview}
              selectedLesson={selectedLesson}
              selectedExercise={selectedExercise}
              contentType={contentType}
            />
          </LessonsWidget>
          <LessonViewer>
            {selectedLesson && contentType === "lesson" && (
              <LessonContent
                selectedLesson={selectedLesson}
                selectedProgram={selectedProgram}
                handleNextLesson={handleNextLesson}
              />
            )}
            {selectedExercise &&
              contentType === "exercise" &&
              questions.length > 0 && (
                <TrainingQuestions
                  exerciseId={selectedExercise.id}
                  exercise={selectedExercise}
                  questions={questions}
                />
              )}
          </LessonViewer>
        </LessonsBodyContainer>
      </LessonsWrapper>
      <LessonReviewModal
        open={open}
        setOpen={setOpen}
        lessonId={currentLessonId}
      />
    </Layout>
  );
};

export default ProgramLessonsContainer;
