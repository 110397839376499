import axios from "axios";
import store from "../../store";

const newApiClient = axios.create({
  baseURL: process.env.REACT_APP_NEW_BACKEND_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  },
  timeout: process.env.REACT_APP_REQUESTS_TIMEOUT,
});

newApiClient.interceptors.request.use(
  (config) => {
    if (
      store.getState().auth.token &&
      (config.url !== "/auth/login" || config.url !== "/auth/register")
    ) {
      config.headers["Authorization"] = "Bearer " + store.getState().auth.token;
    }

    // Log the request URL
    // console.log(`Request URL: ${config.baseURL}${config.url}`);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const { get: newGet, post: newPost, put: newPut, delete: newDelete, patch: newPatch } = newApiClient;

export { newGet, newPost, newPut, newDelete, newPatch };
export default newApiClient;