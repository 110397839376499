import styled from "styled-components/macro";

import { media } from "styles/utils";

export const LessonsWrapper = styled.div`
  width: 100%;
  padding: 30px 140px 150px;
  ${media("large")} {
    padding: 30px 12px 0;
  }
  background-image: linear-gradient(154deg, #f6faff 11%, #f9fcff 35%, #fff 77%);
`;

export const LessonsBodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 40px;
  ${media("large")} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const LessonsWidget = styled.div`
  grid-area: 1 / 5 / 2 / 7;
  ${media("large")} {
    width: 100%;
    order: 2;
    margin-top: 40px;
  }
`;
export const LessonViewer = styled.div`
  grid-area: 1 / 1 / 2 / 5;
  margin-right: 24px;
  .lesson_iframe {
    height: 68vh;
    ${media("medium")} {
      height: 350px;
    }
    ${media("small")} {
      height: 250px;
    }
  }
  ${media("large")} {
    margin-right: unset;
    margin-top: 40px;
    width: 100%;
  }
  ${media("medium")} {
    width: 100%;
  }
`;
