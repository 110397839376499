import React from "react";
import Iframe from "react-iframe";
import { TEXTS } from "data/textStrings";

import {
  LessonContentContainer,
  MediaContainer,
  VideoWrapper,
  LessonInfoContainer,
  LessonInfoTabs,
  LessonInfoTab,
  LessonDescription,
  NextLessonButton,
} from "./LessonContent.styles";
import { useState } from "react";
import LessonComments from "components/LessonComments/LessonComments";
import LessonLoader from "components/Loaders/LessonLoader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";

const LessonContent = ({ selectedLesson, handleNextLesson }) => {
  const [activeTab, setActiveTab] = useState("overview");
  const [loading, setLoading] = useState(true);

  const selectedProgram = useSelector(
    (state) => state.programs.selectedProgram
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <LessonContentContainer key={selectedLesson.id}>
      <MediaContainer>
        <VideoWrapper>
          {/* {loading ? (
            <LessonLoader />
          ) : ( */}
          <Iframe
            url={selectedLesson.video_url}
            width="450px"
            id={selectedLesson.id}
            className="lesson_iframe"
            display="initial"
            allowFullScreen
            position="relative"
            loading="loading"
            onLoad={() => {
              dispatch(hideLoading());
            }}
          />
        </VideoWrapper>
      </MediaContainer>
      <LessonInfoContainer>
        <LessonInfoTabs>
          <LessonInfoTab
            active={activeTab === "overview"}
            onClick={() => setActiveTab("overview")}
          >
            {TEXTS.LESSON_OVERVIEW}
          </LessonInfoTab>
          <LessonInfoTab
            active={activeTab === "comments"}
            onClick={() => setActiveTab("comments")}
          >
            {TEXTS.LESSON_COMMENTS}
          </LessonInfoTab>
          {selectedProgram.is_user_subscribed ? (
            <NextLessonButton onClick={() => handleNextLesson()}>
              الدرس التالي
            </NextLessonButton>
          ) : null}
        </LessonInfoTabs>
        {activeTab === "overview" && (
          <LessonDescription>{selectedLesson.description}</LessonDescription>
        )}
        {activeTab === "comments" && (
          <LessonComments lessonId={selectedLesson.id} />
        )}
      </LessonInfoContainer>
    </LessonContentContainer>
  );
};

export default LessonContent;
