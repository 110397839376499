/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { UserApi } from "services/api/user";
import Toast from "../../../components/Toast/Toast";
import { Auth } from "../../../services/api/auth";

const INITIAL_STATE = {
  isAuthenticated: false,
  loading: false,
  token: undefined,
  user: {},
  errors: "",
  phone: "",
  password: "",
  referralCode: "",
  resetPasswordPhone: "",
  resetPasswordCode: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    loginRequest(state) {
      state.loading = true;
    },

    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = action.payload.token;
      state.loading = false;
      state.password = "";
    },
    loginFailure(state, action) {
      state.errors = action.payload;
      state.isAuthenticated = false;
      state.user = {};
      state.token = undefined;
      state.loading = false;
      state.password = "";
    },
    socialLoginRequest(state) {
      state.loading = true;
    },

    socialLoginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.resource;
      state.token = action.payload.resource.token;
      state.loading = false;
      state.password = "";
    },
    socialLoginFailure(state, action) {
      state.errors = action.payload;
      state.isAuthenticated = false;
      state.user = {};
      state.token = undefined;
      state.loading = false;
      state.password = "";
    },
    registerRequest(state) {
      state.loading = true;
    },

    registerSuccess(state, action) {
      state.isAuthenticated = false;
      state.loading = false;
      state.phone = action.payload.resource.phone;
      state.password = action.payload.data.password;
      state.errors = "";
    },
    registerFailure(state, action) {
      state.isAuthenticated = false;
      state.errors = action.payload;
      state.user = {};
      state.token = undefined;
      state.loading = false;
    },
    verifyRequest(state) {
      state.loading = true;
    },
    verifyRequire(state, action) {
      state.phone = action.payload.phone;
      state.password = action.payload.password;
    },
    verifySuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = action.payload.token;
      state.password = "";
      state.errors = "";
      state.loading = false;
    },
    verifyFailure(state, action) {
      state.isAuthenticated = false;
      state.errors = action.payload;
      state.user = {};
      state.token = undefined;
      state.loading = false;
    },
    updateProfileRequest(state) {
      state.loading = true;
    },
    updateProfileSuccess(state, action) {
      state.loading = false;
      state.user = { ...state.user, ...action.payload };
    },
    updateProfileError(state, action) {
      state.errors = action.payload;
    },

    getProfileRequest(state) {
      state.loading = true;
    },
    getProfileSuccess(state, action) {
      state.loading = false;
      state.user = { ...state.user, ...action.payload };
    },
    getProfileError(state, action) {
      state.errors = action.payload;
    },
    registerEnd(state, action) {
      state.loading = false;
    },
    setReferralCode(state, action) {
      state.referralCode = action.payload;
    },

    setForgetPasswordData(state, action) {
      if (action.payload.phone) {
        state.resetPasswordPhone = action.payload.phone;
      }

      if (action.payload.code) {
        state.resetPasswordCode = action.payload.code;
      }
    },
    resetPasswordData(state, action) {
      state.resetPasswordCode = "";
      state.resetPasswordPhone = "";
    },
    resetLogout(state, action) {
      state.isAuthenticated = false;
      state.errors = "";
      state.user = {};
      state.token = undefined;
      state.loading = false;
    },
  },
});

// export actions
export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  registerEnd,
  verifyRequest,
  verifySuccess,
  verifyFailure,
  verifyRequire,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileError,
  getProfileRequest,
  getProfileSuccess,
  getProfileError,
  setReferralCode,
  socialLoginRequest,
  socialLoginSuccess,
  socialLoginFailure,
  setForgetPasswordData,
  resetPasswordData,
  resetLogout,
} = authSlice.actions;

// export reducer
export const { reducer: authReducer } = authSlice;

const loginDispatch = (data, callback) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    const response = await Auth.login(data);

    if (response.data.metaData.key === "success") {
      dispatch(loginSuccess(response.data.data.resource));
      callback(-1);
    }

    dispatch(registerEnd());
  } catch (error) {
    if (error?.response?.data?.metaData?.key === "not_verified") {
      dispatch(verifyRequire(data));

      toast(
        <Toast
          message={error.response.data.metaData.message}
          title='خطأ'
          error
        />
      );
      callback("/otp", "push");
      return;
    }
    if (error?.response?.data?.metaData?.key === "invalid_credentials") {
      toast(
        <Toast message='كلمة المرور أو رقم الهاتف غير صحيح' title='خطأ' error />
      );
      return;
    }
    toast(
      <Toast
        message={error.response?.data?.metaData?.message || "حدث خطا ما"}
        title='خطأ'
        error
      />
    );
    dispatch(loginFailure(error.response.data.metaData));
  } finally {
    dispatch(registerEnd());
  }
};

const verifyDispatch = (data, callback) => async (dispatch) => {
  try {
    dispatch(verifyRequest());
    const response = await Auth.verify(data);

    if (response.data.metaData.key === "success") {
      dispatch(verifySuccess(response.data.data.resource));
      callback();
    }
    
    dispatch(registerEnd());
  } catch (error) {
    dispatch(verifyFailure(error.response.data.metaData));
    toast(
      <Toast
        message={error.response.data?.metaData?.message}
        title='خطأ'
        error
      />
    );
  } finally {
    dispatch(registerEnd());
  }
};

const registerDispatch = (data, callback) => async (dispatch) => {
  try {
    dispatch(registerRequest());
    const response = await Auth.register(data);

    if (response.data.metaData.key === "success") {
      dispatch(
        registerSuccess({ resource: response.data.data.resource, data })
      );

      callback();
    }
    dispatch(registerEnd());
  } catch (error) {
    console.log(
      "🚀 ~ file: auth.slice.js ~ line 251 ~ registerDispatch ~ error",
      error
    );
    dispatch(registerFailure(error.response.data.metaData));
    toast(
      <Toast
        message={error.response.data?.metaData?.message}
        title='خطأ'
        error
      />
    );
  } finally {
    dispatch(registerEnd());
  }
};

const socialLoginDispatch = (data, callback) => async (dispatch) => {
  try {
    dispatch(socialLoginRequest());
    const response = await Auth.getCallbackUrl(data);

    if (response.data.metaData.key === "success") {
      dispatch(
        socialLoginSuccess({ resource: response.data.data.resource, data })
      );

      callback();
    }
    dispatch(registerEnd());
  } catch (error) {
    console.log(error);
    dispatch(socialLoginFailure(error.response.data.metaData));
    toast(
      <Toast
        message={error.response.data?.metaData?.message}
        title='خطأ'
        error
      />
    );
  } finally {
    dispatch(registerEnd());
  }
};

const updateProfileDispatch = (data, callback) => async (dispatch) => {
  try {
    dispatch(updateProfileRequest());

    const response = await UserApi.updateProfile(data);

    if (response.data.metaData.key === "success") {
      dispatch(updateProfileSuccess(response.data.data.resource));
      callback();
    }
  } catch (error) {
    dispatch(updateProfileError(error.response.data.metaData));
    toast(
      <Toast message={error.response.data.metaData.message} title='خطأ' error />
    );
  } finally {
    dispatch(registerEnd());
  }
};

const resetPasswordDispatch = (data, callback) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    const response = await Auth.resetPassword(data);

    if (response.data.metaData.key === "success") {
      dispatch(loginSuccess(response.data.data.resource));
      dispatch(resetPasswordData());
      callback("/");
    }

    dispatch(registerEnd());
  } catch (error) {
    console.log(error.response);
    if (error.response.data?.metaData?.key === "not_verified") {
      dispatch(verifyRequire(data));

      toast(
        <Toast
          message={error.response.data.metaData.message}
          title='خطأ'
          error
        />
      );
      callback("/otp");
      return;
    }
    if (error.response.data?.metaData?.key === "invalid_credentials") {
      toast(
        <Toast message='كلمة المرور أو رقم الهاتف غير صحيح' title='خطأ' error />
      );
      return;
    }
    toast(
      <Toast
        message={error.response?.data?.metaData?.message || "حدث خطا ما"}
        title='خطأ'
        error
      />
    );
    dispatch(loginFailure(error.response.data.metaData));
  } finally {
    dispatch(registerEnd());
  }
};

const dispatchFetchProfile =
  (callback = null) =>
  async (dispatch) => {
    try {
      dispatch(getProfileRequest());

      const response = await UserApi.getProfile();

      if (response.data.metaData.key === "success") {
        dispatch(getProfileSuccess(response.data.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(getProfileError(error.response.data.metaData));
      toast(
        <Toast
          message={error.response.data.metaData.message}
          title='خطأ'
          error
        />
      );
    }
  };
const dispatchSetReferralCode = (data) => async (dispatch) => {
  dispatch(setReferralCode(data));
};

const dispatchSetForgetPasswordData = (data) => async (dispatch) => {
  dispatch(setForgetPasswordData(data));
};

const dispatchResetPasswordData = () => async (dispatch) => {
  dispatch(resetPasswordData());
};
const dispatchLogout = () => async (dispatch) => {
  dispatch(resetLogout());
};

export {
  loginDispatch,
  registerDispatch,
  verifyDispatch,
  updateProfileDispatch,
  resetPasswordDispatch,
  dispatchFetchProfile,
  dispatchSetReferralCode,
  socialLoginDispatch,
  dispatchLogout,
  dispatchSetForgetPasswordData,
  dispatchResetPasswordData,
};
