import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const HeaderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  direction: rtl;
`;
export const HeaderTitle = styled.h1`
  align-self: flex-start;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: right;
  color: rgb(var(--primary-light));
  ${media("large")} {
    text-align: center;
    width: 100%;
  }
`;

export const HeaderTabsContainer = styled.div`
  min-height: 76.8px;
  margin-top: 16px;
  padding: 9px 12.4px 10px 14px;
  border-radius: 10px;
  border: solid 1px #f1f2f2;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media("large")} {
    padding: 9px 10px 10px;
    width: 100%;
  }

  ${media("medium")} {
    justify-content: space-around;
  }
`;

export const TabLink = styled(Link)`
  height: 57.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  color: rgb(var(--black-dark));
  text-decoration: none;
  padding: 13px 45.4px 12.8px 45px;
  background-color: ${(props) => (props.active ? "#f6faff" : " #ffffff")};
  opacity: ${(props) => (props.active ? 1 : 0.7)};

  ${media("large")} {
    font-size: 1.4rem;
    padding: 13px 10.4px 12.8px;
  }
`;
