import React, { useEffect, useState, useRef } from "react";
import * as S from "./Menu.styles";
import { Link, useLocation } from "react-router-dom";

import DownArrow from "../../assets/img/down.svg";
import { triningPrograms } from "data/menu";
import {
  DropdownContainer,
  DropdownItem,
  LinksContainer,
  LoginLink,
  MenuItem,
  NavItem,
  RegisterLink,
  MobileLogoutButton,
  FeatureLink,
  FeatureLinka,
} from "../Header/header.styles";
import { useSelector } from "react-redux";

function RightNav({ open, handleLogout, groups, setOpen }) {
  const menuDropdownRef = useRef();
  const [showDropDown, setShowDropDown] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  useEffect(() => {
    setShowDropDown(false);
  }, [open]);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <S.Ul open={open}>
      <LinksContainer open={open}>
        {isAuthenticated ? (
          <>
            <NavItem>
              <MenuItem
                to="/profile"
                active={location.pathname.includes("profile")}
              >
                الملف الشخصي
              </MenuItem>
            </NavItem>
            <NavItem>
              <FeatureLinka
                href="https://mo.tmyyoz.com"
                target="_blank"
                active={location.pathname.includes("profile")}
              >
                متميز AI
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.3492 7.31293H0.973633V11.6984H7.3492V7.31293Z"
                    fill="white"
                  />
                  <path
                    d="M18.0255 7.31293H11.6499V11.6984H18.0255V7.31293Z"
                    fill="white"
                  />
                  <path
                    d="M11.6483 0.980469H7.34918L7.3492 7.31293L11.6483 7.3129V0.980469Z"
                    fill="white"
                  />
                  <path
                    d="M11.6483 11.6999H7.34918V18.0323H11.6483V11.6999Z"
                    fill="white"
                  />
                </svg>
              </FeatureLinka>
            </NavItem>
            <NavItem>
              <MenuItem
                to="/my-programs"
                active={location.pathname.includes("my-programs")}
              >
                دوراتي
              </MenuItem>
            </NavItem>
            <NavItem>
              <MenuItem
                to="/subscriptions"
                active={location.pathname.includes("subscriptions")}
              >
                معلومات الاشتراك
              </MenuItem>
            </NavItem>
            <MobileLogoutButton onClick={handleLogout}>
              تسجيل الخروج
            </MobileLogoutButton>
          </>
        ) : (
          <>
            <RegisterLink to="/register">حساب جديد</RegisterLink>

            <LoginLink to="/login">تسجيل دخول</LoginLink>

            <FeatureLinka href="https://mo.tmyyoz.com" target="_blank">
              متميز AI
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.3492 7.31293H0.973633V11.6984H7.3492V7.31293Z"
                  fill="white"
                />
                <path
                  d="M18.0255 7.31293H11.6499V11.6984H18.0255V7.31293Z"
                  fill="white"
                />
                <path
                  d="M11.6483 0.980469H7.34918L7.3492 7.31293L11.6483 7.3129V0.980469Z"
                  fill="white"
                />
                <path
                  d="M11.6483 11.6999H7.34918V18.0323H11.6483V11.6999Z"
                  fill="white"
                />
              </svg>
            </FeatureLinka>
          </>
        )}
      </LinksContainer>
      <NavItem>
        <MenuItem to="/" active={location.pathname === "/"}>
          الرئيسية
        </MenuItem>
      </NavItem>
      <NavItem open={showDropDown} ref={menuDropdownRef} active={showDropDown}>
        <button onClick={toggleDropDown}>
          <>
            <img src={DownArrow} alt="down" />
            <MenuItem active={location.pathname.includes("programs")}>
              البرامج التدريبية
            </MenuItem>
          </>
        </button>

        <DropdownContainer open={showDropDown} active={showDropDown}>
          {triningPrograms.map((item, index) => (
            <DropdownItem
              open={showDropDown}
              to={item.path}
              active={location.pathname === item.path}
              key={index}
              onClick={() => setOpen(false)}
            >
              {item.text}
            </DropdownItem>
          ))}
        </DropdownContainer>
      </NavItem>
      {/* <NavItem open={showDropDown}>
        <button onClick={toggleDropDown}>
          <>
            <img src={DownArrow} alt='down' />
            <MenuItem active={false}>الفئات </MenuItem>
          </>
        </button>
        <DropdownContainer mobile={true} open={showDropDown}>
          {React.Children.toArray(
            groups &&
              groups.map((group) => (
                <DropdownItem
                  open={showDropDown}
                  to={`/groups/${group.id}/programs`}
                  active={false}
                >
                  {group.name}
                </DropdownItem>
              ))
          )}
        </DropdownContainer>
      </NavItem> */}
      {/*  {React.Children.toArray(
        groups &&
        groups.map((group) => (
          <NavItem>
            <MenuItem to={`/groups/${group.id}/programs`} active={false}>
              {group.name}
            </MenuItem>
          </NavItem>
        ))
      )} */}
      {/* {isAuthenticated ? (
        <>
          <NavItem>
            <MenuItem to='/profile' active={false}>
              الملف الشخصي
            </MenuItem>
          </NavItem>
          <NavItem>
            <MenuItem to='/my-programs' active={false}>
              دوراتي
            </MenuItem>
          </NavItem>
          <NavItem>
            <MenuItem to='/subscriptions' active={false}>
              معلومات الاشتراك
            </MenuItem>
          </NavItem>
        </>
      ) : null} */}

      <NavItem>
        <MenuItem
          to="/about-us"
          active={location.pathname.includes("about-us")}
        >
          عن تميز
        </MenuItem>
      </NavItem>
      <NavItem>
        <MenuItem
          to={{
            pathname: "https://blog.tmyyoz.com.sa",
          }}
          target="_blank"
          active={false}
        >
          مدونة تميز
        </MenuItem>
      </NavItem>
      {/* {headerMenuItems.map((menuItem, index) => (
        <Link
          href='/'
          activeStyle={{
            fontWeight: "bold",
            color: "#0DADEA",
          }}
        >
          <li>{menuItem.name}</li>
        </Link>
      ))}

      <Link
        href='/menu1'
        activeStyle={{
          fontWeight: "bold",
          color: "#0DADEA",
        }}
      >
        <li>Menu 1</li>
      </Link>
      <Link
        href='/menu2'
        activeStyle={{
          fontWeight: "bold",
          color: "#0DADEA",
        }}
      >
        <li>Menu 2</li>
      </Link>
      <Link
        href='/menu3'
        activeStyle={{
          fontWeight: "bold",
          color: "#0DADEA",
        }}
      >
        <li>Menu 3</li>
      </Link>
      <Link
        href='/menu4'
        activeStyle={{
          fontWeight: "bold",
          color: "#0DADEA",
        }}
      >
        <li>Menu 4</li>
      </Link> */}
    </S.Ul>
  );
}

export default RightNav;
