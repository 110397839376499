/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Groups } from "services/api/groups";
import { StatisticsApi } from "services/api/statistics";

const INITIAL_STATE = {
  ranking: [],
  stats: {},
  weeklyAchievements: [],
  loading: false,
  errors: "",
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState: INITIAL_STATE,
  reducers: {
    rankingRequest(state) {
      state.loading = true;
    },

    rankingSuccess(state, action) {
      state.list = action.payload.sort((a, b) => (a.rank > b.rank ? 1 : -1));
      state.loading = false;
    },
    rankingFailure(state, action) {
      state.errors = action.payload;
      state.ranking = [];
      state.loading = false;
    },
    weeklyRequest(state) {
      state.loading = true;
    },

    weeklySuccess(state, action) {
      state.weeklyAchievements = action.payload;
      state.loading = false;
    },
    weeklyFailure(state, action) {
      state.errors = action.payload;
      state.weeklyAchievements = [];
      state.loading = false;
    },
    statsRequest(state) {
      state.loading = true;
    },

    statsSuccess(state, action) {
      state.stats = action.payload;
      state.loading = false;
    },
    statsFailure(state, action) {
      state.errors = action.payload;
      state.stats = {};
      state.loading = false;
    },
  },
});

// export actions
export const {
  rankingRequest,
  rankingSuccess,
  rankingFailure,
  weeklyRequest,
  weeklySuccess,
  weeklyFailure,
  statsRequest,
  statsSuccess,
  statsFailure,
} = statisticsSlice.actions;

// export reducer
export const { reducer: statisticsReducer } = statisticsSlice;

const dispatchFetchUserRanking = (programId) => async (dispatch) => {
  try {
    dispatch(rankingRequest());

    const response = await StatisticsApi.getUserRank(programId);

    if (response.data.metaData.key === "success") {
      dispatch(rankingSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(rankingFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(rankingFailure(error.toString()));
  }
};

const dispatchFetchWeeklyAchievements = () => async (dispatch) => {
  try {
    dispatch(weeklyRequest());

    const response = await StatisticsApi.getWeeklyAchievements();

    if (response.data.metaData.key === "success") {
      dispatch(weeklySuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(weeklyFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(weeklyFailure(error.toString()));
  }
};

const dispatchFetchStudentStats = (programId) => async (dispatch) => {
  try {
    dispatch(statsRequest());

    const response = await StatisticsApi.getStudentStats(programId);

    if (response.data.metaData.key === "success") {
      dispatch(statsSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(statsFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(statsFailure(error.toString()));
  }
};
export {
  dispatchFetchUserRanking,
  dispatchFetchWeeklyAchievements,
  dispatchFetchStudentStats,
};
