import React, { useState } from 'react'
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { ModalBodyContainer, ModalContent, FooterButton, TextArea, ModalTitle } from './ProgramRating.styles'
export const ProgramReviewModal = ({ open, setOpen, handleSubmit }) => {
    const [review, setReview] = useState('')
    const onSubmit = async () => {
        await handleSubmit(review)
        setReview('')
    }
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            showCloseIcon={true}
            closeOnOverlayClick={true}
            classNames={{
                root: "custom-root",
                overlay: "app-modal-overlay",
                modal: "app-modal review-app-modal",
                closeButton: "app-modal-close",
            }}
            center
        >
            <ModalBodyContainer>
                <ModalTitle>أضف تعليقك</ModalTitle>
                <ModalContent>
                    <TextArea rows={4} value={review} onChange={(e) => setReview(e.target.value)} />
                </ModalContent>
                <FooterButton onClick={onSubmit}>إرسال</FooterButton>
            </ModalBodyContainer>
        </Modal>
    )
}
