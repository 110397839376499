import ProfileImage0 from "assets/img/profile/profile-0.svg";
import ProfileImage1 from "assets/img/profile/profile-1.svg";
import ProfileImage2 from "assets/img/profile/profile-2.svg";
import ProfileImage3 from "assets/img/profile/profile-3.svg";
import ProfileImage4 from "assets/img/profile/profile-4.svg";
import ProfileImage5 from "assets/img/profile/profile-5.svg";
import ProfileImage6 from "assets/img/profile/profile-6.svg";
import ProfileImage7 from "assets/img/profile/profile-7.svg";
import ProfileImage8 from "assets/img/profile/profile-8.svg";
import ProfileImage9 from "assets/img/profile/profile-9.svg";
import ProfileImage10 from "assets/img/profile/profile-10.svg";
import ProfileImage11 from "assets/img/profile/profile-11.svg";
import ProfileImage12 from "assets/img/profile/profile-12.svg";
import ProfileImage13 from "assets/img/profile/profile-13.svg";
import ProfileImage14 from "assets/img/profile/profile-14.svg";
import ProfileImage15 from "assets/img/profile/profile-15.svg";
import ProfileImage16 from "assets/img/profile/profile-16.svg";

export const RandomImages = [
  "profile-0",
  "profile-1",
  "profile-2",
  "profile-3",
  "profile-4",
  "profile-5",
  "profile-6",
  "profile-7",
  "profile-8",
  "profile-9",
  "profile-10",
];
const images = {
  "profile-0": ProfileImage0,
  "profile-1": ProfileImage1,
  "profile-2": ProfileImage2,
  "profile-3": ProfileImage3,
  "profile-4": ProfileImage4,
  "profile-5": ProfileImage5,
  "profile-6": ProfileImage6,
  "profile-7": ProfileImage7,
  "profile-8": ProfileImage8,
  "profile-9": ProfileImage9,
  "profile-10": ProfileImage10,
  "profile-11": ProfileImage11,
  "profile-12": ProfileImage12,
  "profile-13": ProfileImage13,
  "profile-14": ProfileImage14,
  "profile-15": ProfileImage15,
  "profile-16": ProfileImage16,
};

export default images;
