import styled from "styled-components/macro";
import { media } from "styles/utils";

export const QuestionsContainer = styled.div`
  direction: rtl;
`;
export const QuestionTitleContainer = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: #a7a9ac;
  display: flex;
  justify-content: space-between;
`;
export const QuestionTitle = styled.span``;
export const QuestionNumber = styled.span`
  margin-right: 12px;
`;

export const QuestionText = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  text-align: right;
  margin-top: 12px;
  line-height: 1.5;

  color: rgb(var(--secondary));
`;

export const QuestionInfo = styled.div``;

export const QuestionMediaContainer = styled.div`
  img {
    margin-top: 37px;
    border-radius: 15px;
    max-width: 100%;
    align-self: center;
  }
  .react-audio-player {
    margin-top: 37px;

    min-width: 500px;
    height: 136px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 37px 18px 37px 28px;
    border-radius: 15px;
    box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
    background-color: #fff;
    ${media("large")} {
      min-width: 400px;
    }
    audio {
      /*border-radius: 90px;*/
      color: red;
      background: red;
    }

    &::-webkit-media-controls-enclosure {
      border-radius: 5px;
      background-color: transparent;
    }
    /* &::-webkit-media-controls-mute-button {
      display: none !important;
    }

    &::-webkit-media-controls-volume-slider {
      display: none !important;
    }

    &::-webkit-media-controls-volume-control-container.closed {
      display: none !important;
    }
    &::-webkit-media-controls-volume-control-container {
      display: none !important;
    } */
  }

  .video-player-wrapper {
    margin-top: 37px;
    /* align-self: center; */
    position: relative;

    /* position: relative; */
    padding-top: 56.25%;
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const ChoicesContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
`;
export const ChoiceItemContainer = styled.div`
  padding: 15px 24px 16px 23px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.hide ? 0.2 : 1)};
  width:100%;
  &:not(:first-child) {
    margin-top: 21px;
  }
`;
export const ChoiceText = styled.p`
  font-size: 1.6rem;
  letter-spacing: normal;
  text-align: right;
  color: rgb(var(--black-dark));
  margin-right: 15px;
`;

export const QuestionsFooterContainer = styled.div`
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media("large")} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const QuestionNavigationContainer = styled.div`
  ${media("large")} {
    margin: 20px auto;
  }
`;
export const NextQuestionButton = styled.button`
  height: 46px;

  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  font-size: 1.4rem;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;
export const PrevQuestionButton = styled.button`
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  background-color: transparent;
  letter-spacing: normal;
  cursor: pointer;
  margin-left: 45px;

  color: rgba(var(--black-dark), 0.8);
`;
export const ProgressBar = styled.progress`
  flex: 1;
  margin: 0 34px;

  &[value] {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    border-radius: 7px;
    -webkit-animation: animate-stripes 5s linear infinite;
    animation: animate-stripes 5s linear infinite;
  }

  &[value]::-webkit-progress-bar {
    background-color: #f1f2f2;
    border-radius: 7px;
  }

  &[value]::-webkit-progress-value {
    background-color: rgb(var(--secondary));
    border-radius: 7px;
  }

  ${media("large")} {
    flex: unset;
    width: 100%;
    order: 1;
  }
`;
export const SolveButton = styled.button`
  height: 46px;
  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--primary-light));
  font-size: 1.4rem;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 5px;
  &:hover {
    background-color: rgba(var(--primary-light), 0.94);
  }
  ${media("medium")} {
    margin-right: unset;
    margin-bottom: 10px;
  }
`;

export const AudioChoice = styled.div`
  margin-right: 10px;
  width: 100%;

  ${media("medium")} {
    .audio-player {
      width: 250px;
    }
  }
`;
export const HideChoiceButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: auto;
`;

export const ShowAnswer = styled.button`
  height: 46px;

  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  font-size: 1.4rem;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;

export const CorrectIcon = styled.div`
  min-width: 22px;
  height: 22px;
  background-color: #21ba45;
  background-color: ${(props) => (props.correct ? "#21ba45" : " #db2828")};

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
`;
export const ReportContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 38px;
  p {
    font-size: 1.6rem;
    font-weight: bold;
    color: #a7a9ac;
    margin-right: 7px;
  }
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
`;
