import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";

const GuestRoute = (props) => {
  const { component: Component, ...rest } = props;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const render = (props) => {
    if (isAuthenticated) {
      return <Redirect to='/' />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};

export default GuestRoute;
