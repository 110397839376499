import styled from "styled-components/macro";

export const Input = styled.input`
  &[type="radio"] {
    appearance: none;
    min-width: 20px;
    height: 20px;
    border: 1px solid rgb(var(--black-dark));
    border-radius: 50%;
    background-clip: content-box;
    padding: 2px;
  }
  &[type="radio"]:not(:checked) {
    border: 1px solid #c6c6c6;
  }
  &[type="radio"]:checked {
    background-color: rgb(var(--black-dark));
  }
`;
