import styled from "styled-components/macro";
import { media } from "styles/utils";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; // allow the items to wrap onto multiple lines
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px;
  direction: rtl;
  color: rgb(var(--black-dark));
  width: calc(100% - 20px); // Subtracting the 10px padding on both sides
  background-image: linear-gradient(154deg, #f6faff 11%, #f9fcff 35%, #fff 77%);

  @media (min-width: 768px) {
    flex-wrap: nowrap; // disable wrapping on larger screens
    width: 100%;
  }
`;
export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-right: 0;
  padding:20px;
  background: #fff;
  box-shadow: 0 22px 66px 0 rgba(203, 194, 213, 0.31);
  border-radius: 15px;

  @media (min-width: 768px) {
    width: calc(100% - 300px); // adjust the width based on your needs
    margin-right: 20px;
  }
`;

export const NavWrapper = styled.div`
  flex-shrink: 0; // prevents the nav bar from shrinking
  width: 100%;
  border-bottom: 1px solid rgb(var(--grey-light));
  padding: 20px;
  overflow: auto;
  background-color: #fff;

  @media (min-width: 768px) {
    width: 25%;
    min-width: 400px;
    border-right: 1px solid rgb(var(--grey-light));
    border-bottom: none;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 20px;
  border: 1px solid rgb(var(--grey-light));
  background-color: #f8f9fa;
  color: rgb(var(--black-dark));
  font-size: 16px;
  direction: rtl;
  font-family: "Arial", sans-serif;
  line-height: 1.5;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 100%;
    margin: 20px 0; 
  }
`;

export const H1 = styled.h1`
  font-size: 28px;
  line-height: 1.5;
  font-weight: bold;
  color: rgb(var(--black-dark));
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  font-family: "Arial", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  direction: rtl;
`;

export const H2 = styled.h1`
  font-size: 22px;
  line-height: 1.5;
  font-weight: bold;
  color: rgb(var(--black-dark));
  margin-bottom: 20px;
  text-align: right;
  padding: 10px;
  font-family: "Arial", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  direction: rtl;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  direction: rtl;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 2px solid rgb(var(--secondary));
  border-radius: 5px;
  background-color: ${({ clicked }) => clicked ? "rgb(var(--secondary))" : "#fff"};
  color: ${({ clicked }) => !clicked ? "rgb(var(--secondary))" : "#fff"};
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;

  &:disabled {
    cursor: not-allowed;

      &:hover {
        background-color: rgba(var(--secondary), 0.5);
        ${({ warn, showButttons }) => (showButttons & warn) && `
        &::after {
          content: "حدد نص أولا لتمكين هذه الميزة";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          margin:2px;
          padding: 5px;
          height:auto;
          background-color: rgb(var(--grey-light));
          color: #000;
          font-size: 12px;
        }
      `}
    }
  }

  &:hover {
    background-color: rgb(var(--secondary));
    color: #fff;
  }

  @media (min-width: 768px) {
    width: 30%;
    margin-bottom: 0;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: justify;
  direction: rtl;
  color: rgb(var(--black-dark));
  // Differentiation: Adding a bit of a transition for a hover effect
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.01); // Slightly scale up on hover
  }
`;

export const QuizWrapper = styled.div`
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 20px;
  border: 1px solid rgb(var(--grey-light));
  background-color: #fff;
  color: rgb(var(--black-dark));
  font-size: 16px;
  direction: rtl;
  margin-top:40px
`;

export const DocumentName = styled.h1`
text-align: right;
font-size: 1.8rem;
line-height: 25px;
color: rgb(var(--black-dark));
margin-left: auto;
margin-right: 15px;
max-width: 80%;
`;

export const QuestionCounter = styled.div`
  position: relative;
  font-size: 1.2em;
`;


export const ExamQuestionFooterWrapper = styled.div`
  padding: 17px 17px;
  background-color: #fff;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  box-shadow: 0 11px 66px 0 rgba(203, 194, 213, 0.22);
  bottom: 0;

  ${media("large")} {
    flex-direction: column;
    padding: 17px 12px;
  }
`;

export const NextQuestionButton = styled.button`
  height: 46px;
  width: 50%;

  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  font-size: 1.4rem;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px;

  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
  @media (min-width: 768px) {
    margin: 10px;
    width: 20%;
  }
`;

export const PrevQuestionButton = styled.button`
  height: 46px;
  width: 50%;
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  background-color: transparent;
  letter-spacing: normal;
  cursor: pointer;
  margin: 20px;

  color: rgba(var(--black-dark), 0.8);
  @media (min-width: 768px) {
    margin: 10px;
    width: 20%;
  }
`;

export const FinalScore = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  margin-top: 12px;
  line-height: 1.4;
  color: rgb(var(--secondary));
`;

export const ModalBodyContainer = styled.div`
  position: relative;
  font-size: 1.2em;
  width: 100%;
  padding: 32px 64px 56px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  direction: rtl;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${media("medium")} {
    padding: 32px 14px 56px;
    max-width: 100%;
  }
`;

export const ReportsContainer = styled.div`
  position: relative;
  // left: 10px;
  font-size: 1.2em;
  display: flex;
  gap: 29px;
  margin-top: 40px;
`;

export const ReportItem = styled.div`
  position: relative;
  // left: 10px;
  font-size: 1.2em;
  height: 63px;
  padding: 8.5px 30px;
  border-radius: 15px;
  box-shadow: 0 22px 66px 0 rgba(203, 194, 213, 0.31);
  background-color: ${({ wrong, purple }) =>
    wrong
      ? "rgba(var(--orange), 0.1)"
      : purple
      ? "#cbc2d6"
      : "rgba(var(--green), 0.1)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media("medium")} {
    padding: 8.5px 15px;
  }
`;

export const ReportTitle = styled.div`
  position: relative;
  // left: 10px;
  font-size: 1.2em;
  text-align: center;
  color: ${({ wrong, purple }) =>
    wrong ? "rgb(var(--orange))" : purple ? "#684c88" : "rgb(var(--green))"};
  margin-top: 5px;
`;

export const ReportInfo = styled.div`
  position: relative;
  // left: 10px;
  font-size: 1.2em;
  min-height: 23px;
  padding: 0 8px 2px 9px;
  border-radius: 8px;
  box-shadow: 0 22px 66px 0 rgba(203, 194, 213, 0.31);

  background-color: ${({ wrong, purple }) =>
    wrong ? "rgb(var(--orange))" : purple ? "#684c88" : "rgb(var(--green))"};
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media("medium")} {
    font-size: 1.1rem;
  }
`;

export const ExamQuestionFooterWrapperReport = styled.div`
  width: 100%;
  margin: 20px;
  padding: 17px 36px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 11px 66px 0 rgba(203, 194, 213, 0.22);

  ${media("large")} {
    flex-direction: column;
    padding: 17px 12px;
  }
`;