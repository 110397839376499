import { useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Reward from "react-rewards";

import {
  ModalTitle,
  ModalBodyContainer,
  ModalFooter,
  FooterButton,
  ModalContent,
  ImageContainer,
  OptionsContainer,
  TextArea,
} from "./LessonReviewModal.styles";
import LikeImage from "assets/img/like.svg";
import DisLikeImage from "assets/img/d_like.svg";
import { Input } from "components/ui/RadioCheckBox/RadioCheckBox.styles";
import {
  ChoiceItemContainer,
  ChoiceText,
} from "components/TrainingQuestions/TrainingQuestions.styles";
import { Lessons } from "services/api/lessons";
import { useCallback } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};
const TEXTS = {
  0: "شرح الدرس غير مفهوم",
  1: " تدريبات الدرس قليلة",
  2: "الدرس صعب ويحتاج تبسيط أكثر ",
};

const LessonReviewModal = ({
  setOpen,
  open,
  type,
  lessonId,
  NextLesson,
  choseLesson,
}) => {
  const modalRef = useRef(null);
  const [textToSend, setTextToSend] = useState("");
  const [selectDislike, setSelectDislike] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleLessonReview = async (e) => {
    setLoading(true);
    try {
      const response = await Lessons.reviewLesson(lessonId, {
        like: 0,
        review: textToSend,
      });
      if (response.data.metaData.key === "success") {
        setOpen(false);
        setSelectDislike(false);
        setShowTextArea(false);
        setShowOptions(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      NextLesson && NextLesson(true);
      choseLesson && choseLesson(null, true);
    }
  };
  const punish = useRef();
  const handleSelectReason = (e) => {
    if (e === 3) {
      setShowTextArea(true);
      setShowOptions(false);
    } else {
      setTextToSend(TEXTS[e]);
      setShowTextArea(false);
    }
  };

  const handleLikeLesson = () => {
    // reward.current.rewardMe();

    setSelectDislike(false);
    setShowTextArea(false);

    setTimeout(() => {
      NextLesson && NextLesson(true);
      choseLesson && choseLesson(null, true);
      setOpen(false);
    }, 500);
  };

  const handleDisLikeLesson = () => {
    punish.current.punishMe();

    setSelectDislike(true);
    setShowOptions(true);
  };

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  return (
    <>
      <Modal
        ref={modalRef}
        open={open}
        onClose={() => {
          NextLesson && NextLesson(false);
          choseLesson && choseLesson(null, false);
        }}
        initialFocusRef={modalRef}
        showCloseIcon={true}
        closeOnOverlayClick={false}
        classNames={{
          root: "custom-root",
          overlay: "app-modal-overlay",
          modal: "app-modal review-app-modal",
          closeButton: "app-modal-close",
        }}
        styles={{
          root: {
            display: "grid",
            placeItems: "center",
          },
          modal: {},
        }}
        center
      >
        <ModalBodyContainer>
          <p
            style={{
              position: "absolute",
              top: 22,
              left: 70,
              fontSize: "13px",
              fontWeight: "bold",
              color: "#707070",
            }}
          >
            لم أكمل الدرس
          </p>
          <ModalTitle>هل فهمت هذا الدرس؟</ModalTitle>
          <ModalContent>
            <ImageContainer
              onClick={() => {
                handleLikeLesson();
                fire();
              }}
            >
              <img src={LikeImage} alt="LikeImage" />
            </ImageContainer>

            <Reward
              type="confetti"
              ref={punish}
              config={{ lifetime: 150, startVelocity: 15 }}
            >
              <ImageContainer onClick={() => handleDisLikeLesson()}>
                <img src={DisLikeImage} alt="DisLikeImage" />
              </ImageContainer>
            </Reward>
          </ModalContent>
          {selectDislike && (
            <ModalFooter>
              <OptionsContainer>
                {showOptions && (
                  <>
                    <ChoiceItemContainer>
                      <label htmlFor="test1">
                        <Input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          onChange={() => handleSelectReason(0)}
                        />
                      </label>{" "}
                      <ChoiceText>شرح الدرس غير مفهوم</ChoiceText>
                    </ChoiceItemContainer>
                    <ChoiceItemContainer>
                      <label htmlFor="test1">
                        <Input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          onChange={() => handleSelectReason(1)}
                        />
                      </label>{" "}
                      <ChoiceText> تدريبات الدرس قليلة</ChoiceText>
                    </ChoiceItemContainer>
                    <ChoiceItemContainer>
                      <label htmlFor="test1">
                        <Input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          onChange={() => handleSelectReason(2)}
                        />
                      </label>{" "}
                      <ChoiceText>الدرس صعب ويحتاج تبسيط أكثر </ChoiceText>
                    </ChoiceItemContainer>
                  </>
                )}

                <ChoiceItemContainer>
                  <label htmlFor="test1">
                    <Input
                      type="radio"
                      id="test1"
                      name="radio-group"
                      onChange={() => handleSelectReason(3)}
                    />
                  </label>{" "}
                  <ChoiceText>أخرى</ChoiceText>
                </ChoiceItemContainer>

                {showTextArea && (
                  <TextArea
                    rows="10"
                    onChange={(e) => setTextToSend(e.target.value)}
                  ></TextArea>
                )}
              </OptionsContainer>
              <FooterButton onClick={() => handleLessonReview()}>
                إرسال
              </FooterButton>
            </ModalFooter>
          )}
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        </ModalBodyContainer>
      </Modal>
    </>
  );
};

export default LessonReviewModal;
