import styled from "styled-components";
import { media } from "styles/utils";

// PDFQuizBody styles
//PDFQuizFooter styles
//Quiz body and PDFListing components container
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  top: -24px;
`;

export const ProgramTitleContainer = styled.div`
  margin: 40px;
  font-size: 30px;
  position: relative;
  bottom: 30px;
`;
export const ProgramTitle = styled.div`
  padding: 10px;
  direction: rtl;
  color: rgba(var(--primary-light));
  font-size: 30px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;
export const PDFTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 100px;
  @media (max-width: 768px) {
    bottom: 50px;
  }

  color: rgba(var(--primary-light));
`;

export const BodyContainer = styled.div`
  width: 75%;
  margin-right: 50px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

export const PerformanceContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 10px;
  margin: auto;
  position: relative;
  gap: 40px;

  @media (max-width: 1100px) {
    &::after {
      left: 50%;
    }
  }

  @media (max-width: 820px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &::after {
      content: none;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  word-wrap: break-word;
  font-size: 20px;
  direction: rtl;
  text-align: right;
  width: 40%;
  @media (max-width: 780px) {
    width: 100%;
  }
  @media (max-width: 820px) {
    width: 70%;
  }
`;

export const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-top: 10px;
  @media (max-width: 780px) {
    align-self: center;
  }
  @media (max-width: 820px) {
    align-self: center;
  }
`;

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;

  @media (max-width: 780px) {
    align-items: center;
    justify-content: center;
  }
`;
export const ReportRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 780px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const QuestionsContainer = styled.div`
  border: 1px solid grey;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  flex: 1;
  margin-top: 10px;
  margin-left: 30px;
  @media (max-width: 780px) {
    margin: 5px;
  }
`;
export const SuggestionConatainer = styled.div`
  margin-top: 20px;
  word-wrap: break-word;
  width: 320px;
  @media (max-width: 780px) {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
  b {
    font-weight: bold;
  }
`;
export const SuggestionText = styled.a`
  font-size: 20px;
  direction: rtl;
  color: black;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: darkblue;
  }
  b {
    font-weight: bold;
  }
`;

export const Button = styled.button`
  padding: 7px 10px 8px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  height: 40px;
  width: 300px;
  border-radius: 10px;
  background-color: rgb(var(--secondary-light));
  color: #fff;
  border: none;
  @media screen {
    margin: 20px 0px;
  }
`;
//questions container
//choices containers
export const ChoicesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  direction: rtl;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 40px;
  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;
export const ChoiceItemContainer = styled.div`
  padding: 6px 40px;
  width: 100%;
  border: 0.05px solid #ededed;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  display: flex;
  align-items: center;
  @media (max-width: 720px) {
    width: 90%;
    margin-right: 20px;
    padding: 6px 30px;
  }
`;
export const ChoiceText = styled.p`
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  color: rgb(var(--black-dark));
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  height: 5rem;
  overflow: hidden;
  letter-spacing: 3px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 720px) {
    font-weight: normal;
    font-size: 1.3rem;
  }
`;

//question image container
export const QuestionImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  justify-content: center;
`;
export const QuestionImageContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RootContentWrapper = styled.div`
  /* position: fixed;
  top: 0; 
  left: 0; 
  width: 100vw; 
  height: 100vh;  */
`;

export const PDFQuizBodyContner = styled.div`
  position: relative;
`;

//result show
export const ExamQuestionFooterWrapper = styled.div`
  padding: 17px 17px;
  background-color: #fff;
  min-height: 80px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  box-shadow: 0 11px 66px 0 rgba(203, 194, 213, 0.22);
  bottom: 0;

  ${media("large")} {
    flex-direction: column;
    padding: 17px 12px;
  }
`;

export const SepratorContainer = styled.div`
@media (max-width: 719px) {
  border-top: 1px solid hsla(200, 10%, 50%, 100);
  height: 2px;
  width: 100%;
}
  @media (min-width: 720px) {
    border-left: 1px solid hsla(200, 10%, 50%, 100);
    height: 170px;
    width: 1px;
  }
`;
