import { useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  AnswerContainer,
  ContentType,
  ModalButton,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalTitle,
  AnswerTitle,
} from "./SolutionModal.styles";
import ReactPlayer from "react-player/youtube";
import RadioCheckBox from "components/ui/RadioCheckBox/RadioCheckBox";
import { ChoiceText } from "components/TrainingQuestions/TrainingQuestions.styles";
import ProgramImage from "assets/img/program-item.jpg";
import ReactAudioPlayer from "react-audio-player";
import {
  AudioChoice,
  ChoiceImage,
  CorrectIcon,
} from "components/ExamQuestion/ExamQuestion.styles";

const SolutionModal = ({ setOpen, open, type, question, readMode }) => {
  const modalRef = useRef(null);

  let answers =
    type === "exam" && !readMode ? question.question_answers : question.answers;
  console.log(question);
  console.log(answers);
  return (
    <>
      <Modal
        ref={modalRef}
        open={open}
        onClose={() => setOpen(false)}
        initialFocusRef={modalRef}
        classNames={{
          root: "custom-root",
          overlay: "app-modal-overlay",
          modal: "app-modal",
          closeButton: "app-modal-close",
        }}
        center
      >
        <ModalTitle> طريقة الحل</ModalTitle>
        <ModalContent>
          <ModalDescription>{question?.answer_text}</ModalDescription>

          <ContentType>
            {question?.answer_media_type === "image" ? (
              <img
                src={
                  process.env.REACT_APP_RESOURCES_URL +
                  "/" +
                  (String(question.answer_media_content).startsWith("public")
                    ? "storage/" +
                      String(question.answer_media_content).substring("7")
                    : question.answer_media_content)
                }
                alt=""
              />
            ) : question?.answer_media_type === "audio" ? (
              <ReactAudioPlayer
                className="audio-player"
                src={
                  process.env.REACT_APP_RESOURCES_URL +
                  question.answer_media_content
                }
                controls
              />
            ) : question?.answer_media_type === "video" ? (
              <ContentType>
                <iframe src={question?.answer_media_content} height={225} allowFullScreen/>
              </ContentType>
            ) : null}
          </ContentType>
          {/* {!! question?.answer_media_content ?
            <ContentType>
              <iframe src={question?.answer_media_content} height={225} />
            </ContentType>:''
          } */}
        </ModalContent>
        <ModalFooter>
          <AnswerTitle> الإجابة الصحيحة</AnswerTitle>
          <AnswerContainer>
            {/* <RadioCheckBox /> */}

            {question.answer_choices_type === "image" ? (
              <ChoiceImage
                src={
                  process.env.REACT_APP_RESOURCES_URL +
                  answers?.filter((answer) => answer.is_correct)[0].content
                }
                alt="answer image"
              />
            ) : question.answer_choices_type === "audio" ? (
              <AudioChoice>
                <ReactAudioPlayer
                  className="audio-player"
                  src={
                    process.env.REACT_APP_RESOURCES_URL +
                    answers?.filter((answer) => answer.is_correct)[0].content
                  }
                  controls
                />
              </AudioChoice>
            ) : (
              <ChoiceText>
                {answers?.filter((answer) => answer.is_correct)[0].content}
              </ChoiceText>
            )}
          </AnswerContainer>
          <ModalButton onClick={() => setOpen(false)}>فهمت</ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SolutionModal;
