import styled from "styled-components/macro";

import { media } from "styles/utils";

export const CommentsContainer = styled.div`
  width: 100%;
  margin-top: 42px;
  direction: rtl;
`;
export const CommentContainer = styled.div`
  display: flex;
  align-items: flex-start;

  width: 100%;
  border-radius: 15px;

  box-shadow: ${(props) =>
    props.reply ? "none" : " 0 22px 66px 0 rgba(203, 194, 213, 0.31)"};

  padding: ${(props) => (props.reply ? "23 0" : " 23px 32px 31px")};
  background-color: #fff;
  &:not(:first-child) {
    margin-top: 25px;
  }
`;

export const CommentContent = styled.div`
  width: 100%;
  padding-top: 8px;
`;
export const UserImage = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 16px;
`;
export const CommentMeta = styled.div`
  display: flex;
  align-items: center;
`;
export const UserName = styled.h1`
  font-size: 1.4rem;
  font-weight: bold;

  text-align: right;
  color: rgb(var(--primary-light));
`;
export const CommentDate = styled.span`
  margin-right: 8px;
  font-size: 1.2rem;
  text-align: right;
  color: #b5b5b5;
`;
export const InputContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${media("medium")} {
  flex-direction:column ;
  gap:10px;
  }

`;
export const Input = styled.input`
  width: 100%;
  height: 46px;
  border-radius: 12px;
  background-color: rgba(240, 217, 233, 0.27);
  border: none;
  font-size: 1.4rem;
  color: #a493b6;
  padding: 16px 24px;
  &:focus {
    outline: none;
  }
`;
export const Submit = styled.button`
  margin-right: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 128px;
  font-size: 1.4rem;
  height: 46px;
  padding: 9px 24px 12px;
  border: none;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  color: #fff;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }


  ${media("medium")} {
    margin-right: 0;

  }
`;
export const TextContainer = styled.div`
  margin-top: 16px;
`;
export const AddCommentButton = styled.button`
  margin-top: 21px;
  border: none;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: rgb(var(--secondary));
  cursor: pointer;
`;
export const CommentText = styled.p`
  font-size: 1.4rem;
  text-align: right;
  color: rgba(var(--black-dark), 0.8);
  line-height: 1.71;
`;
