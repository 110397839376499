import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { normalize } from "styled-normalize";
import universals from "./universals";
import { media } from "./utils";

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${reset}
  ${universals}

  :root {
    --primary: 51, 32, 100; /* #332064 */
    --primary-light:77, 43, 118	 ; /*#4d2b76 */
    --secondary: 	202, 87, 160; /* #ca57a0 */
    --secondary-light: 183, 65, 144; /* #b74190 */
    --grey-light:167, 169, 172; /* #a7a9ac */
    --black:46, 46, 54; /*#2e2e36*/;
    --black-dark:35, 31, 32	; /*#231f20*/;
    --green:33, 186, 69	; /*#21ba45*/;
    --orange:219, 40, 40	; /*#db2828*/;
    --Font:"RB";

    font-size:62.5%;

  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }



  body{
    font-family: var(--Font) !important;
    min-height: 100vh;
    overflow:hidden !important;

  }
  .wpwl-label,.wpwl-group-brand  {
    display:none !important;
  }
  #checkout-form input,
  .wpwl-control-cardNumber,
  .wpwl-control-cvv{
    direction:rtl !important;
    width: 100% !important;
    padding: 15px 24px 15px 23px ;
    padding-inline:24px 15px !important;
    border-radius: 15px ;
    border: solid 1px ;
    background-color: #f6faff !important;
    font-size: 1.4rem !important;
    border-color: #b8dafc !important;
    
  
    &:focus {
      outline: none;
      border: solid 2px #b8dafc !important;
    }
  
    ${media("small")} {
      max-width: 100%;
    }
  }
  .wpwl-group-cardNumber {
    order:1;
  }
  .wpwl-group-expiry {
    order:3;
  }
  .wpwl-group-cardHolder {
    order:2;
  }
  .wpwl-group-cvv {
    order:4;
  }
  .wpwl-group-submit  {
    order:5;
  }
  .wpwl-form-card .wpwl-group-cardNumber, .wpwl-form-card .wpwl-group-cardHolder, .wpwl-form-card .wpwl-group-birthDate{
    padding-left:0 !important;
  }
  
  .wpwl-container-virtualAccount-APPLEPAY{
    width: 250px;
    overflow: hidden;
    margin: auto;
    
  }
  .wpwl-container-virtualAccount-APPLEPAY button{
    background:black !important;
  }
  .wpwl-container-virtualAccount-APPLEPAY button *{
        color: #fff !important;
  }
`;

export default GlobalStyle;
