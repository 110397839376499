import { Link } from "react-router-dom";
import {
  FooterContainer,
  FooterMenusContainer,
  BrandingContainer,
  SocialLinksContainer,
  TaxNumberContainer,
  CopyRightContainer,
  MenuContainer,
  MenuItems,
  MenuItem,
  MenuTitle,
  NavItem,
} from "./Footer.styles";
import LogoImage from "../../assets/img/logo.svg";
import MadaImage from "../../assets/img/footer-image.svg";
import YoutubeImage from "assets/img/footer/youtube.svg";
import TiktokImage from "assets/img/footer/tiktok.svg";
import TwitterImage from "assets/img/footer/twitter.svg";
import InstaImage from "assets/img/footer/instagram.svg";

import { footerMenuOne, footerMenuTwo, footerMenuThree } from "../../data/menu";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterMenusContainer>
        <img
          src={LogoImage}
          height={61}
          width={105}
          alt='logo'
          className='footer_logo'
        />
        <MenuContainer>
          <MenuTitle>روابط مهمة</MenuTitle>

          <MenuItems>
            {footerMenuOne.map((menuItem, index) => (
              <NavItem key={index}>
                <MenuItem to={menuItem?.link}>{menuItem.name}</MenuItem>
              </NavItem>
            ))}
          </MenuItems>
        </MenuContainer>
        <MenuContainer>
          <MenuTitle>البرامج التدريبية</MenuTitle>
          <MenuItems>
            {footerMenuTwo.map((menuItem, index) => (
              <NavItem key={index}>
                <MenuItem to={menuItem?.link}>{menuItem.name}</MenuItem>
              </NavItem>
            ))}
          </MenuItems>
        </MenuContainer>
        <MenuContainer >
          <MenuTitle>موجودين هنا</MenuTitle>
          <SocialLinksContainer>
            <a href='https://www.youtube.com/c/Tmyyoz' target='_blank' rel='noreferrer'>
              <img src={YoutubeImage} height={25} width={25} alt='facebook' />
            </a>
            <a href='https://www.tiktok.com/@tmyyoz.sa?lang=ar' target='_blank' rel='noreferrer'>
              <img src={TiktokImage} height={25} width={25} alt='linkedin' />
            </a>
            <a href='https://twitter.com/tmyyozsa' target='_blank' rel='noreferrer'>
              <img src={TwitterImage} height={25} width={25} alt='twitter' />
            </a>
            <a href='https://www.instagram.com/tmyyozsa/' target='_blank' rel='noreferrer'>
              <img src={InstaImage} height={25} width={25} alt='instagram' />
            </a>
          </SocialLinksContainer>
        </MenuContainer>
      </FooterMenusContainer>
      <BrandingContainer>
        <img
          src={MadaImage}
          width={'200px'}
          alt='logo'
          className='footer_logo'
        />
        <CopyRightContainer>

          <p>جميع الحقوق محفوظة لمنصة  تميز 2022 ©</p>
        </CopyRightContainer>

      </BrandingContainer>
      <TaxNumberContainer>
        <p><span style={{fontSize:"1em"}}> 310985403300003 </span>شركة وجهات التميز لتقنية المعلومات | الرقم الضريبي</p>
      </TaxNumberContainer>
    </FooterContainer>
  );
};

export default Footer;
