import { get } from "config/api";

export const zoom = {
  getMeetingInfo: (group_id, category_id) =>
    get("webinars", {
      params: {
        ...(group_id && { group_id }),
        ...(category_id && { category_id }),
      },
    }),
};
