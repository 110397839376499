import { useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {
  ModalTitle,
  ModalBodyContainer,
  ModalFooter,
  FooterButton,
  ModalContent,
  TextArea
} from "./QuestionReportModal.styles";

import { useEffect } from "react";
import { Exercises } from "services/api/exercises";
import {
  ChoiceItemContainer,
  ChoiceText,
} from "components/TrainingQuestions/TrainingQuestions.styles";
import { Input } from "components/ui/RadioCheckBox/RadioCheckBox.styles";
import React from "react";
import { toast } from "react-toastify";
import Toast from "components/Toast/Toast";


const QuestionReportModal = ({
  setOpen,
  open,
  mode,
  resourceId,
  questionId,
}) => {
  const modalRef = useRef(null);
  const [selected, setSelected] = useState(null);
  const [note,setNote] = useState("");
  const [reasons, setReasons] = useState([]);


  useEffect(() => {
    fetchReportReasons();
  }, []);

  useEffect(() => {
    setSelected(null);
    setNote("");
  }, [questionId]);

  const fetchReportReasons = async () => {
    try {
      const response = await Exercises.getReportReasons();
      if (response.data.metaData.key === "success") {
        setReasons(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleSendReport = async (e) => {
    if (!selected) {
      toast(<Toast title='الرجاء إختيار سبب للتبليغ' error />);
      return;
    }
    try {
      let payload = {
        question_id: questionId,
        report_reason_id: selected,
        resource_type:
          mode === "exercises"
            ? "App\\Models\\Exercise"
            : "App\\Models\\CollectionExam",
        resource_id: resourceId,
        note:  note,
      };
      const response = await Exercises.sendReport(payload);
      if (response.data.metaData.key === "success") {
        toast(<Toast title='تم التبيلغ بنجاح' success />);
        setOpen(false)
        setSelected(null);
        setNote("");
      }
    } catch (error) {
      toast(
        <Toast
          message={error.response?.data?.metaData?.message || "حدث خطا ما"}
          title='خطأ'
          error
        />
      );
    }
  };

  return (
    <>
      <Modal
        ref={modalRef}
        open={open}
        onClose={() => setOpen(false)}
        initialFocusRef={modalRef}
        showCloseIcon={true}
        closeOnOverlayClick={true}
        classNames={{
          root: "custom-root",
          overlay: "app-modal-overlay",
          modal: "app-modal review-app-modal",
          closeButton: "app-modal-close",
        }}
        center
      >
        <ModalBodyContainer>
          <ModalTitle>تبليغ</ModalTitle>
          <ModalContent></ModalContent>
          {React.Children.toArray(
            reasons.length > 0 &&
            reasons.map((reason) => (
              <ChoiceItemContainer>
                <label htmlFor='test1'>
                  <Input
                    type='radio'
                    id='test1'
                    name='radio-group'
                    onChange={() => setSelected(reason.id)}
                  />
                </label>{" "}
                <ChoiceText>{reason.name}</ChoiceText>
              </ChoiceItemContainer>
            ))
          )}
          {selected === 4 && <TextArea rows={3} value={note} onChange={e=>setNote(e.target.value)}/>}
          <ModalFooter>
            <FooterButton onClick={() => handleSendReport()}>
              ارسال الابلاغ
            </FooterButton>
          </ModalFooter>
        </ModalBodyContainer>
      </Modal>
    </>
  );
};

export default QuestionReportModal;
