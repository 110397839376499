import {
  Cognitive_ability,
  Undergraduate_abilities,
  Undergraduate_courses,
  courses_abilities,
  TeacherArea,
  challenge_Abilitys_category,
  subjects_category,
} from "./categories";
import {
  Undergraduates,
  Abilitys,
  Teacher,
  challenge_Abilitys,
  subjects,
} from "./groups";

export const triningPrograms = [
  {
    text: "القدرات العامة",
    path: `/groups/${Abilitys}/categories/${courses_abilities}/programs`,
  },
  {
    text: "التحصيلي",
    path: `/groups/${Abilitys}/categories/${Undergraduate_courses}/programs`,
  },
  {
    text: "قدرات الجامعيين",
    path: `/groups/${Undergraduates}/categories/${Undergraduate_abilities}/programs`,
  },
  {
    text: "القدرة المعرفية",
    path: `/groups/${Undergraduates}/categories/${Cognitive_ability}/programs`,
  },

  {
    text: "الرخصة المهنية",
    path: `/categories/16/programs/34/documents`,
    // path: `/groups/${Teacher}/categories/${TeacherArea}/programs/34/documents`,
  },
  {
    text: "تحدي القدرات",
    path: `/groups/${challenge_Abilitys}/categories/${challenge_Abilitys_category}/programs`,
  },
  {
    text: "مواد وأكثر",
    path: `/groups/${subjects}/categories/${subjects_category}/programs`,
  },
];
export const headerMenuItems = [
  {
    name: "الرئيسية",
    active: true,
  },
  {
    name: "عن تميز",
    active: false,
  },
  {
    name: "المدونه",
    active: false,
  },
];

export const footerMenuOne = [
  {
    name: "عن منصة تميز",
    link: "/about-us",
  },
  {
    name: "الشروط والأحكام",
    link: "/terms",
  },
  {
    name: "سياسة الخصوصية",
    link: "/privacy-policy",
  },
  {
    name: "سياسة الاسترجاع",
    link: "/refund-policy",
  },
  {
    name: "مدونة تميز",
    link: "",
  },
];

export const footerMenuTwo = [
  {
    name: "القدرات العامة",
    link: `/groups/${Abilitys}/categories/${courses_abilities}/programs`,
  },
  {
    name: "التحصيلي",
    link: `/groups/${Abilitys}/categories/${Undergraduate_courses}/programs`,
  },
  {
    name: "قدرات الجامعيين",
    link: `/groups/${Undergraduates}/categories/${Undergraduate_abilities}/programs`,
  },
  {
    name: "القدرة المعرفية",
    link: `/groups/${Undergraduates}/categories/${Cognitive_ability}/programs`,
  },

  {
    name: "الرخصة المهنية",
    link: `/groups/${Teacher}/categories/${TeacherArea}/programs`,
  },
];

export const footerMenuThree = [
  {
    name: "تطبيق تميز على جوجل بلاي",
  },

  {
    name: "تطبيق تميز على ابل ستور",
  },
];
