import { get, post } from "../../config/api";

export const Programs = {
  getPrograms: (categoryId, limit = 10, page = 1) =>
    get(`programs?filter[category_id]=${categoryId}&page=${page}`),
  getProgram: (programId) => get(`programs/${programId}`),
  getUserPrograms: () => get(`users/me/programs`),
  reviewProgram: (programId, payload) =>
    post(`programs/${programId}/reviews`, payload),
  reviews: (programId) => get(`programs/${programId}/reviews`),
};
