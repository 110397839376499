import React from "react";
import { RatingContainer, RatingTitle, Input } from "./ProgramRating.styles";
import ReactStars from "react-rating-stars-component";
import { useParams } from "react-router";
import { Programs } from "services/api/programs";
import Toast from "components/Toast/Toast";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";
import { ProgramReviewModal } from './ProgramReviewModal'
const ProgramRating = () => {
  const { programId } = useParams();
  const selectedProgram = useSelector(
    (state) => state.programs.selectedProgram
  );
  
  const [open, setOpen] = useState(false)
  
  const [newRating, setnewRating] = useState(null)
  const ratingChanged =  (rating) =>{
     setnewRating(rating)
     setOpen(true)
  }
  const handleSubmit = async (review) => {
    try {
      const response = await Programs.reviewProgram(programId, {
        review: review,
        rating: newRating,
      });

      if (response.data.metaData.key === "success") {
        toast(<Toast title='تم التقييم بنجاح' success />);
      }
    } catch (error) {
      toast(<Toast title={error.response.data.metaData.message} error />);
    }
    setOpen(false)
  }
  return (
    <RatingContainer>
      <RatingTitle>تقييم الدورة</RatingTitle>
      <ReactStars
        count={5}
        onChange={ratingChanged}
        size={24}
        value={ newRating ?? selectedProgram?.my_rating?.rating }
        activeColor='#ffd700'
        color='#ffffff'

      // classNames={selectedProgram?.my_rating?.rating && "review-disabled"}
      />
      {open && <ProgramReviewModal open={open} setOpen={setOpen} handleSubmit ={handleSubmit }/>}
      {/* <Input/>   */}
    </RatingContainer>
  );
};

export default ProgramRating;
