import {
  IconContainer,
  TextContainer,
  ToastMessage,
  ToastTitle,
  ToastWrapper,
  ProfileLink,
  Block
} from "./Toast.styles";

import ErrorIcon from "assets/img/error.svg";
import SuccessIcon from "assets/img/success.svg";
const Toast = ({
  success = false,
  error = false,
  message = "",
  title = "",
  LinkToProfile = false
}) => {
  
  return (
    <ToastWrapper>
      <IconContainer error={error} success={success}>
        {error && <img src={ErrorIcon} alt='error' />}
        {success && <img src={SuccessIcon} alt='error' />}
      </IconContainer>
      <TextContainer>
        <ToastTitle>{title}</ToastTitle>
        <ToastMessage>{message}</ToastMessage>
        {LinkToProfile &&
          <Block>
            <ProfileLink to="/profile">الملف الشخصي</ProfileLink>
          </Block>
        }
      </TextContainer>
    </ToastWrapper>
  );
};

export default Toast;
