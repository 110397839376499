import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  FooterButton,
  ModalBodyContainer,
  ModalFooter,
  ModalTitle,
} from "../Modals/ConfirmationModal/ConfirmationModal.styles";

const ConfirmationModal = ({ setOpen, open, setShowQuiz }) => {

  const handleClose=()=>{
     setShowQuiz(false)   
}   

  return (
    <>
      <Modal
        open={open}
        onClose={() => {setOpen(false); handleClose();}}
        showCloseIcon={false}
        closeOnOverlayClick={true}
        classNames={{
          root: "custom-root",
          overlay: "app-modal-overlay",
          modal: "app-modal",
          closeButton: "app-modal-close",
        }}
        center
      >
        <ModalBodyContainer>
          <ModalTitle>هل تود الخروج من الاختبار</ModalTitle>
          <ModalFooter>
            <FooterButton onClick={() => handleClose()}>
              Cancel
            </FooterButton>
          </ModalFooter>
        </ModalBodyContainer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
