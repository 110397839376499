import { post, get, patch } from "../../config/api";

export const UserApi = {
  getProfile: () => get("users/me"),
  updateProfile: (data) => patch("users/me", data),
  getUserSubscriptions: () => get("users/me/subscriptions"),
  getCities: () => get("cities"),
  enableFreeTrail: () => post("free-trial/enable"),
  getUsersReviews: () => get("user-reviews"),
};
