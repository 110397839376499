/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Groups } from "services/api/groups";

const INITIAL_STATE = {
  list: [],
  loading: false,
  errors: "",
};

const groupsSlice = createSlice({
  name: "groups",
  initialState: INITIAL_STATE,
  reducers: {
    groupsRequest(state) {
      state.loading = true;
    },

    groupsSuccess(state, action) {
      state.list = action.payload;
      state.loading = false;
    },
    groupsFailure(state, action) {
      state.errors = action.payload;
      state.list = [];
      state.loading = false;
    },
  },
});

// export actions
export const { groupsRequest, groupsSuccess, groupsFailure } =
  groupsSlice.actions;

// export reducer
export const { reducer: groupsReducer } = groupsSlice;

const dispatchFetchGroups = () => async (dispatch) => {
  try {
    dispatch(groupsRequest());

    const response = await Groups.getGroups();

    if (response.data.metaData.key === "success") {
      dispatch(groupsSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(groupsFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(groupsFailure(error.toString()));
  }
};

export { dispatchFetchGroups };
