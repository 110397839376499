import styled from "styled-components/macro";
import { media } from "../../styles/utils";
import { Link } from "react-router-dom";


export const FooterContainer = styled.footer`
  position: relative;
  padding: 105px 140px 93px 325px;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(
    179deg,
    #f6faff 3%,
    #f9fcff 54%,
    #ffffff 99%
  );
  display: flex;
  flex-direction: column;

  ${media("huge")} {
    padding: 105px 100px 93px 100px;
  }
  ${media("large")} {
    padding: 105px 50px 93px 50px;
  }
  ${media("medium")} {
    padding: 105px 30px 93px 30px;
  }
`;

export const FooterMenusContainer = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap:wrap;
  flex-direction: row-reverse;
  .footer_logo {
    max-width: 105px !important;
  }
  ${media("medium")} {
    display:grid;
    grid-template-columns:1fr 1fr;
    place-items:start center;
    gap:3rem 1rem;
  }
  ${media("small")} {
    display:grid;
    grid-template-columns:1fr ;
    place-items:start center;
    
  }
  
`;

export const MenuContainer = styled.div`
  &:last-of-type{
    margin-inline-start:20px;
    ${media("small")} {
      margin-inline:0;
      
    }
  }
  ${media("small")} {
    display: grid;
    place-items: center;
    
  }

`;

export const MenuItems = styled.div`
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  
  ${media("small")}{
   align-items:center;
   margin-top: 12px;
  }

  
`;
export const MenuTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: right;
  color: rgba(var(--black-dark), 0.8);
  
  
`;

export const NavItem = styled.div`
  &:not(:first-of-type) {
    margin-top: 23px;
  }
  text-align:end;
  ${media('small')}{
    &:not(:first-of-type) {
      margin-top: 15px;
    } 
  }

`;

export const MenuItem = styled(Link)`
  color: rgba(var(--black-dark));
  font-weight: normal;
  line-height: 1;
  font-size: 1.4rem;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration:none;
  text-align: right;
  &::after {
    content: "";
  }
  &:hover {
    color: rgb(var(--black-dark));
  }

  
`;

export const SocialLinksContainer = styled.div`
  margin-top: 23px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap:10px;
  a {
    display: block;
    transition: all 0.3s;
    &:hover {
      transform: translateY(-1px) scale(1.01);
    }
  }
  
`;

export const BrandingContainer = styled.div`
  display: grid;
  grid-template-columns:200px 1fr 27%;

  align-items:center;
  margin-block-start:2rem;
  
  
  ${media("huge")}{
    grid-template-columns:200px 1fr 25%;
  }
  ${media("large")}{
    grid-template-columns:200px 1fr 24%;
  }
  ${media("medium")}{
    grid-template-columns:1fr 1fr;
    gap:1rem;
    place-items:center;
    
  }
  ${media("small")}{
    grid-template-columns:1fr ;
    
    gap:2rem;
  }
  
`;
export const CopyRightContainer = styled.div`
  p {
    color: rgb(var(--primary-light));
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 30px;
    text-align:end;
    
    ${media("medium")}{
      font-size: 1.5rem; 
      text-align:center;
    }
    ${media("small")}{
      font-size: 1rem; 
      text-align:center;
    }
  }
 
  
`;

export const TaxNumberContainer=styled.div`
  position:absolute;
  bottom:0;
  width:100%;
  left:0;
  right:0;
  min-height:50px;
  max-height:70px;
  background:#e6e7e8;
  display:grid;
  place-items:center;
  padding-inline:5px;
  
  p{
    color:#231f20;
    font-size:1.8rem;
    text-align:center;
    line-height: 30px;
    ${media("medium")}{
      font-size: 1.3rem; 
      
    }
    ${media("small")}{
      font-size:1rem;
    }
  }
`