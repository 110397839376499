import Modal from "react-responsive-modal";
import styled, { css } from "styled-components/macro";
import { media } from "styles/utils";

export const AnswerContainer = styled.div`
  width: 100%;
  max-width: 330px;
  margin: 33px auto 0;
  padding: 10px 17px 16px 16px;
  border-radius: 15px;
  box-shadow: 0 9px 27px 0 rgba(203, 194, 213, 0.23);
  background-color: #fff;
  direction: rtl;
  display: flex;
  align-items: center;
`;

export const ModalButton = styled.button`
  height: 46px;
  margin: 52px auto;
  padding: 9px 24px 12px;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  font-size: 1.4rem;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;

export const ModalTitle = styled.h1`
  position: absolute;
  top: 24px;
  right: 32px;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
  color: rgba(35, 31, 32, 0.87);
`;
export const ModalDescription = styled.p`
  font-size: 1.6rem;
  letter-spacing: normal;
  text-align: right;
  line-height: 1.5;

  color: rgba(35, 31, 32, 0.8);
  ${media("medium")} {
    width: 100%;
    text-align: center;
  }
`;

export const ContentType = styled.div`
  margin-top: 20px;

  img {
    max-width: 100%;
    border-radius: 15px;
  }
  p {
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: right;
    color: rgba(35, 31, 32, 0.8);
  }
  .react-player {
    ${media("medium")} {
      width: 300px !important;
    }
  }
`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ModalContent = styled.div`
  background-color: #f1f2f2;
  padding: 76px 93px 35px;

  ${media("medium")} {
    padding: 76px 33px 35px;
  }
`;

export const AnswerTitle = styled.p`
  margin-left: auto;
  padding: 18px 18px 0 0;
  font-size: 1.4rem;
`;
