import { useRef } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { persistor } from "store";
import store from "store";
import { dispatchLogout } from "store/slices/auth";
import {
  FooterButton,
  ModalBodyContainer,
  ModalFooter,
  ModalTitle,
  ModalSubTitle,
} from "./LogoutModal.styles";

const LogoutModal = ({ setOpen, open, type }) => {
  const modalRef = useRef(null);

  const handleExamCancellation = () => {
    persistor.purge();
    store.dispatch(dispatchLogout());
    localStorage.removeItem("persist:root");
    // eslint-disable-next-line no-restricted-globals
    window.location.reload();
  };
  return (
    <>
      <Modal
        ref={modalRef}
        open={open}
        onClose={() => setOpen(false)}
        initialFocusRef={modalRef}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        classNames={{
          root: "custom-root",
          overlay: "app-modal-overlay",
          modal: "app-modal",
          closeButton: "app-modal-close",
        }}
        center
      >
        <ModalBodyContainer>
          <ModalTitle> لا يمكنك الدخول الا من جهاز فقط</ModalTitle>
          <ModalSubTitle>
            تم تسجيل الخروج من هذا الجهاز بعد تسجيل الدخول من جهاز اخر{" "}
          </ModalSubTitle>

          <ModalFooter>
            <FooterButton onClick={() => handleExamCancellation()}>
              خروج
            </FooterButton>
          </ModalFooter>
        </ModalBodyContainer>
      </Modal>
    </>
  );
};

export default LogoutModal;
