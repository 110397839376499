import { get, post } from "../../config/api";

export const Auth = {
  register: (data) => post("auth/register", data),
  login: (data) => post("auth/login", data),

  sendForgetCode: (data) => post("forget-password/send", data),
  resetPassword: (data) => post("forget-password/reset", data),
  verify: (data) => post("verification/verify", data),
  resendCode: (data) => post("verification/resend", data),
  getRedirectUrl: (provider) => get(`auth/providers/${provider}/url`),
  getCallbackUrl: ({ provider, state, code, oauthToken, oauthVerifier }) =>
    get(
      `auth/providers/${provider}/callback?state=${state}&code=${code}&oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`
    ),
  authPing: () => get("auth/ping"),
};
