import { newGet, newPost } from "../../config/api/feature2";
export const getTextFromApi = {
    getDocuments: async () => {
        try {
          console.log("I entered here")
          const response = await newGet('/document/listing');
          return response.data;
        } catch (error) {
          console.error('Error:', error);
          throw error
        }
      },
    document: async (documentID) => {
        try {
          const response = await newGet(`/document/details/${documentID}`);
          return response.data;
        } catch (error) {
          throw error
          console.error('Error:', error);
        }
      },
    summary: async (documentId) => {
        try {
          const response = await newGet(`/document/summary/${documentId}`);
         return response.data;
        } catch (error) {
          console.error('Failed to fetch summary:', error);
          throw error
        }
      },
      explanation: async (documentId, paraID) => {
        try {
          const response = await newGet(`/document/explain/${documentId}/${paraID}`);
         return response.data;
        } catch (error) {
          throw error
          console.error('Failed to fetch explanation:', error);
        }
      },
      quiz: async (documentId) => {
        try {
          const response = await newGet(`/document/quiz/${documentId}`);
         return response.data;
        } catch (error) {
          throw error
          console.error('Failed to fetch quiz:', error);
        }
      },
      isSubscribed: async (cid, pid) => {
        try {
          const response = await newGet(`/document/subscription-status?category_id=${cid}&program_id=${pid}`);
         return response.data;
        } catch (error) {
          throw error
        }
      }
  };

export const OBJQUizAPI = {
  getPDFs: async () => {
      try {
        const response = await newGet('/quiz/listing');
        return response.data;
      } catch (error) {
        console.error('Error in fetching PDFListings:', error);
        throw error
      }
  },
  getQuiz: async (id) => {
    try {
      const response = await newGet(`/quiz/start-quiz/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error in fetching the sgghsrhsg question:", error);
      throw error
    }
  },    
  submitAnswer :async(quiz_id, question_id, option)=> {
    try {
      const payload = {
        quiz_id,
        question_id,
        option
      };
      
      const response = await newPost('/quiz/submit-answer', payload); 
      return response.data;
    } catch (error) {
      console.error("Error occurred while submitting the answer:", error);
      throw error;
    }
  },
  submitQuiz:async(quiz_id)=>{
    try {
      const response=await newGet(`/quiz/submit-quiz/${quiz_id}`);
      return response.data;
    } catch (error) {
      console.log("Error occurred while submiting the Quiz:",error);
    }
  },
  GetQuizReview:async(quiz_id)=>{
    try {
      const response=await newGet(`/quiz/review-quiz/${quiz_id}`);
      return response.data;
      
    } catch (error) {
      console.log("Error occured while fething the QuizReview data",error);
    }
  }
}

