import CheckIcon from "assets/img/check.svg";
import PlayIcon from "assets/img/play.svg";
import QuestionIcon from "assets/img/question.svg";
import React, { useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useDispatch, useSelector } from "react-redux";
import { showLoading } from "react-redux-loading-bar";
import { useParams } from "react-router";
import { dispatchFetchSubCategories } from "store/slices/subCategories";
import { convertMinutes } from "utils";
import {
  CourseTime,
  CourseTimeContainer,
  CourseTimeTitle,
  LessonsContainer,
  SectionContainer,
  SectionTime,
  SectionTitle,
  SingleLessonContainer,
  SingleLessonIcon,
  SingleLessonTime,
  SingleLessonTitle,
  SubCategoryContainer,
  SubCategoryTitle,
  SubCategoryTitleContainer,
  SingleLessonStatus,
} from "./ProgramContentAccordion.styles";
import LockIcon from "assets/img/lock.svg";
import CircleProgressBar from "components/ui/CircleProgressBar";

const ProgramContentAccordion = ({
  setSelectedLesson,
  setSelectedExercise,
  setContentType,
  setShowProgramReview,
  selectedLesson,
  selectedExercise,
  contentType,
}) => {
  const subCategories = useSelector((state) => state.subCategories.list);
  const selectedProgram = useSelector(
    (state) => state.programs.selectedProgram
  );
  const { user } = useSelector((state) => state.auth);

  const { programId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dispatchFetchSubCategories(programId));
  }, []);

  const calculateChapterPercentage = (chapter) => {
    let lessonsCount = chapter.lessons.length;
    let exercisesCount = 0;
    let doneCount = 0;
    if (chapter.lessons.length > 0) {
      doneCount = chapter.lessons.reduce((accu, curr) => {
        if (curr.is_done) {
          accu += 1;
        }
        if (curr.exercises.length > 0) {
          curr.exercises.forEach((e) => {
            if (e.is_done) {
              accu += 1;
            }
          });
        }

        return accu;
      }, 0);

      exercisesCount = chapter.lessons.reduce((accu, curr) => {
        if (curr.exercises.length > 0) {
          return (accu = accu + curr.exercises.length);
        }
        return 0;
      }, 0);
    }

    let totalCount = lessonsCount + exercisesCount;
    if (!totalCount) {
      return 0;
    }

    return Math.trunc((100 * doneCount) / totalCount);
  };

  const handleSelectedLesson = async (lesson) => {
    window.dataLayer.push({
      event: "select_lesson",
      program_name: selectedProgram.name,
      lesson_name: lesson.title,
    });
    window.scrollTo(0, 0);

    setSelectedLesson({ ...lesson });
    dispatch(showLoading());
    setContentType("lesson");
    if (lesson.is_latest) {
      setShowProgramReview(true);
    }
  };
  const handleSelectedExercise = async (exercise) => {
    window.dataLayer.push({
      event: "select_exercise",
      program_name: selectedProgram.name,
      exercise_name: exercise.title,
    });
    window.scrollTo(0, 0);
    setSelectedExercise(exercise);
    setContentType("exercise");
  };
  return (
    <LessonsContainer>
      <CourseTimeContainer>
        <CourseTimeTitle>محتوى الدورة</CourseTimeTitle>
        <CourseTime>
          {" "}
          {convertMinutes(selectedProgram.duration)
            ? convertMinutes(selectedProgram.duration)
            : null}
        </CourseTime>
      </CourseTimeContainer>
      <SectionContainer>
        {React.Children.toArray(
          subCategories.length > 0 &&
            subCategories.map((subCategory) => (
              <>
                {(subCategory.chapters.length > 0 ||
                  subCategory.lessons.length > 0) && (
                  <SubCategoryContainer key={subCategory.id}>
                    <SubCategoryTitleContainer>
                      <SubCategoryTitle> {subCategory.title}</SubCategoryTitle>
                      <CourseTime>
                        {convertMinutes(subCategory.duration)}
                      </CourseTime>
                    </SubCategoryTitleContainer>
                    <Accordion
                      allowMultipleExpanded
                      allowZeroExpanded
                      preExpanded={[
                        subCategory.chapters[0]
                          ? subCategory.chapters[0].id
                          : subCategory.id,
                      ]}
                    >
                      {subCategory.lessons.length > 0 && (
                        <AccordionItem uuid={subCategory.id}>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <SectionTitle>دروس الفصل</SectionTitle>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            {React.Children.toArray(
                              subCategory.lessons.length > 0 &&
                                subCategory.lessons.map((lesson) => (
                                  <>
                                    <SingleLessonContainer
                                      active={
                                        selectedLesson?.id === lesson.id &&
                                        contentType === "lesson"
                                      }
                                      key={lesson.id}
                                      onClick={() =>
                                        lesson.is_paid === "مدفوع" &&
                                        user.is_trail &&
                                        !selectedProgram.is_user_subscribed
                                          ? null
                                          : handleSelectedLesson(lesson)
                                      }
                                    >
                                      <SingleLessonIcon
                                        isDone={lesson.is_done === 1}
                                      >
                                        {lesson.is_done ? (
                                          <img src={CheckIcon} alt="play" />
                                        ) : (
                                          <img src={PlayIcon} alt="play" />
                                        )}
                                      </SingleLessonIcon>
                                      <SingleLessonTitle>
                                        {lesson.title}
                                      </SingleLessonTitle>

                                      <SingleLessonTime>
                                        {convertMinutes(lesson.duration)}
                                      </SingleLessonTime>
                                      {!selectedProgram.is_user_subscribed &&
                                      user.is_trail ? (
                                        <SingleLessonStatus>
                                          {lesson.is_paid ===
                                          "غير مدفوع" ? null : (
                                            <img src={LockIcon} alt="lock" />
                                          )}
                                        </SingleLessonStatus>
                                      ) : null}
                                    </SingleLessonContainer>
                                    {React.Children.toArray(
                                      lesson.exercises.length > 0 &&
                                        lesson.exercises.map((exercise) => (
                                          <>
                                            {exercise.questions_count > 0 && (
                                              <SingleLessonContainer
                                                active={
                                                  selectedExercise?.id ===
                                                    exercise.id &&
                                                  contentType === "exercise"
                                                }
                                                key={exercise.id}
                                                onClick={() =>
                                                  exercise.is_paid ===
                                                    "مدفوع" &&
                                                  user.is_trail &&
                                                  !selectedProgram.is_user_subscribed
                                                    ? null
                                                    : handleSelectedExercise(
                                                        exercise
                                                      )
                                                }
                                              >
                                                <SingleLessonIcon
                                                  isDone={
                                                    exercise.is_done === 1
                                                  }
                                                  isExercise={true}
                                                >
                                                  <img
                                                    src={QuestionIcon}
                                                    alt="QuestionIcon"
                                                  />
                                                </SingleLessonIcon>
                                                <SingleLessonTitle>
                                                  {exercise.title}
                                                </SingleLessonTitle>

                                                {!selectedProgram.is_user_subscribed &&
                                                user.is_trail ? (
                                                  <SingleLessonStatus>
                                                    {exercise.is_paid ===
                                                    "غير مدفوع" ? (
                                                      <h1>مفتوح مجانا</h1>
                                                    ) : (
                                                      <img
                                                        src={LockIcon}
                                                        alt="lock"
                                                      />
                                                    )}
                                                  </SingleLessonStatus>
                                                ) : null}
                                              </SingleLessonContainer>
                                            )}
                                          </>
                                        ))
                                    )}
                                  </>
                                ))
                            )}
                          </AccordionItemPanel>
                        </AccordionItem>
                      )}
                      {React.Children.toArray(
                        subCategory.chapters.length > 0 &&
                          subCategory.chapters.map((chapter) => (
                            <AccordionItem uuid={chapter.id} key={chapter.id}>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  <CircleProgressBar
                                    percentage={calculateChapterPercentage(
                                      chapter
                                    )}
                                    width="40px"
                                    color="#ca57a0"
                                    strokeWidth={12}
                                    fontSize="23px"
                                  />
                                  <SectionTitle>{chapter.title}</SectionTitle>
                                  <SectionTime>
                                    {convertMinutes(chapter.duration)}
                                  </SectionTime>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                {React.Children.toArray(
                                  chapter.lessons.length > 0 &&
                                    chapter.lessons.map((lesson) => (
                                      <>
                                        <SingleLessonContainer
                                          active={
                                            selectedLesson?.id === lesson.id &&
                                            contentType === "lesson"
                                          }
                                          key={lesson.id}
                                          onClick={() =>
                                            lesson.is_paid === "مدفوع" &&
                                            user.is_trail &&
                                            !selectedProgram.is_user_subscribed
                                              ? null()
                                              : handleSelectedLesson(lesson)
                                          }
                                        >
                                          <SingleLessonIcon
                                            isDone={lesson.is_done === 1}
                                          >
                                            {lesson.is_done ? (
                                              <img src={CheckIcon} alt="play" />
                                            ) : (
                                              <img src={PlayIcon} alt="play" />
                                            )}
                                          </SingleLessonIcon>
                                          <SingleLessonTitle>
                                            {lesson.title}
                                          </SingleLessonTitle>

                                          <SingleLessonTime>
                                            {convertMinutes(lesson.duration)}
                                          </SingleLessonTime>
                                          {!selectedProgram.is_user_subscribed &&
                                          user.is_trail ? (
                                            <SingleLessonStatus>
                                              {lesson.is_paid ===
                                              "غير مدفوع" ? null : (
                                                <img
                                                  src={LockIcon}
                                                  alt="lock"
                                                />
                                              )}
                                            </SingleLessonStatus>
                                          ) : null}
                                        </SingleLessonContainer>
                                        {React.Children.toArray(
                                          lesson.exercises.length > 0 &&
                                            lesson.exercises.map((exercise) => (
                                              <>
                                                {exercise.questions_count >
                                                  0 && (
                                                  <SingleLessonContainer
                                                    active={
                                                      selectedExercise?.id ===
                                                        exercise.id &&
                                                      contentType === "exercise"
                                                    }
                                                    key={exercise.id}
                                                    onClick={() =>
                                                      exercise.is_paid ===
                                                        "مدفوع" &&
                                                      user.is_trail &&
                                                      !selectedProgram.is_user_subscribed
                                                        ? null()
                                                        : handleSelectedExercise(
                                                            exercise
                                                          )
                                                    }
                                                  >
                                                    <SingleLessonIcon
                                                      isDone={
                                                        exercise.is_done === 1
                                                      }
                                                      isExercise={true}
                                                    >
                                                      <img
                                                        src={QuestionIcon}
                                                        alt="QuestionIcon"
                                                      />
                                                    </SingleLessonIcon>
                                                    <SingleLessonTitle>
                                                      {exercise.title}
                                                    </SingleLessonTitle>

                                                    {!selectedProgram.is_user_subscribed &&
                                                    user.is_trail ? (
                                                      <SingleLessonStatus>
                                                        {exercise.is_paid ===
                                                        "غير مدفوع" ? (
                                                          <h1>مفتوح مجانا</h1>
                                                        ) : (
                                                          <img
                                                            src={LockIcon}
                                                            alt="lock"
                                                          />
                                                        )}
                                                      </SingleLessonStatus>
                                                    ) : null}
                                                  </SingleLessonContainer>
                                                )}
                                              </>
                                            ))
                                        )}
                                      </>
                                    ))
                                )}
                              </AccordionItemPanel>
                            </AccordionItem>
                          ))
                      )}
                    </Accordion>
                  </SubCategoryContainer>
                )}
              </>
            ))
        )}
      </SectionContainer>
    </LessonsContainer>
  );
};

export default ProgramContentAccordion;
