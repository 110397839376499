import { Input } from "./RadioCheckBox.styles";
import React from "react";

const RadioCheckBox = ({ onChange }) => {
  return (
    <>
      <Input
        type='radio'
        id='test1'
        name='radio-group'
        checked
        onChange={onChange}
      />
      <label htmlFor='test1'></label>
    </>
  );
};

export default RadioCheckBox;
