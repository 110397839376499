import { TEXTS } from "data/textStrings";
import {
  CommentsContainer,
  CommentContainer,
  CommentContent,
  UserImage,
  CommentMeta,
  UserName,
  CommentDate,
  InputContainer,
  Input,
  Submit,
  TextContainer,
  AddCommentButton,
  CommentText,
} from "./LessonComments.styles";
import { CommentsApi } from "services/api/comments";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import CommentsLoader from "components/Loaders/CommentsLoader";
import avatars from "data/avatars";

const LessonComments = ({ lessonId }) => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [newReply, setNewReply] = useState("");

  const [selectedCommentId, setSelectedCommentId] = useState("");
  const [showReplyInput, setShowReplyInput] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getLessonComments();
  }, []);

  const getLessonComments = async () => {
    try {
      setLoading(true);
      const response = await CommentsApi.getLessonComments(lessonId);

      if (response.data.metaData.key === "success") {
        setComments([...response.data.data]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleNewComment = async () => {
    try {
      setLoading(true);
      const response = await CommentsApi.postComment(lessonId, {
        text: newComment,
      });

      if (response.data.metaData.key === "success") {
        setNewComment("");
        getLessonComments();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }

  };
    const handleNewReply = async (parentId) => {
      try {
        setLoading(true);
        const response = await CommentsApi.postComment(lessonId, {
          text: newReply,
          parent_id:parentId
        });

        if (response.data.metaData.key === "success") {
          setNewReply("");
          getLessonComments();
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

  const handleReplyClick = (commentId) => {
    setShowReplyInput(true);
    setSelectedCommentId(commentId);
  };
  return (
    <CommentsContainer>
      <CommentContainer>
        <UserImage
          src={avatars[`${user.avatar || "profile-0"}`]}
          alt='profile image'
        />
        <CommentContent>
          <CommentMeta>
            <UserName>{user.name}</UserName>
            <CommentDate>{format(new Date(), "MMM/dd/yyyy")}</CommentDate>
          </CommentMeta>
          <InputContainer>
            <Input
              type='text'
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="اضف تعليقا"
              value={newComment}
            />
            <Submit onClick={handleNewComment}>{TEXTS.SUBMIT_COMMENT}</Submit>
          </InputContainer>
        </CommentContent>
      </CommentContainer>
      {loading && <CommentsLoader />}
      {!loading && comments.length > 0 &&
        comments.map((comment) => (
          <CommentContainer key={comment.id}>
            <UserImage
              src={avatars[`${user.avatar || "profile-0"}`]}
              alt='profile image'
            />
            <CommentContent>
              <CommentMeta>
                <UserName>{comment.user.name}</UserName>
                <CommentDate>
                  {format(new Date(comment.created_at), "MMM/dd/yyyy")}
                </CommentDate>
              </CommentMeta>
              <TextContainer>
                <CommentText>{comment.text}</CommentText>
                <AddCommentButton onClick={() => handleReplyClick(comment.id)}>
                  {" "}
                  + {TEXTS.ADD_REPLY}
                </AddCommentButton>
                {showReplyInput && comment.id === selectedCommentId && (
                  <InputContainer>
                    <Input
                      type='text'
                      placeholder="أضف ردا"
                      onChange={(e) => setNewReply(e.target.value)}
                      value={newReply}
                    />
                    <Submit onClick={()=>handleNewReply(comment.id)}>
                      {TEXTS.SUBMIT_REPLY}
                    </Submit>
                  </InputContainer>
                )}
              </TextContainer>
              {comment.children.length > 0 &&
                comment.children.map((child) => (
                  <CommentContainer reply key={child.id}>
                    <UserImage
                      src={avatars[`${user.avatar || "profile-0"}`]}
                      alt='profile image'
                    />
                    <CommentContent>
                      <CommentMeta>
                        {/* <UserName>{child.user.name}</UserName> */}
                        <CommentDate>
                          {format(new Date(child.created_at), "MMM/dd/yyyy")}
                        </CommentDate>{" "}
                      </CommentMeta>
                      <TextContainer>
                        <CommentText>{child.text}</CommentText>
                      </TextContainer>
                    </CommentContent>
                  </CommentContainer>
                ))}
            </CommentContent>
          </CommentContainer>
        ))}
    </CommentsContainer>
  );
};

export default LessonComments;
