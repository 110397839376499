import styled from "styled-components/macro";

import { media } from "styles/utils";

export const LessonContentContainer = styled.div`
  direction: rtl;
`;
export const MediaContainer = styled.div``;
export const VideoWrapper = styled.div`
  /* align-self: center; */
  position: relative;

  /* position: relative; */
  /* padding-top: 56.25%; */
  iframe {
    width: 100% !important;
  }
  .lesson_iframe {
    height: 68vh;
    ${media("medium")} {
      height: 450px;
    }
  }
`;

export const LessonInfoContainer = styled.div`
  margin-top: 24px;
`;
export const LessonInfoTabs = styled.div`
  display: flex;
  position: relative;
`;
export const LessonInfoTab = styled.button`
  font-size: 1.6rem;
  font-weight: bold;
  color: rgb(var(--secondary));
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &::after {
    content: "";
    width: 100%;
    height: 7px;
    background-color: rgb(var(--secondary));
    color: blue;
    position: absolute;
    bottom: -15px;
    right: 0;
    border-radius: 6px;
    display: ${(props) => (props.active ? "block" : "none")};
  }
  &:not(:first-child) {
    margin-right: 24px;
  }
`;

export const LessonDescription = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  letter-spacing: normal;
  text-align: right;
  color: rgba(var(--black-dark), 0.8);
  margin-top: 44px;
  ${media("medium")} {
    margin-bottom: 24px;
  }
`;

export const NextLessonButton = styled.button`
  height: 46px;
  padding: 9px 44px 12px;
  border-radius: 10px;
  background-color: rgb(var(--secondary));
  font-size: 1.4rem;
  font-weight: bold;
  border: none;
  position: absolute;
  left: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(var(--secondary), 0.9);
  }
`;
