import axios from "axios";
import store from "../../store";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: process.env.REACT_APP_REQUESTS_TIMEOUT,
});

apiClient.interceptors.request.use(
  (config) => {
    if (
      store.getState().auth.token &&
      (config.url !== "/auth/login" || config.url !== "/auth/register")
    ) {
      config.headers["Authorization"] = "Bearer " + store.getState().auth.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// apiClient.interceptors.response.use(
//   (response) => response,

//   (error) => {
//     console.log("🚀 ~ file: index.js ~ line 36 ~ error", error);
//     if (error.response.status === 401) {
//       console.log(error.response.status);
//       persistor.purge();
//       store.dispatch(dispatchLogout());
//       localStorage.removeItem("persist:root");

//       history.push("/login");
//       // eslint-disable-next-line no-restricted-globals
//       window.location.reload();
//     }
//     return Promise.reject(error);
//   }
// );

const { get, post, put, delete: destroy, patch } = apiClient;
export { get, post, put, destroy, patch };
export default apiClient;
