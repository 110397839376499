import { get, post } from "../../config/api";

export const Lessons = {
  getLessons: (
    programId,
    resourceId = undefined,
    resourceType = "chapter",
    limit = 10
  ) =>
    get(
      `programs/${programId}/lessons?limit=${limit}&sort=id&filter[resource_id]=${resourceId}&filter[resource_type]=${resourceType}`
    ),

  reviewLesson: (lessonId, payload) =>
    post(`users/me/lessons/${lessonId}`, payload),
};
