import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Loader from "../DocumentGPT/Loader";
import {
  LessonsContainer,
  SectionTitle,
  SingleLessonContainer,
  SingleLessonIcon,
  SubCategoryContainer,
} from "../ProgramContentAccordion/ProgramContentAccordion.styles";
import CheckIcon from "assets/img/check.svg";
import PlayIcon from "assets/img/pdf.png";
import { DocumentName } from "components/DocumentGPT/StyledComponents";

const PDFListing = ({
  categories,
  setSelectedPDF,
  setPDFId,
  setShowQuiz,
}) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [activePDF, setActivePDF] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    if (!isDataLoaded && categories && Object.keys(categories).length > 0) {
      const firstCategory = Object.keys(categories)[0];
      setActiveCategory(firstCategory);
      if (Array.isArray(categories[firstCategory]) && categories[firstCategory].length > 0) {
        setActivePDF(categories[firstCategory][0].pdf_name);
      }
      setExpandedItem(firstCategory);
      setIsDataLoaded(true);
    }
  }, [categories, isDataLoaded]);

  const handleCategoryClick = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };
  const handlePDFselect = (item, PDFId) => {
    setSelectedPDF(item);
    setPDFId(PDFId);
    setActivePDF(item); // Set the active PDF to the selected one
    setShowQuiz(false);
  };
  const [expandedItem, setExpandedItem] = useState(null);

  const handleChange = (panels) => {
    setExpandedItem(panels[0]);
  };

  return (
    <LessonsContainer>
      {isDataLoaded ? (
        Object.entries(categories).map(([category, pdfs]) => (
          <SubCategoryContainer key={category}>
            <Accordion
              allowZeroExpanded
              allowMultipleExpanded={false}
              onChange={handleChange}
              preExpanded={[expandedItem]}
            >
              <AccordionItem uuid={category}>
                <AccordionItemHeading>
                  <AccordionItemButton onClick={() => handleCategoryClick(category)}>
                    <SectionTitle style={{"font-size":"20px"}}>{category}</SectionTitle>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {Array.isArray(pdfs) &&
                    pdfs.length > 0 &&
                    pdfs.map((pdf) => (
                      <SingleLessonContainer
                        active={activePDF === pdf.pdf_name}
                        onClick={() => handlePDFselect(pdf.pdf_name, pdf.id)}
                        key={pdf.id}
                      >
                        <SingleLessonIcon>
                          {pdf.is_done ? (
                            <img src={CheckIcon} alt="done" />
                          ) : (
                            <img src={PlayIcon} alt="not done" />
                          )}
                        </SingleLessonIcon>
                        <DocumentName>{pdf.pdf_name}</DocumentName>
                      </SingleLessonContainer>
                    ))}
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </SubCategoryContainer>
        ))
      ) : (
        <Loader />
      )}
    </LessonsContainer>
  );
};

export default PDFListing;
