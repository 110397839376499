/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { zoom } from "services/api/zoom";
import Toast from "components/Toast/Toast";

const INITIAL_STATE = {
  meetingNumber: "",
  loading: false,
  meetingData: [],
  errors: "",
};

const zoomSlice = createSlice({
  name: "zoom",
  initialState: INITIAL_STATE,
  reducers: {
    zoomRequest(state) {
      state.loading = true;
    },

    zoomSuccess(state, action) {
      // state.meetingNumber = action.payload.?;
      state.meetingData = action.payload.data.filter(
        (m) => m.group.id == action.payload.groupId
      );
      state.loading = false;
    },
    zoomFailure(state, action) {
      state.errors = action.payload;
      state.meetingNumber = "";
      state.meetingData = [];
      state.loading = false;
    },
    setWebinarNumber(state, action) {
      state.meetingNumber = action.payload;
    },
    unSetWebinarNumber(state, action) {
      state.meetingNumber = "";
    },
  },
});

// export actions
export const {
  zoomRequest,
  zoomSuccess,
  zoomFailure,
  setWebinarNumber,
  unSetWebinarNumber,
} = zoomSlice.actions;

// export reducer
export const { reducer: zoomReducer } = zoomSlice;

const zoomDispatch = (groupId) => async (dispatch) => {
  try {
    dispatch(zoomRequest());

    const response = await zoom.getMeetingInfo().then(()=>alert("sd"));
    console.log(response)
    if (response.data.metaData.key === "success") {
      dispatch(zoomSuccess({ data: response.data.data, groupId }));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(zoomFailure());
    }
  } catch (error) {
    console.log(error);
  }
};
const dispatchSetWebinarNumber = (data) => (dispatch) => {
  dispatch(setWebinarNumber(data));
};
const dispatchUnSetWebinarNumber = () => (dispatch) => {
  dispatch(unSetWebinarNumber());
};
export { zoomDispatch, dispatchSetWebinarNumber, dispatchUnSetWebinarNumber };
