import { Route, Switch, useHistory } from "react-router-dom";
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import GlobalStyles from "styles/globals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import PrivateRoute from "utils/PrivateRoute";
import GuestRoute from "utils/GuestRoute";
import ScrollToTop from "utils/ScrollToTop";
import apiClient from "config/api";
import { persistor } from "store";
import store from "store";
import { dispatchLogout } from "store/slices/auth";
import { Auth } from "services/api/auth";
import LogoutModal from "components/Modals/LogoutModal/LogoutModal";
import { Loading } from "components/loading/Loading";
import ProgramLessons from "pages/ProgramLessons";
import PDFQuiz from "components/PDFQuiz/PDFQuiz";

/* pages */
const Zoom = lazy(() => import("pages/Zoom"));
const Home = lazy(() => import("pages/Home"));
const AboutUs = lazy(() => import("pages/About-us"));
const Terms = lazy(() => import("pages/Terms"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("pages/RefundPolicy"));
const Testimonial = lazy(() => import("pages/Testimonial"));
const Login = lazy(() => import("pages/Login"));
const Register = lazy(() => import("pages/Register"));
const Otp = lazy(() => import("pages/Otp"));
const ForgetPasswordPage = lazy(() => import("pages/forgetPassword"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const UserPrograms = lazy(() => import("pages/UserPrograms"));
const UserSubscriptions = lazy(() => import("pages/UserSubscriptions"));
const Checkout = lazy(() => import("pages/Checkout"));
const Cart = lazy(() => import("pages/Cart"));
const Invoice = lazy(() => import("pages/Invoice"));
const Courses = lazy(() => import("pages/Courses"));
const Program = lazy(() => import("pages/Program"));
const Packages = lazy(() => import("pages/Packages"));
const Statistics = lazy(() => import("pages/Statistics"));
const Exam = lazy(() => import("pages/Exam"));
const ExamQuestions = lazy(() => import("pages/ExamQuestions"));
const Profile = lazy(() => import("pages/Profile"));
const GoogleCallBack = lazy(() => import("pages/GoogleCallBack"));
const TwitterCallback = lazy(() => import("pages/TwitterCallback"));
const NotFound = lazy(() => import("pages/NotFound"));
const DocumentGPT = lazy(() => import("pages/DocumentGPT"));
/*  */
function App() {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  let interval = useRef(null);

  useEffect(() => {
    /* toast(<Toast message='الرجاء استكمال بيانات الملف الشخصي' error LinkToProfile/>); */
    if (!isAuthenticated) {
      apiClient.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            persistor.purge();
            store.dispatch(dispatchLogout());
            localStorage.removeItem("persist:root");
            if (location.pathname !== "/login") {
              history.push("/login");
              window.location.reload();
            }
          }
          return Promise.reject(error);
        }
      );
    }
  }, [apiClient.interceptors.response, isAuthenticated, location.pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      interval.current = setInterval(() => {
        checkAuth();
      }, 10000);
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [isAuthenticated]);

  const checkAuth = async () => {
    try {
      await Auth.authPing();
    } catch (error) {
      if (error?.response?.data?.message === "Unauthenticated.") {
        clearInterval(interval.current);
        if (!openLogoutModal) {
          setOpenLogoutModal(true);
        }
      }
    }
  };

  return (
    <>
      <ScrollToTop />
      <GlobalStyles />
      {/* < QuestionReportModal/> */}
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/refund-policy" component={RefundPolicy} />
          <Route exact path="/testimonial" component={Testimonial} />
          <GuestRoute exact path="/login" component={Login} />
          <GuestRoute exact path="/register" component={Register} />
          <GuestRoute exact path="/otp" component={Otp} />
          <GuestRoute
            exact
            path="/forget-password"
            component={ForgetPasswordPage}
          />
          <GuestRoute exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute exact path="/my-programs" component={UserPrograms} />
          <PrivateRoute
            exact
            path="/subscriptions"
            component={UserSubscriptions}
          />
          <PrivateRoute exact path="/checkout" component={Checkout} />
          <PrivateRoute exact path="/cart" component={Cart} />
          <PrivateRoute exact path="/invoice" component={Invoice} />
          <PrivateRoute
            exact
            key={location.path}
            path="/zoom"
            component={Zoom}
          />
          {/* <Route exact path='/groups/:groupId/programs' component={Courses} /> */}
          <PrivateRoute exact component={PDFQuiz} path='/pdf-quiz'/>
          <Route
            exact
            path="/groups/:groupId/categories/:categoryId/programs"
            component={Courses}
          />
          <Route
            exact
            path="/categories/:categoryId/programs/:programId"
            component={Program}
          />
          <PrivateRoute
            exact
            path="/categories/:categoryId/programs/:programId/packages"
            component={Packages}
          />
          <PrivateRoute
            exact
            path="/categories/:categoryId/programs/:programId/lessons"
            component={ProgramLessons}
          />

          <PrivateRoute
            exact
            path="/categories/:categoryId/programs/:programId/statistics"
            component={Statistics}
          />
          <PrivateRoute
            exact
            path="/categories/:categoryId/programs/:programId/exams"
            component={Exam}
          />
          <PrivateRoute
            exact
            path="/categories/:categoryId/programs/:programId/collections/:collectionId/exams/:examId"
            component={ExamQuestions}
          />
          <Route 
          exact 
          path="/categories/:categoryId/programs/:programId/documents" 
          component={DocumentGPT} 
          />
          <PrivateRoute exact path="/profile" component={Profile} />
          <GuestRoute
            exact
            path="/google/callback"
            component={GoogleCallBack}
          />
          <GuestRoute
            exact
            path="/twitter/callback"
            component={TwitterCallback}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      <LogoutModal open={openLogoutModal} setOpen={setOpenLogoutModal} />
    </>
  );
}

export default App;
