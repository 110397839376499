import React from "react";
import Loader from "react-loader-spinner";
import { LoadingContainer } from "./Loading.style";
export const Loading = () => {
  return (
    <LoadingContainer>
      <Loader
        type="TailSpin"
        height="100"
        width="100"
        color="#ca57a0"
        ariaLabel="tail-spin-loading"
        radius="5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </LoadingContainer>
  );
};
