/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PackagesApi } from "services/api/packages";
import { TrainingPlans } from "services/api/TrainingPlans";

const INITIAL_STATE = {
  list: [],
  loading: false,
  errors: "",
  selectedPlan: {},
};

const plansSlice = createSlice({
  name: "plans",
  initialState: INITIAL_STATE,
  reducers: {
    plansRequest(state) {
      state.loading = true;
    },

    plansSuccess(state, action) {
      state.list = action.payload;
      state.loading = false;
    },
    plansFailure(state, action) {
      state.errors = action.payload;
      state.list = [];
      state.loading = false;
    },
    selectedPlanRequest(state, action) {
      state.loading = true;
    },

    selectedPlanSuccess(state, action) {
      state.loading = true;
      state.selectedPlan = action.payload;
    },

    selectedPlanFailure(state, action) {
      state.errors = action.payload;
      state.list = [];
      state.loading = false;
      state.selectedPlan = {};
    },
  },
});

// export actions
export const {
  plansRequest,
  plansSuccess,
  plansFailure,
  selectedPlanRequest,
  selectedPlanSuccess,
  selectedPlanFailure,
} = plansSlice.actions;

// export reducer
export const { reducer: plansReducer } = plansSlice;

const dispatchFetchProgramPlans = (programId) => async (dispatch) => {
  try {
    dispatch(plansRequest());

    const response = await TrainingPlans.getProgramPlans(programId);

    if (response.data.metaData.key === "success") {
      dispatch(plansSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(plansFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(plansFailure(error.toString()));
  }
};

const dispatchFetchUserPlan = (programId) => async (dispatch) => {
  try {
    dispatch(selectedPlanRequest());

    const response = await TrainingPlans.getUserPlan(programId);

    if (response.data.metaData.key === "success") {
      dispatch(selectedPlanSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(selectedPlanFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(selectedPlanFailure(error.toString()));
  }
};
export { dispatchFetchProgramPlans, dispatchFetchUserPlan };
