/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { Exams } from "services/api/exams";

const INITIAL_STATE = {
  collections: [],
  exams: [],
  examsStatuses: [],
  examsResults: [],
  questions: [],
  loading: false,
  errors: "",
  selectedGroup: {},
  selectedExam: {},
  studentAnswers: [],
  bookmarkQuestions: [],
  finishedExams: [],
  examSendedData: [],
  studentReviewAnswers: [],
  collectionStats: {},
};

const examsSlice = createSlice({
  name: "exams",
  initialState: INITIAL_STATE,
  reducers: {
    collectionsRequest(state) {
      state.loading = true;
    },

    collectionsSuccess(state, action) {
      state.collections = action.payload;
      // state.examsStatuses=action.payload.map(collection=> {
      //   return collection.exams.map(exam => )
      // })
      state.loading = false;
    },
    collectionsFailure(state, action) {
      state = { ...INITIAL_STATE, errors: action.paylaod };
    },
    examsRequest(state) {
      state.loading = true;
    },

    examsSuccess(state, action) {
      state.exams = action.payload;
      state.examsStatuses = action.payload.map((exam, index) => {
        if (index === 0) return { ...exam, done: false, ready: true };
        return { ...exam, done: false, ready: false };
      });

      state.loading = false;
    },
    examsFailure(state, action) {
      state = { ...INITIAL_STATE, errors: action.paylaod };
    },
    setExams(state) {
      // state.examsStatuses = state.exams.map;
    },
    questionsRequest(state) {
      state.loading = true;
    },

    questionsSuccess(state, action) {
      state.questions = action.payload;
      state.loading = false;
    },
    questionsFailure(state, action) {
      state = { ...INITIAL_STATE, errors: action.paylaod };
    },
    reviewQuestionsRequest(state) {
      state.loading = true;
    },

    reviewQuestionsSuccess(state, action) {
      state.questions = action.payload.user_exam_stats_passed.details.map(
        (q) => q.question
      );
      state.studentReviewAnswers =
        action.payload.user_exam_stats_passed.details.map((q) => q.answer);
      state.loading = false;
    },
    reviewQuestionsFailure(state, action) {
      state = { ...INITIAL_STATE, errors: action.paylaod };
    },

    setSelectedExam(state, action) {
      state.selectedExam = { ...action.payload };
    },
    setSelectedGroup(state, action) {
      state.selectedGroup = { ...action.payload };
    },
    setBookMarkedQuestions(state, { payload }) {
      const { questionId, questionText, examId, programId, groupId } = payload;
      if (state.bookmarkQuestions.length === 0) {
        state.bookmarkQuestions = [
          {
            programId,
            groupId,
            examId,
            questionId,
            questionText,
          },
        ];
        return;
      }

      let questionIndex = state.bookmarkQuestions.findIndex(
        (s) =>
          s.programId === programId &&
          s.groupId === groupId &&
          s.examId === examId &&
          s.questionId === questionId
      );

      if (questionIndex !== -1) {
        state.bookmarkQuestions = [
          ...state.bookmarkQuestions.slice(0, questionIndex),
          ...state.bookmarkQuestions.slice(questionIndex + 1),
        ];
        return;
      }
      state.bookmarkQuestions = [...state.bookmarkQuestions, { ...payload }];
    },
    setStudentAnswers(state, { payload }) {
      const { questionId, examId, programId, groupId, answer } = payload;
      if (state.studentAnswers.length === 0) {
        state.studentAnswers = [
          {
            programId: programId,
            groupId: groupId,
            examId: examId,
            questionId: questionId,
            answer: { ...answer, checked: true },
          },
        ];
        return;
      }

      let answerIndex = state.studentAnswers.findIndex(
        (s) =>
          s.programId === programId &&
          s.groupId === groupId &&
          s.examId === examId &&
          s.questionId === questionId
      );

      if (answerIndex !== -1) {
        state.studentAnswers = [
          ...state.studentAnswers.slice(0, answerIndex),
          { ...payload, answer: { ...payload.answer, checked: true } },
          ...state.studentAnswers.slice(answerIndex + 1),
        ];
        return;
      }
      state.studentAnswers = [
        ...state.studentAnswers,
        { ...payload, answer: { ...payload.answer, checked: true } },
      ];
    },
    setExamIsDone(state, { payload }) {
      const { examId, collectionId } = payload;
      if (state.finishedExams.length === 0) {
        state.finishedExams = [
          {
            collectionId,
            examId: examId,
          },
        ];
        return;
      }

      // let answerIndex = state.finishedExams.findIndex(
      //   (s) =>
      //     s.collectionId === collectionId &&
      //     s.examId === examId &&
      // );

      // if (answerIndex !== -1) {
      //   state.finishedExams = [
      //     ...state.finishedExams.slice(0, answerIndex),
      //     { ...payload, answer: { ...payload.answer, checked: true } },
      //     ...state.finishedExams.slice(answerIndex + 1),
      //   ];
      //   return;
      // }
      state.finishedExams = [...state.finishedExams, { ...payload }];
    },

    setExamResult(state, { payload }) {
      const { questionId, examId, groupId, programId, answer } = payload;
      console.log(
        "🚀 ~ file: exams.slice.js ~ line 177 ~ setExamResult ~ answer",
        answer
      );

      // if this the first exercise
      if (state.examsResults.length === 0) {
        state.examsResults = [
          {
            programId: programId,
            examId: examId,
            groupId: groupId,
            questions: [
              {
                questionId: questionId,
                isCorrect: !!answer.is_correct,
              },
            ],
          },
        ];
      }
      // check if the this Exam is new or not
      let examIndex = state.examsResults.findIndex(
        (s) =>
          s.programId === programId &&
          s.groupId === groupId &&
          s.examId === examId
      );

      // if the Exam is exist before, we check for question if exist or not
      if (examIndex !== -1) {
        // get the Exam from the state using ID
        let exam = state.examsResults.filter(
          (s) =>
            s.programId === programId &&
            s.examId === examId &&
            s.groupId === groupId
        )[0];

        // check if the is answered question is exist in exam or not
        let questionIndex = exam.questions.findIndex(
          (q) => q.questionId === questionId
        );

        // if the question is exist before, we just update the isCorrect field
        if (questionIndex !== -1) {
          state.examsResults = [
            ...state.examsResults.slice(0, examIndex),
            {
              programId: programId,
              examId: examId,
              groupId: groupId,
              questions: exam.questions.map((question) => {
                if (questionId !== question.questionId) return question;

                return { questionId, isCorrect: !!answer.is_correct };
              }),
            },
            ...state.examsResults.slice(examIndex + 1),
          ];
        } else {
          // else add the question to the specific exam
          state.examsResults = [
            ...state.examsResults.slice(0, examIndex),
            {
              programId: programId,
              examId: examId,
              groupId: groupId,
              questions: [
                ...exam.questions,
                {
                  questionId: questionId,
                  isCorrect: !!answer.is_correct,
                },
              ],
            },
            ...state.examsResults.slice(examIndex + 1),
          ];
        }

        return;
      }

      // if this is a new exam, then just added to the examsResults array
      state.examsResults = [
        ...state.examsResults,
        {
          programId: programId,
          examId: examId,
          groupId: groupId,
          questions: [
            {
              questionId: questionId,
              isCorrect: !!answer.is_correct,
            },
          ],
        },
      ];
    },

    setExamSendedData(state, { payload }) {
      const { questionId, answer } = payload;

      // if this the first exercise
      if (state.examSendedData.length === 0) {
        state.examSendedData = [
          {
            question_id: questionId,
            answer_id: answer.id,
            is_correct: answer.is_correct,
          },
        ];
        return;
      }

      // check if the  answered question is exist in exam or not
      let questionIndex = state.examSendedData.findIndex(
        (q) => q.question_id === questionId
      );

      // if the question is exist before, we just update the isCorrect field
      if (questionIndex !== -1) {
        state.examSendedData = [
          ...state.examSendedData.slice(0, questionIndex),
          {
            question_id: questionId,
            answer_id: answer.id,
            is_correct: answer.is_correct,
          },
          ...state.examSendedData.slice(questionIndex + 1),
        ];
        return;
      }

      state.examSendedData = [
        ...state.examSendedData,
        {
          question_id: questionId,
          answer_id: answer.id,
          is_correct: answer.is_correct,
        },
      ];
    },

    resetExamSendedData(state) {
      state.examSendedData = [];
    },
    resetExamData(state) {
      state.examsResults = [];
      state.finishedExams = [];
      state.studentAnswers = [];
      state.bookmarkQuestions = [];
    },
    resetQuestions(state) {
      state.questions = [];
    },

    collectionStatsRequest(state) {
      state.loading = true;
    },

    collectionStatsSuccess(state, action) {
      state.collectionStats = action.payload;
      state.loading = false;
    },
    collectionStatsFailure(state, action) {
      state.errors = action.payload;
      state.collectionStats = {};
      state.loading = false;
    },
  },
});

// export actions
export const {
  collectionsRequest,
  collectionsSuccess,
  collectionsFailure,
  examsRequest,
  examsSuccess,
  examsFailure,
  questionsRequest,
  questionsSuccess,
  questionsFailure,
  setSelectedExam,
  setSelectedGroup,
  setStudentAnswers,
  setExamResult,
  setBookMarkedQuestions,
  setExamIsDone,
  setExamSendedData,
  resetExamSendedData,
  resetExamData,
  resetQuestions,
  reviewQuestionsRequest,
  reviewQuestionsSuccess,
  reviewQuestionsFailure,
  collectionStatsRequest,
  collectionStatsSuccess,
  collectionStatsFailure,
} = examsSlice.actions;

// export reducer
export const { reducer: examsReducer } = examsSlice;

const dispatchFetchCollections = (programId) => async (dispatch) => {
  try {
    // dispatch(collectionsRequest());

    const response = await Exams.getProgramCollections(programId);

    if (response.data.metaData.key === "success") {
      dispatch(collectionsSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(collectionsFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(collectionsFailure(error.toString()));
  }
};

const dispatchFetchCollectionExams = (collectionId) => async (dispatch) => {
  try {
    dispatch(examsRequest());

    const response = await Exams.getCollectionExams(collectionId);

    if (response.data.metaData.key === "success") {
      dispatch(examsSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(examsFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(examsFailure(error.toString()));
  }
};
const dispatchFetchExamQuestions = (examId) => async (dispatch) => {
  try {
    dispatch(questionsRequest());

    const response = await Exams.getExamQuestions(examId);

    if (response.data.metaData.key === "success") {
      dispatch(questionsSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(questionsFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(questionsFailure(error.toString()));
  }
};
const dispatchFetchExamReview =
  ({ collectionId, examId }) =>
  async (dispatch) => {
    try {
      dispatch(reviewQuestionsRequest());

      const response = await Exams.getExamReviewQuestions(collectionId, examId);

      if (response.data.metaData.key === "success") {
        dispatch(reviewQuestionsSuccess(response.data.data));
      } else if (response.data.metaData.key === "not_found") {
        dispatch(reviewQuestionsFailure());
      }
    } catch (error) {
      console.log(error);
      dispatch(reviewQuestionsFailure(error.toString()));
    }
  };

const dispatchFetchCollectionStats = (collectionId) => async (dispatch) => {
  try {
    dispatch(collectionStatsRequest());

    const response = await Exams.getCollectionStats(collectionId);

    if (response.data.metaData.key === "success") {
      dispatch(collectionStatsSuccess(response.data.data));
    } else if (response.data.metaData.key === "not_found") {
      dispatch(collectionStatsFailure());
    }
  } catch (error) {
    console.log(error);
    dispatch(collectionStatsFailure(error.toString()));
  }
};

const dispatchSelectedExam = (data) => (dispatch) => {
  dispatch(setSelectedExam(data));
};
const dispatchSelectedGroup = (data) => (dispatch) => {
  dispatch(setSelectedGroup(data));
};
const dispatchSetStudentAnswers = (data) => (dispatch) => {
  dispatch(setStudentAnswers(data));
};
const dispatchSetExamResult = (data) => (dispatch) => {
  dispatch(setExamResult(data));
};
const dispatchSetExamSendedData = (data) => (dispatch) => {
  dispatch(setExamSendedData(data));
};
const dispatchSetBookMarkedQuestion = (data) => (dispatch) => {
  dispatch(setBookMarkedQuestions(data));
};
const dispatchSetExamIsDone = (data) => (dispatch) => {
  dispatch(setExamIsDone(data));
};
const dispatchResetExamSendedData = () => (dispatch) => {
  dispatch(resetExamSendedData());
};
const dispatchResetExamData = () => (dispatch) => {
  dispatch(resetExamData());
};
const dispatchResetQuestions = () => (dispatch) => {
  dispatch(resetQuestions());
};

export {
  dispatchFetchCollections,
  dispatchFetchCollectionExams,
  dispatchFetchExamQuestions,
  dispatchSelectedExam,
  dispatchSetStudentAnswers,
  dispatchSelectedGroup,
  dispatchSetExamResult,
  dispatchSetBookMarkedQuestion,
  dispatchSetExamIsDone,
  dispatchSetExamSendedData,
  dispatchResetExamSendedData,
  dispatchResetExamData,
  dispatchFetchExamReview,
  dispatchResetQuestions,
  dispatchFetchCollectionStats,
};
