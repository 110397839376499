export const TEXTS = {
  START_PROGRAM: "اشترك الآن",
  CHECKOUT_TITLE: "إتمام الشراء",
  PRICE: "السعر",
  PERIOD: "مدة الباقة",
  //CART STRINGS
  COUPON_QUESTION: "هل لديك كوبون خصم",
  DISCOUNT: "مقدار الخصم",
  PACKAGE_DESCRIPTION: "وصف الباقة",
  CONTINUE_PAYMENT: "الاستمرار في الدفع",
  TOTAL: "القيمة الاجمالية",
  CART_TITLE: "تفاصيل شراء الباقة",

  SHOW_DEGREE: "عرض الدرجة",
  //program tabs
  ACTUAL_TESTS: "الإختبارات",
  PROGRAM_LESSONS: "الدروس",
  STATISTICS: "الإحصائيات",

  FEATURES_TITLE: "مميزات دوراتنا",

  FEATURES_ONE: "دروس تفاعلية مختصرة وشاملة لجميع أفكار الاختبار",
  FEATURES_TWO: " متابعة مستمرة لتحسين مستواك وعشان نضمن لك تجيب درجة أعلى",
  FEATURES_THREE: "اختبارات محاكية من تجميعات القدرات والتحصيلي",

  BANNER_TITLE: "خطط المذاكرة الفعّالة",
  BANNER_DESCRIPTION:
    "نقدم لك خطة دراسية منظمة تساعدك تنجز الدورة بفعالية وأقصى استفادة",

  //TESTIMONIALS
  TESTIMONIAL_TITLE: "قصص نجاح مع منصة تميز",
  TESTIMONIAL_SUBTITLE: "تجارب وآراء طلابنا ونتائجهم بعد التدرب على المنصة",

  //STATISTICS
  STATISTICS_TITLE: "احصائيات منصة تميز",
  STATISTICS_SUBTITLE:
    "منصة تعليمية متخصصة في تدريب وتحسين مهارات الطلاب لاجتياز اختبارات القياس بأسلوب سهل واحترافي",
  PROGRAM_STATISTICS: "برنامج تعليمي",
  QUESTIONS_STATISTICS: "سؤال تدريبي",
  LESSONS_STATISTICS: "درس تعليمي",

  //ABOUT
  ABOUT_TITLE: "لماذا تختارنا كأول مصدر للمذاكرة والاستعداد؟",

  ABOUT_ONE: "محتوى علمي ذكي شامل ومختصر",
  ABOUT_TWO: "دروس تفاعلية تضمن لك الفهم",
  ABOUT_THREE: "فعليا ما تحتاج مصادر ثانية .. تميز وبس",
  ABOUT_FOUR: "تدريبات مطابقة للاختبارات الفعلية",

  //PACKAGES
  PACKAGES_TITLE: "باقات الاشتراك",
  PACKAGES_SUBTITLE: "اختر الباقة المناسبة لاحتياجك",
  FREE_TRIAL_TITLE: "جرّب مجانا",
  FREE_TRIAL_TEXT: "تقدر تجرّب جزء من الدروس والاختبارات المحاكية قبل الاشتراك",

  //lessons
  LESSON_OVERVIEW: "ملخص الدرس",
  LESSON_COMMENTS: "التعليقات",
  LESSON_PROGRAMS: "دروس الدورة",
  SUBMIT_COMMENT: "ارسال تعليقك",
  ADD_REPLY: "أضف رد",

  //ranking

  RANKING_TITLE: "مستوى أدائك مقارنة بباقي الطلاب",
  RANKING_SUBTITLE: "تعلم أكثر وطور مهاراتك وارفع تقييمك",

  CHART_TITLE: "مخطط التحصيل الاسبوعي",

  //Training plans
  TRAINING_PLAN_TITLE: "خطة المذاكرة",
  TRAINING_PLAN_SUBTITLE:
    "صممنا الخطط لتنظيم مذاكرتك .. اختر الخطة المناسبة لك حسب موعد اختبارك",
  SUBMIT_REPLY: "ارسال الرد",
};
