import { combineReducers } from "redux";

import { authReducer } from "./auth";
import { zoomReducer } from "./zoom";
import { packagesReducer } from "./packages";
import { groupsReducer } from "./groups";
import { categoriesReducer } from "./categories";
import { programsReducer } from "./programs";
import { subCategoriesReducer } from "./subCategories";
import { chaptersReducer } from "./chapters";
import { examsReducer } from "./exams";
import { exercisesReducer } from "./exercises";
import { paymentReducer } from "./payment";
import { educationalPartiesReducer } from "./educationalParties";
import { statisticsReducer } from "./statistics";
import { loadingBarReducer } from "react-redux-loading-bar";
import { plansReducer } from "./plans";

const reducers = combineReducers({
  auth: authReducer,
  zoom: zoomReducer,
  packages: packagesReducer,
  categories: categoriesReducer,
  programs: programsReducer,
  subCategories: subCategoriesReducer,
  chapters: chaptersReducer,
  exams: examsReducer,
  exercises: exercisesReducer,
  payment: paymentReducer,
  groups: groupsReducer,
  educationalParties: educationalPartiesReducer,
  statistics: statisticsReducer,
  loadingBar: loadingBarReducer,
  programPlans: plansReducer,
});

export default reducers;
